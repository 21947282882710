import React, { useState, useEffect } from 'react';
import Spinner from '../../common/components/spinner/spinner';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Button from 'react-bootstrap/Button';
import { FeedbackGridModel } from '../../models/feedback/feedbackGridModel';
import FeedbackService from '../../services/feedbackService';
import Card from 'react-bootstrap/Card';
import { dateToLocal } from '../../common/functions/helperFunctions';
import Modal from 'react-bootstrap/Modal';
import Alert from 'react-bootstrap/Alert';

const FeedbacksListComponent = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [feedbacks, setFeedbacks] = useState(new Array<FeedbackGridModel>());
    const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [feedbackToDelete, setFeedbackToDelete] = useState('');

    const getAll = async () => {
        const feedbackService = new FeedbackService();

        try {
            setIsLoading(true);
            let allFeedbacks: Array<FeedbackGridModel> = await feedbackService.getAll();
            if(allFeedbacks) {
                setFeedbacks(allFeedbacks);
            }
            setIsLoading(false);
        } catch(error) {
            setErrorMessage(error.message)
            setIsLoading(false);
        }
    }

    useEffect(() => {
       getAll();
    }, []);

    const renderBreadcrumb = () => {
        return (
            <Breadcrumb style={{ fontSize: '85%'}}>
            <Breadcrumb.Item href="/">Начало</Breadcrumb.Item>
            <Breadcrumb.Item active>
                Feedback
            </Breadcrumb.Item>
        </Breadcrumb>
        );
    }

    const renderFeedbacks = () => {
        if(feedbacks.length === 0 && !errorMessage) {
            return (
                <p>
                    Няма добавени записи.
                </p>
            )
        } else {
            return(
                <>
                    {feedbacks.map((feedback, index) => {
                        return(
                            <div style={{ marginBottom: '10px'}}>
                                 <Card key={feedback.id}>
                                    <Card.Body>
                                        <div style={{ display: 'flex', flexDirection: 'column', fontSize: '85%'}}>
                                            <span><strong>Добавен на:</strong> {dateToLocal(feedback.dateAdded)}</span>
                                            <span><strong>IP адрес:</strong> {feedback.ipAddress}</span>
                                            <span><strong>Имейл:</strong> {feedback.email}</span>
                                            <div style={{ 
                                                borderTop: '1px solid rgba(0,0,0,0.5)', 
                                                borderBottom: '1px solid rgba(0,0,0,0.5)'}}
                                                className="mt-2 pt-2 mb-2 pb-2">
                                                {feedback.content}
                                            </div>
                                            <div className="row mt-2">
                                                <div className="col-sm-2">
                                                    <Button  size="sm" variant="danger" onClick={() => onDeleteBtnClicked(feedback.id)}>Изтрий</Button>
                                                </div>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </div>
                        )
                    })}
                </>
            )
        }
    }

    const onDeleteBtnClicked = (feedbackId) => {
        setErrorMessage('');
        setFeedbackToDelete(feedbackId);
        setDeleteDialogVisible(true);
    }

    const deleteFeedback = async () => {
        try {
            setDeleteDialogVisible(false);
            setIsLoading(true);

            if(feedbackToDelete) {
                const feedbackService = new FeedbackService();
                await feedbackService.delete(feedbackToDelete);
            }

            await getAll();
        } catch(error) {
            setErrorMessage(error.message)
            setIsLoading(false);
        }
    }

    return(
        <div style={{ marginBottom: 50 }}>
        {isLoading ? <Spinner /> : null}

        {!isLoading 
         ? <>
            {renderBreadcrumb()}
            <h5>Feedback {feedbacks ? `(${feedbacks.length})` : null}</h5>
            {errorMessage ? 
                  <Alert variant={'danger'}>
                  {errorMessage}
                </Alert>
                : null}
            {renderFeedbacks()}
                <Modal show={deleteDialogVisible} onHide={() => setDeleteDialogVisible(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Изтриване на feedback</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Сигурни ли сте, че искате да изтриете този запис?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setDeleteDialogVisible(false)}>
                            Не
                        </Button>
                        <Button variant="danger" onClick={deleteFeedback}>
                        Да
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
         : null}
    </div>
    )
}

export default FeedbacksListComponent;