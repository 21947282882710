import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { Tab, Tabs } from 'react-bootstrap';
import Spinner from '../../../common/components/spinner/spinner';
import PollEditComponent from "./pollEditComponent";
import PollsService from "../../../services/pollsService";
import PollVotesComponent from "./pollVotesComponent";
import PollViewsComponent from "./pollViewsComponent";
import PollCommentsComponent from './pollCommentsComponent';

const PollComponent = () => {
     // @ts-ignore
    const { pollId } = useParams();
    const pollsService = new PollsService();
    // @ts-ignore
    const [poll, setPoll] = useState<PollEditModel | null>(null);
    const [selectedTab, setSelectedTab] = useState('main');
    const [isLoading, setIsLoading] = useState(false);

    const getPoll = async () => {
        setIsLoading(true);

        try {
            let pollToEdit = await pollsService.getPoll(pollId);
            if(pollToEdit) {
                setPoll(pollToEdit);
            }

            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    }

    useEffect(() => {   
        getPoll();
        // eslint-disable-next-line
    }, []);

    const changeTab = (key: any) => {
        setSelectedTab(key);
    }

    const onPollUpdated = async () => {
        await getPoll();
    }

    return (
        <div>
             {isLoading ? <Spinner /> : null}
                <Tabs
                    id="pollTabs"
                    activeKey={selectedTab}
                    onSelect={(k) => changeTab(k)}
                    >
                    <Tab eventKey="main" title="Въпрос">
                        {!isLoading
                         ? <PollEditComponent pollToEdit={poll} onpPollUpdated={onPollUpdated} />
                         : null}
                    </Tab>
                    <Tab eventKey="votes" 
                            title={`Гласувания (${!isLoading && poll ? poll.votes : 0})`} 
                            disabled={!isLoading && poll?.votes === 0}>
                           {!isLoading
                            ? <PollVotesComponent poll={poll} />
                            : null}
                    </Tab>
                    <Tab eventKey="comments" 
                            title={`Коментари (${!isLoading && poll ? poll.commentsCount : 0})`} 
                            disabled={!isLoading && poll?.commentsCount === 0}>
                            {!isLoading
                            ? <PollCommentsComponent poll={poll} />
                            : null}
                    </Tab>
                    <Tab eventKey="usersViews" 
                            title={`Прегледи (${!isLoading && poll ? poll.views : 0})`}
                            disabled={!isLoading && poll?.views === 0}>
                              {!isLoading
                                ? <PollViewsComponent poll={poll} />
                                : null}
                    </Tab>
                </Tabs>
        </div>
    )
}

export default PollComponent;