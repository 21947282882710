import React, { Component } from 'react';
import Pagination from 'react-bootstrap/Pagination';

interface IProps {
    totalRecords: number;
    pageSize: number;
    currentPage: number;
    style?: React.CSSProperties;
    onClick: any;
}

interface IState {
    totalPages: number;
}

class Paginator extends Component<IProps, IState> {
    public static defaultProps = {
        style: {}
    };

    constructor(props: IProps) {
        super(props);

        this.state = {
            totalPages: Math.ceil(this.props.totalRecords / this.props.pageSize)
        }
    }

    renderPaginationItems = () => {
        const { currentPage } = this.props;
        const { totalPages } = this.state;

        if(totalPages < 3) {
            return (
                <>
                    <Pagination.Item active={currentPage === 1} onClick={() => this.props.onClick(1)}>{1}</Pagination.Item>
                    <Pagination.Item active={currentPage === 2} onClick={() => this.props.onClick(2)}>{2}</Pagination.Item>
                </>
            )
        } else {
            if(currentPage === 1) {
                return (
                    <>
                        <Pagination.Item active={true} onClick={() => this.props.onClick(1)}>{1}</Pagination.Item>
                        <Pagination.Item onClick={() => this.props.onClick(2)}>{2}</Pagination.Item>
                        <Pagination.Item onClick={() => this.props.onClick(3)}>{3}</Pagination.Item>
                    </>
                );
            } else if (currentPage === totalPages) {
                return (
                    <>
                        <Pagination.Item onClick={() => this.props.onClick(currentPage - 2)}>{currentPage - 2}</Pagination.Item>
                        <Pagination.Item onClick={() => this.props.onClick(currentPage - 1)}>{currentPage - 1}</Pagination.Item>
                        <Pagination.Item active={true} onClick={() => this.props.onClick(currentPage)}>{currentPage}</Pagination.Item>
                    </>
                )
            } else {
                return (
                    <>
                        <Pagination.Item onClick={() => this.props.onClick(currentPage - 1)}>{currentPage - 1}</Pagination.Item>
                        <Pagination.Item active={true} onClick={() => this.props.onClick(currentPage)}>{currentPage}</Pagination.Item>
                        <Pagination.Item onClick={() => this.props.onClick(currentPage + 1)}>{currentPage + 1}</Pagination.Item>
                    </>
                )
            }
        }
    }
   
    render() {
        const { currentPage, style } = this.props;
        const { totalPages } = this.state;

        return (
            <Pagination style={style ? style : {}}>
                <Pagination.First disabled={currentPage === 1} onClick={() => this.props.onClick(1)} />
                <Pagination.Prev disabled={currentPage === 1} onClick={() => this.props.onClick(currentPage - 1)}/>
                {this.renderPaginationItems()}
                <Pagination.Next disabled={currentPage === totalPages} onClick={() => this.props.onClick(currentPage + 1)} />
                <Pagination.Last disabled={currentPage === totalPages} onClick={() => this.props.onClick(totalPages)}/>
            </Pagination>
        )
    }
}

export default Paginator;