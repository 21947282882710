import React, { CSSProperties } from 'react';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import Spinner from '../../common/components/spinner/spinner';
import SystemOptionsService from '../../services/systemOptionsService';
import Card from 'react-bootstrap/esm/Card';
import { CacheTypes } from '../../common/enums';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import { RestrictedAddressAddEditModel } from '../../models/options/restrictedAddressAddEditModel';
import { CacheResetModel } from '../../models/options/cacheResetModel';

interface IProps {
    style: CSSProperties | undefined;
}

interface IState {
    isLoading: boolean;
    errorMessage: string;
    successMassage: string;
    selectedCache: CacheTypes;
}

class CacheResetComponent extends React.Component<IProps, IState> {
    systemOptionsService = new SystemOptionsService();

    constructor(props: IProps) {
        super(props);

        let currentRestrictedAddress = new RestrictedAddressAddEditModel();
        currentRestrictedAddress.isActive = true;

        this.state = {
            isLoading: false,
            errorMessage: '',
            successMassage: '',
            selectedCache: CacheTypes.RestrictedAddresses,
        }
    }

    handleClearCacheBtnPressed = async () => {
        try {
            const { selectedCache } = this.state;

            let cacheResetModel = new CacheResetModel();
            cacheResetModel.cacheType = selectedCache;

            this.setState({
                isLoading: true,
                successMassage: '',
                errorMessage: '',
            });

            await this.systemOptionsService.resetCache(cacheResetModel);

            this.setState({
                isLoading: false,
                successMassage: 'Операцията приключи успешно.'
            });

        } catch (error) {
            this.setState({
                isLoading: false,
                errorMessage: error.message
            });
        }
    }

    render() {
        const { isLoading, errorMessage, successMassage, selectedCache } = this.state;

        if(successMassage) {
            setTimeout(() => {
                this.setState({ 
                    successMassage: '',
                });
            }, 5000)
        }

        return (
         <Card style={ this.props.style ? this.props.style : {}}>
            {isLoading ? <Spinner /> : null}
            <Card.Body>
                <h6>Изчистване на кеш</h6>
                {errorMessage
                ?  <Alert style={{ fontSize: '85%'}} variant={'danger'}>
                        {errorMessage}
                    </Alert>
                : null}
                {successMassage
                ?  <Alert style={{ fontSize: '85%'}} variant={'success'}>
                        {successMassage}
                    </Alert>
                : null}
                <Form>
                        <Form.Group as={Row} controlId="cacheSelect">
                            <Form.Label column sm="2" style={{ fontSize: '85%'}}>Тип</Form.Label>
                            <Col sm="10">
                                <Form.Control 
                                    size="sm" 
                                    as="select"
                                    value={selectedCache}
                                    onChange={(e) =>  this.setState({ selectedCache: +e.target['value'] })}>
                                    <option value={CacheTypes.RestrictedAddresses}>Ограничени адреси</option>
                                    <option value={CacheTypes.StoryViews}>Прегледи на истории</option>
                                    <option value={CacheTypes.Votes}>Гласувания</option>
                                </Form.Control>
                            </Col>
                        </Form.Group>
                        <Button size="sm" variant="primary" onClick={this.handleClearCacheBtnPressed}>Изчисти</Button>
                </Form>
            </Card.Body>
         </Card>
         )
    }
}

export default CacheResetComponent;