import * as React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import AuthService from '../../services/authService';
import DashboardComponent from '../dashboard/dashboardComponent';
import LoginComponent from '../user/loginComponent';
import StoriesListComponent from '../stories/storiesListComponent';
import AddEditStoryComponent from '../stories/addEditStoryComponent';
import CommentsListComponent from '../comments/commentsListComponent';
import EditCommentComponent from '../comments/editCommentComponent';
import UsersListComponent from '../users/usersListComponent';
import EditUserComponent from '../users/editUserComponent';
import LoginsListComponent from '../users/loginsListComponent';
import StoriesViewsListComponent from '../stories/storiesViewsListComponent';
import CategoriesListComponent from '../categories/categoriesListComponent';
import AddEditCategoryComponent from '../categories/addEditCategoryComponent';
import SystemOptionsComponent from '../options/systemOptionsComponent';
import FeedbacksListComponent from '../feedback/feedbacksListComponent';
import PollsListComponent from '../polls/pollsListComponent';
import PollsViewsListComponent from '../polls/pollsViewsListComponent';
import PollComponent from '../polls/poll/pollComponent';
import EditPollCommentComponent from '../pollComments/editPollCommentComponent';
import PollCommentsListComponent from '../pollComments/pollCommentsListComponent';
import PollsOptionsVotesListComponent from '../polls/pollsOptionsVotesListComponent';
 
const NoMatch = () => (
    <div>
        <h3>
            URL address <code>{window.location.pathname}</code> not match.
    </h3>
    </div>
);

// const NotAuthorizedComponent = () => (
//     <div className="app-content">
//         <h3>{Translate('You are not Authenticated to view this resource')}</h3>
//     </div>)


interface IProps {
    history: any,
    location: any;
    match: any;
}

interface IState {
}

class Content extends React.Component<IProps, IState> {
    authService = new AuthService();

    render() {
        const isAuth = this.authService.isAuthenticated();

        return (
            <>
                <Switch>
                    <Route exact path="/" component={isAuth ? DashboardComponent : LoginComponent} />
                    <Route exact path="/dashboard" component={isAuth ? DashboardComponent : LoginComponent} />
                    <Route exact path="/login" component={isAuth ? DashboardComponent : LoginComponent} />
                    <Route exact path="/stories/:page?" component={isAuth ? StoriesListComponent : LoginComponent} />
                    <Route exact path="/story-comments/:storyId/:page?" component={isAuth ? CommentsListComponent : LoginComponent} />
                    <Route exact path="/add-edit-story/:storyId?" component={isAuth ? AddEditStoryComponent : LoginComponent} />
                    <Route exact path="/comments/:page?" component={isAuth ? CommentsListComponent : LoginComponent} />
                    <Route exact path="/edit-comment/:commentId" component={isAuth ? EditCommentComponent : LoginComponent} />
                    <Route exact path="/users/:page?" component={isAuth ? UsersListComponent : LoginComponent} />
                    <Route exact path="/edit-user/:userId" component={isAuth ? EditUserComponent : LoginComponent} />
                    <Route exact path="/logins/:page?" component={isAuth ? LoginsListComponent : LoginComponent} />
                    <Route exact path="/stories-views/:page?" component={isAuth ? StoriesViewsListComponent : LoginComponent} />
                    <Route exact path="/categories" component={isAuth ? CategoriesListComponent : LoginComponent} />
                    <Route exact path="/edit-category/:categoryId" component={isAuth ? AddEditCategoryComponent : LoginComponent} />
                    <Route exact path="/add-category" component={isAuth ? AddEditCategoryComponent : LoginComponent} />
                    <Route exact path="/system-options" component={isAuth ? SystemOptionsComponent : LoginComponent} />
                    <Route exact path="/feedback" component={isAuth ? FeedbacksListComponent : LoginComponent} />
                    <Route exact path="/polls/:page?" component={isAuth ? PollsListComponent : LoginComponent} />
                    <Route exact path="/polls-views/:page?" component={isAuth ? PollsViewsListComponent : LoginComponent} />
                    <Route exact path="/edit-poll/:pollId?" component={isAuth ? PollComponent : LoginComponent} />
                    <Route exact path="/edit-pollcomment/:commentId" component={isAuth ? EditPollCommentComponent : LoginComponent} />
                    <Route exact path="/pollscomments/:page?" component={isAuth ? PollCommentsListComponent : LoginComponent} />
                    <Route exact path="/polls-votes/:page?" component={isAuth ? PollsOptionsVotesListComponent : LoginComponent} />
                    <Route component={NoMatch} />
                </Switch>
            </>
        )
    }
}



export default withRouter(Content);