import React, { useState } from 'react';
import { Alert, Button, Col, Form, FormGroup, Modal, Row, } from 'react-bootstrap';
import { InputValidationModel } from '../../../common/models/inputValidationModel';
import { dateToLocal } from '../../../common/functions/helperFunctions';
import { PollStatus } from '../../../common/enums';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { PollOptionEditModel } from '../../../models/polls/pollOptionEditModel';
import { PollEditModel } from '../../../models/polls/pollEditModel';
import Spinner from '../../../common/components/spinner/spinner';
import PollsService from '../../../services/pollsService';

const PollEditComponent = ({ pollToEdit, onpPollUpdated }) => {
    const pollsService = new PollsService();
    
    const [touched, setTouched] = useState<boolean>(false);
    const [poll, setPoll] = useState<PollEditModel>(pollToEdit);
    const [validations, setValidations] = useState<Array<InputValidationModel>>(new Array<InputValidationModel>());
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [successMessage, setSuccessMessage] = useState<string>('');
    const [deleteConformationVisible, setDeleteConformationVisible] = useState<boolean>(false);
    const [optionIndexToDelete, setOptionIndexToDelete] = useState<number>(-1);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleChange = (prop, value) => {
        const pollToEdit: PollEditModel = { ...poll };
       
        if(prop === 'isMultiple') {
            pollToEdit.isMultiple = !pollToEdit.isMultiple;
           
        } else {
            pollToEdit[prop] = value;
        }

        setPoll(pollToEdit);
        if(touched) {  validate(); }
    }

    const handleOptionChange = (index: number, prop: string, value: any) => {
        const pollToEdit: PollEditModel = { ...poll };
        let option = pollToEdit.options[index];

        if(option) {
            option[prop] = value;
        }

        setPoll(pollToEdit);
        if(touched) {  validate(); }
    }

    const isNumeric = (value: any) => {
        return /^\d+$/.test(value)
    }

    const validate = () => {
        const pollToEdit: PollEditModel = {...poll};

        setErrorMessage('');
        let validations = new Array<InputValidationModel>();

        if(!pollToEdit.question)
            validations.push({ key: 'question', message: 'Въведете въпрос'});

        if(pollToEdit.question && pollToEdit.question.length < 6)
            validations.push({ key: 'question', message: 'Въпросът трябва да съдържа минимум 6 символа!'});
        
        if(pollToEdit.question && pollToEdit.question.length > 999)
            validations.push({ key: 'question', message: 'Въпросът трябва да съдържа максимум 999 символа!'});

        if(pollToEdit.options) {
            let validOptions = new Array<PollOptionEditModel>();

            pollToEdit.options.forEach((option, index) => {
                if(option.content && option.votesCount >= 0)
                    validOptions.push(option);
                
                if(option.content) {
                    if(option.votesCount < 0 || !isNumeric(option.votesCount)) {
                        validations.push({ key: 'options', message: 'Въведете валидна стойност за брой гласове!'});
                    }
                }
            });

            if(validOptions.length < 2)
                validations.push({ key: 'options', message: 'Въведете поне два отговора!'});
        } else {
            validations.push({ key: 'options', message: 'Въведете поне два отговора!'});
        }

        setValidations(validations);
        setTouched(true);

        return validations.length === 0;
    }

    const handleDeleteBtnPressed = (pollOptionIndx: number) => {
       setDeleteConformationVisible(true);
       setOptionIndexToDelete(pollOptionIndx);
    }

    const handleSaveBtnPressed = async () => {
        setSuccessMessage('');

        if(!validate()) {
            return;
        }

        try {
            setIsLoading(true);
            const pollToUpdate = {...poll};
            await pollsService.updatePoll(pollToUpdate);

            let updatedPoll = await pollsService.getPoll(pollToUpdate.id);

            setIsLoading(false);
            setPoll(updatedPoll);
            setSuccessMessage('Данните бяха записани успешно.');
        } catch (error) {
            setIsLoading(false);
            setErrorMessage(error.message);
        }
    }

    const handleAddOptionPressed = () => {
        if(poll) {
            const pollToEdit = {...poll};

            let newPollOption = new PollOptionEditModel();
            newPollOption.id = '';
            newPollOption.content = '';
            newPollOption.pollId = poll.id;
            newPollOption.votesCount = 0;
            pollToEdit.options.push(newPollOption);
            setPoll(pollToEdit);
        }
    }

    const handleConfirmOptionDeletionPressed = () => {
        const pollToEdit = {...poll};

        if(optionIndexToDelete !== -1 && pollToEdit.options && pollToEdit.options.length > optionIndexToDelete) {
            pollToEdit.options.splice(optionIndexToDelete, 1);
            setPoll(pollToEdit);
        }

        setOptionIndexToDelete(-1);
        setDeleteConformationVisible(false);
    }

    const renderPoll = () => {
        const questionValidationErrors = validations.find(v => v.key === 'question');
        const optionValidationErrors = validations.filter(v => v.key === 'options');

        return (
            <div>
                 {isLoading ? <Spinner /> : null}
                  {poll && !isLoading
                   ?   <>
                       {errorMessage
                            ?   <Alert className="mt-3" variant={'danger'} style={{ fontSize: '85%'}}>
                                    {errorMessage}
                                </Alert>
                        : null}
                        {successMessage
                            ?   <Alert className="mt-3" variant={'success'} style={{ fontSize: '85%'}}>
                                    {successMessage}
                                </Alert>
                        : null}
                        <div style={{ display: 'flex', flexDirection: 'column', fontSize: '85%', marginBottom: 15, marginTop: 15}}>
                            <span style={{ marginBottom: 5 }}>Публикуван: {dateToLocal(poll.createdOn)}</span>
                            <span style={{ marginBottom: 5 }}>Последна промяна: {dateToLocal(poll.updatedOn)}</span>
                            <span style={{ marginBottom: 5 }}>IP адрес: {poll.ipAddress ? poll.ipAddress : 'неизвестен'}</span>
                        </div>
                        <Form>
                            <Form.Group className="mb-3" controlId="pollQuestion">
                                <Form.Label style={{ fontSize: '85%'}}>Въпрос</Form.Label>
                                <Form.Control 
                                    as="textarea" 
                                    size="sm"
                                    rows={3}
                                    value={poll.question} 
                                    type="text" 
                                    onChange={(e) => handleChange('question', e.target['value'])}
                                    isValid={touched && !questionValidationErrors}
                                    isInvalid={questionValidationErrors !== undefined}
                                    placeholder="Въведете въпрос" />
                                <Form.Control.Feedback type="invalid">
                                    {touched && questionValidationErrors ? questionValidationErrors.message : null }
                                </Form.Control.Feedback>
                            </Form.Group>
                            <FormGroup>
                                <Button size='sm' variant='secondary' onClick={handleAddOptionPressed}>Добави отговор</Button>
                            </FormGroup>
                            {touched && optionValidationErrors && optionValidationErrors.length > 0
                             ?  <Alert variant={'danger'} style={{ fontSize: '85%'}}>
                                 {optionValidationErrors.map((error, index) => <div key={`e${index}`}>{error.message}</div>)}
                                </Alert>
                             : null
                            }
                            {poll.options && poll.options.map((option, index) => {
                                return (
                                    <Row key={index} className="align-items-center">
                                        <Col xs={8} sm={9} className="pr-2">
                                            <FormGroup className="mb-3">
                                                <Form.Label style={{ fontSize: '85%'}}>{`Отговор ${index+1}`}</Form.Label>
                                                <Form.Control 
                                                    onChange={(e) => handleOptionChange(index, 'content', e.target['value'])}
                                                    value={option.content} 
                                                    type="text" 
                                                    as="textarea" 
                                                    size="sm"
                                                    rows={2}
                                                    placeholder="Въведете отговор" />
                                            </FormGroup>
                                        </Col>
                                        <Col xs={3} sm={2} className="pl-1 pr-2">
                                            <Form.Group className="mb-3" controlId="optionVotes">
                                                <Form.Label style={{ fontSize: '85%'}}>Гласове</Form.Label>
                                                <Form.Control 
                                                    value={option.votesCount} 
                                                    size="sm"
                                                    onChange={(e) => handleOptionChange(index, 'votesCount', e.target['value'])}
                                                    type="text" />
                                            </Form.Group>
                                        </Col>
                                        <Col xs={1} className="pl-1 pr-2 align-middle">
                                            <FontAwesomeIcon 
                                                icon={faTrashAlt} 
                                                size='lg'
                                                style={{ color: "#dc3545", cursor: 'pointer' }} 
                                                onClick={() => handleDeleteBtnPressed(index)}
                                            />
                                        </Col>
                                    </Row>
                                )
                            })}
                            <Form.Group controlId="isMultiple">
                                <Form.Check 
                                    type={'checkbox'}
                                    checked={poll.isMultiple}
                                    label={'Множество възможни отговори'}
                                    style={{ fontSize: '85%'}}
                                    onChange={(e) => handleChange('isMultiple', e.target['value'])}
                                />
                            </Form.Group>
                            <Form.Group controlId="statusGroup">
                                    <Form.Label style={{ fontSize: '85%'}}>Статус</Form.Label>
                                    <Form.Control size="sm" as="select"
                                        value={poll.statusId}
                                        onChange={(e) => handleChange('statusId', +e.target['value'])}
                                    >
                                        <option value={PollStatus.New}>Нов / чака одобрение</option>
                                        <option value={PollStatus.Active}>Активен</option>
                                        <option value={PollStatus.Stopped}>Спряна / неактивен</option>
                                    </Form.Control>
                            </Form.Group>
                            <FormGroup className="mt-5">
                                <Button variant="primary" onClick={handleSaveBtnPressed}>Запис</Button>
                            </FormGroup>
                        </Form>
                    </>
                 : null}
                  <Modal show={deleteConformationVisible} onHide={() => {
                        setDeleteConformationVisible(false);
                        setOptionIndexToDelete(-1);
                    }}>
                            <Modal.Header closeButton>
                            <Modal.Title>Изтриване на отговор</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>Сигурни ли сте, че искате да изтриете избраният отговор?</Modal.Body>
                            <Modal.Footer>
                            <Button variant="secondary" onClick={() => {
                                setDeleteConformationVisible(false);
                                setOptionIndexToDelete(-1);
                            }}>
                                Не
                            </Button>
                            <Button variant="danger" onClick={handleConfirmOptionDeletionPressed}>
                            Да
                            </Button>
                            </Modal.Footer>
                </Modal>
            </div>
        );
    }

    return (
        <div>
            {renderPoll()}
        </div>
    )
}

export default PollEditComponent;