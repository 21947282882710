import axios from 'axios';
import * as Constants from '../common/constants';
import * as HelperFunctions  from '../common/functions/helperFunctions';
import jwtDecode from 'jwt-decode';
import Cookies from 'universal-cookie';
import moment from 'moment';

class AuthService {
  cookies = new Cookies();

    getUser(userName: string, password: string) {
        let bodyFormData = new FormData();
        bodyFormData.append('username', userName);
        bodyFormData.append('password', password);
        bodyFormData.append('project', "story-app-admin");

        return axios({
            baseURL: `${Constants.tokenUrl}`,
            url: `${Constants.tokenUrl}`,
            method: 'post',
            headers: {
              "Content-Type": "application/json; charset=utf-8"
            },
            data: bodyFormData
          })
            .then(res => res.data)
            .catch(error =>
              Promise.reject(error)
            )
    }

    loginWithCookie(userName: string, password: string){
      let bodyFormData = new FormData();
      bodyFormData.append('username', userName);
      bodyFormData.append('password', password);
      bodyFormData.append('project', "story-app-admin");

      return axios({
          url: `${Constants.tokenUrl}`,
          withCredentials: true,
          method: 'post',
          headers: {
            "Content-Type": "application/json; charset=utf-8"
          },
          data: bodyFormData
        })
          .then(res => res.data)
          .catch(error =>
            Promise.reject(error)
          )
   }

   getRefreshToken(userName: string) {
    let bodyFormData = new FormData();
    bodyFormData.append('refreshToken', 'true');
    bodyFormData.append('username', userName);

    return axios({
      url: `${Constants.tokenUrl}`,
      withCredentials: true,
      method: 'post',
      headers: {
        "Content-Type": "application/json; charset=utf-8"
      },
      data: bodyFormData
    })
      .then(res => res.data)
      .catch(error =>
        Promise.reject(error)
      )
  }

    login(userName: string, password: string) {
        return this.getUser(userName, password)
            .then(
                data => {
                if (data.access_token) {
                    this.setToken(data)
                    return true;
                }
                return false;
                })
            .catch(error =>
                Promise.reject(HelperFunctions.getErrorMessage(error))
            );
    }

    logout = () => {
      this.cookies.remove('X-Login-Details');
    }

    serverLogOut = () => {
      let bodyFormData = new FormData();
      bodyFormData.append('logOut', 'true');

      return axios({
        url: `${Constants.tokenUrl}`,
        withCredentials: true,
        method: 'post',
        headers: {
          "Content-Type": "application/json; charset=utf-8"
        },
        data: bodyFormData
      })
        .then(res => res.data)
        .catch(error =>
          Promise.reject(error)
        )
    }

    setToken(authResult) { 
        localStorage.setItem('id_token', authResult.access_token);
        // let expiresAt = JSON.stringify((authResult.expires_in * 1000) + new Date().getTime());
        // localStorage.setItem('expires_at', expiresAt);
    }

    getToken() {
        return localStorage.getItem('id_token');
    }

    getProfile() {
        const token = this.getToken();
        if (token && token !== "undefined") {
          return jwtDecode(token);
        } else {
          return null;
        }
    }

    isAuthenticated() {
      try {
        let cookie = this.cookies.get('X-Login-Details');

        if(cookie && cookie.userName && cookie.expires) {
          let utcNow = moment.utc().valueOf();
          let expires = moment(cookie.expires).valueOf();

          if (utcNow < expires) {
            return true;
          } else {
            return false;
          }
        }

        return false;

      } catch(error) {
        console.log(error);
        return false;
      }
    }

    getLoginName() {
        let cookie = this.cookies.get('X-Login-Details');

        if(cookie && cookie.userName) {
          return cookie.userName;
        }

        return '';
    }
}

export default AuthService;