import axios from 'axios';
import * as HelperFunctions  from '../common/functions/helperFunctions';
import { CategoryAddEditModel } from '../models/category/categoryAddEditModel';

class CategoriesService {
    //authService = new AuthService();

    // constructor() {
    //     axios.defaults.baseURL = `${Constants.apiUrl}`;
    //     axios.defaults.headers = {
    //         "Content-Type": "application/json; charset=utf-8",
    //         //Authorization: `Bearer ${this.authService.getToken()}`,
    //         Pragma: 'no-cache'
    //     }
    // }

    getAllSimple() {
        return axios({
            url: `categories/listsimple`,
            method: 'get', headers: {
              "Content-Type": "application/json; charset=utf-8",
              //Authorization: `Bearer ${this.authService.getToken()}`,
            },
            withCredentials: true
          })
            .then(res => res.data.data)
            .catch(error => 
              Promise.reject(HelperFunctions.getErrorMessage(error))
          )
    }

    getAll() {
      return axios({
          url: `categories/list`,
          method: 'get', headers: {
            "Content-Type": "application/json; charset=utf-8",
            //Authorization: `Bearer ${this.authService.getToken()}`,
          },
          withCredentials: true
        })
          .then(res => res.data.data)
          .catch(error => 
            Promise.reject(HelperFunctions.getErrorMessage(error))
        )
  }

  get(categoryId: string) {
    return axios({
        url: `categories/${categoryId}`,
        method: 'get', headers: {
          "Content-Type": "application/json; charset=utf-8",
          //Authorization: `Bearer ${this.authService.getToken()}`,
        },
        withCredentials: true
      })
        .then(res => res.data.data)
        .catch(error => 
          Promise.reject(HelperFunctions.getErrorMessage(error))
      )
    }

    create(category: CategoryAddEditModel) {
      return axios({
          url: `categories`,
          method: 'post', headers: {
            "Content-Type": "application/json; charset=utf-8",
            //Authorization: `Bearer ${this.authService.getToken()}`,
          },
          data: JSON.stringify(category),
          withCredentials: true
        })
          .then(res => res.data.data)
          .catch(error => 
            Promise.reject(HelperFunctions.getErrorMessage(error))
        )
      }

      update(category: CategoryAddEditModel) {
        return axios({
            url: `categories`,
            method: 'put', headers: {
              "Content-Type": "application/json; charset=utf-8",
              //Authorization: `Bearer ${this.authService.getToken()}`,
            },
            data: JSON.stringify(category),
            withCredentials: true
          })
            .then(res => res.data.data)
            .catch(error => 
              Promise.reject(HelperFunctions.getErrorMessage(error))
          )
        }
}

export default CategoriesService;