import React from 'react';
import Spinner from '../../common/components/spinner/spinner';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Alert from 'react-bootstrap/Alert';
import { CommentModel } from '../../models/comment/commentModel';
import CommentsService from '../../services/commentsService';
import { dateToLocal, validateEmail } from '../../common/functions/helperFunctions';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { NotificationType, UserRole } from '../../common/enums';
import { InputValidationModel } from '../../common/models/inputValidationModel';
import { UserModel } from '../../models/user/userModel';
import UsersService from '../../services/usersService';
import { UserEditModel } from '../../models/user/userEditModel';
import Card from 'react-bootstrap/esm/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { ApiErrorModel } from '../../common/models/ApiErrorModel';
import { UserChangePasswordModel } from '../../models/user/userChangePasswordModel';
import Tabs from 'react-bootstrap/esm/Tabs';
import Tab from 'react-bootstrap/esm/Tab';
import { StoryGridModel } from '../../models/story/storyGridModel';
import StoriesService from '../../services/storiesService';
import StoriesGrid from '../../common/components/story/storiesGrid';
import CommentsGrid from '../../common/components/comment/commentsGrid';
import { StoryViewModel } from '../../models/story/storyViewModel';
import StoriesViewsGrid from '../../common/components/story/storiesViewsGrid';
import { UserLoginModel } from '../../models/user/userLoginModel';
import UserLoginsGrid from '../../common/components/user/userLoginsGrid';
import { NotificationSubscriptionModel } from '../../models/user/notificationSubscriptionModel';
import PollsGrid from '../../common/components/polls/pollsGrid';
import PollsViewsGrid from '../../common/components/polls/pollsViewsGrid';
import PollCommentsGrid from '../../common/components/pollComments/pollCommentsGrid';
import PollsVotesGrid from '../../common/components/polls/pollsVotesGrid';
import { PollGridModel } from '../../models/polls/pollGridModel';
import PollCommentModel from '../../models/pollComment/pollCommentModel';
import { PollViewModel } from '../../models/polls/pollViewModel';
import { PollOptionVoteModel } from '../../models/polls/pollOptionVoteModel';
import PollsService from '../../services/pollsService';
import PollCommentsService from '../../services/pollCommentsService';

interface IProps {
    history: any;
    match: any;
    location: any;
}

interface IState {
    isLoading: boolean,
    isUpdatingUserProfile: boolean;
    errorMessage: string;
    editProfileErrorMessage: string;
    editProfileWarningMessage: string;
    editProfileSuccessMessage: string;
    changePasswordErrorMessage: string;
    changePasswordWarningMessage: string;
    changePasswordSuccessMessage: string;
    userId: string;
    userEditModel: UserEditModel;
    user: UserModel | null,
    touched: boolean;
    validations: Array<InputValidationModel>;
    changePasswordModel: UserChangePasswordModel;
    changePasswordTouched: boolean;
    selectedTab: string,
    selectedStoryTab: string,
    selectedPollTab: string,
    stories: Array<StoryGridModel>,
    storiesErrorMessage: string,
    comments: Array<CommentModel>;
    commentsErrorMessage: string;
    storiesViews: Array<StoryViewModel>;
    storiesViewsErrorMessage: string;
    logins: Array<UserLoginModel>;
    loginsErrorMessage: string;
    pollsErrorMessage: string;
    pollsVotesErrorMessage: string;
    pollsViewsErrorMessage: string;
    pollCommentsErrorMessage: string;
    polls: Array<PollGridModel>;
    pollsComments: Array<PollCommentModel>;
    pollsViews: Array<PollViewModel>;
    pollsVotes: Array<PollOptionVoteModel>;
}

class EditUserComponent extends React.Component<IProps, IState> {
    usersService = new UsersService();
    storiesService = new StoriesService();
    commentsService = new CommentsService();
    pollsService = new PollsService();
    pollsCommentsService = new PollCommentsService();

    constructor(props: IProps) {
        super(props);

        let userId = this.props.match.params.userId;

        this.state = {
            isLoading: false,
            isUpdatingUserProfile: false,
            errorMessage: '',
            editProfileErrorMessage: '',
            editProfileSuccessMessage: '',
            editProfileWarningMessage: '',
            changePasswordErrorMessage: '',
            changePasswordWarningMessage: '',
            changePasswordSuccessMessage: '',
            userId,
            user: null,
            userEditModel: new UserEditModel(),
            validations: new Array<InputValidationModel>(),
            touched: false,
            changePasswordModel: new UserChangePasswordModel(),
            changePasswordTouched: false,
            selectedTab: 'profile',
            selectedStoryTab: 'stories-list',
            selectedPollTab: 'polls-list',
            stories: new Array<StoryGridModel>(),
            storiesErrorMessage: '',
            comments: new Array<CommentModel>(),
            commentsErrorMessage: '',
            storiesViews: new Array<StoryViewModel>(),
            storiesViewsErrorMessage: '',
            logins: new Array<UserLoginModel>(),
            loginsErrorMessage: '',
            pollsErrorMessage: '',
            pollsVotesErrorMessage: '',
            pollsViewsErrorMessage: '',
            pollCommentsErrorMessage: '',
            polls: new Array<PollGridModel>(),
            pollsComments: new Array<PollCommentModel>(),
            pollsViews: new Array<PollViewModel>(),
            pollsVotes: new Array<PollOptionVoteModel>(),
        }
    }

    async componentDidMount() {
        await this.getUser()
    }

    getUser = async () => {
        const { userId, userEditModel } = this.state;

        if(!userId) { return; }

        try {
            this.setState({
                isLoading: true,
                errorMessage: '',
            });

            let user: UserModel = await this.usersService.getUser(userId);

            if(user) {
                userEditModel.id = user.id;
                userEditModel.email = user.email;
                userEditModel.userName = user.userName;
                userEditModel.emailVerified = user.emailVerified;
                userEditModel.isActive = user.isActive;
                userEditModel.roleId = user.roleId;
            }

            this.setState({
                isLoading: false,
                user,
                userEditModel
            });
        } catch (error) {
            this.setState({
                isLoading: false,
                errorMessage: error.message
            });
        }
    }

    handleChange = (key: any, value: any) => {
        const userEditModel: UserEditModel = { ...this.state.userEditModel };
        let touched = this.state.touched;

        if(key === 'emailVerified') {
            userEditModel.emailVerified = !userEditModel.emailVerified;
        } else if(key === 'isActive') {
            userEditModel.isActive = !userEditModel.isActive;
        } else {
            userEditModel[key] = value;
        }

        if(key === 'email' && !value) {
            userEditModel.emailVerified = false;
        }

        this.setState({
            userEditModel,
            touched
        }, () => this.state.touched ? this.validateUserProfile() : null);
    }

    handlePasswordChange(key: any, value: any) {
        const changePasswordModel: UserChangePasswordModel = { ...this.state.changePasswordModel };
        let changePasswordTouched = this.state.changePasswordTouched;

        changePasswordModel[key] = value;

        this.setState({
            changePasswordModel,
            changePasswordTouched
        }, () => this.state.changePasswordTouched ? this.validateChangePassword() : null);

    }

    validateUserProfile = () => {
        const { userEditModel } = this.state;

        this.setState({
            editProfileErrorMessage: '',
            editProfileSuccessMessage: '',
            editProfileWarningMessage: '',
            changePasswordSuccessMessage: '',
            changePasswordErrorMessage: '',
            changePasswordWarningMessage: '',
        });

        let validations = new Array<InputValidationModel>();

        if(!userEditModel.userName) {
            validations.push({ key: 'userName', message: 'Въведете потребителско име.'});
        } else {
            let userName = userEditModel.userName.trim();

            if(userName === '')
                validations.push({ key: 'userName', message: 'Въведете потребителско име.'});
            
            if(userName.length < 4)
                validations.push({ key: 'userName', message: 'Потребителското име трябва да съдържа минимум 4 символа.'});

            if(userName.length < 4)
                validations.push({ key: 'userName', message: 'Потребителското име трябва да съдържа максимум 30 символа.'});
        }

        if(userEditModel.email) {
            if(!validateEmail(userEditModel.email))
                validations.push({ key: 'email', message: 'Въведеният имейл е невалиден'});
        }

        this.setState({
            validations,
            touched: true,
            changePasswordTouched: false,
        });

        return validations.length === 0;
    }

    handleEditUserProfileBtnClick = async () => {
        if(!this.validateUserProfile()) { return }
        
        try {
            this.setState({
                isUpdatingUserProfile: true
            });
            
            let userProfileEditModel = { ...this.state.userEditModel };
            userProfileEditModel.userName = userProfileEditModel.userName.trim();

            if(userProfileEditModel.email) {
                userProfileEditModel.email = userProfileEditModel.email.trim();
            } 
            
            await this.usersService.updateProfile(userProfileEditModel);
            await this.getUser();

            this.setState({
                isUpdatingUserProfile: false,
                editProfileSuccessMessage: 'Данните бяха записани успешно.',
                touched: false,
            });

        } catch(error) {
            this.setState({
                isUpdatingUserProfile: false,
            }, () => this.validateUserProfile());
            this.processEditProfileApiErrors(error);
        }
    }

    validateChangePassword = () => {
        const { changePasswordModel } = this.state;
        let validations = new Array<InputValidationModel>();

        this.setState({
            editProfileErrorMessage: '',
            editProfileSuccessMessage: '',
            editProfileWarningMessage: '',
            changePasswordSuccessMessage: '',
            changePasswordErrorMessage: '',
            changePasswordWarningMessage: '',
        });

        if(!changePasswordModel.password) {
            validations.push({ key: 'password', message: 'Въведете нова парола.' });
        } else {
            if(changePasswordModel.password.length < 6) {
                validations.push({ key: 'password', message: 'Паролата трябва да съдържа минимум 6 символа.' });
            }

            if(changePasswordModel.password.length > 30) {
                validations.push({ key: 'password', message: 'Паролата не трябва да съдържа повече от 30 символа.' });
            }
        }

        if(!changePasswordModel.confirmPassword) {
            validations.push({ key: 'confirmPassword', message: 'Потвърдете паролата.' });
        } else {
            if(changePasswordModel.password && (changePasswordModel.password !== changePasswordModel.confirmPassword)) {
                validations.push({ key: 'confirmPassword', message: 'Въведените пароли не съвпадат.' });
            }
        }

        this.setState({
            changePasswordTouched: true,
            touched: false,
            validations
        });

        return validations.length === 0;
    }

    handleChangePasswordBtnClicked = async () => {
        if(!this.validateChangePassword()) { return; }

        try {
            this.setState({
                isUpdatingUserProfile: true
            });

            const { changePasswordModel, userId } = this.state;
            changePasswordModel.userId = userId;

            await this.usersService.changePassword(changePasswordModel);

            this.setState({
                isUpdatingUserProfile: false,
                changePasswordModel: new UserChangePasswordModel(),
                changePasswordSuccessMessage: 'Паролата на потребителя беше променена успешно.',
                changePasswordTouched: false,
            });

        } catch (error) {
            this.setState({
                isUpdatingUserProfile: false,
            }, () => this.validateChangePassword());
            this.processChangePasswordApiErrors(error);
        }
    }

    processEditProfileApiErrors = (error: ApiErrorModel) => {
        let validations = [...this.state.validations];
        let editProfileErrorMessage = '';
        let editProfileWarningMessage = '';

        if(error.errorCode) {
            switch (error.errorCode) {
                case '1054' :
                case '1055' :
                case '1058' :
                    validations.push({ key: 'userName', message: error.message });
                    break;
                case '1056' : 
                case '1057' :
                    validations.push({ key: 'email', message: error.message });
                    break;
                default: 
                    editProfileErrorMessage = error.message;
                    break;
            }
        } else {
            editProfileErrorMessage = error.message; 
        }

        if(!editProfileErrorMessage) {
            editProfileWarningMessage = 'Моля, отстранете възникналите грешки и опитайте отново.';
        }

        this.setState({
            validations,
            editProfileErrorMessage,
            editProfileWarningMessage
        });
    }

    processChangePasswordApiErrors = (error: ApiErrorModel) => {
        let validations = [...this.state.validations];
        let changePasswordErrorMessage = '';
        let changePasswordWarningMessage = '';

        if(error.errorCode) {
            switch (error.errorCode) {
                case '1060' :
                case '1062' :
                case '1063' :
                    validations.push({ key: 'password', message: error.message });
                    break;
                case '1061' : 
                case '1064' :
                    validations.push({ key: 'confirmPassword', message: error.message });
                    break;
                default: 
                    changePasswordErrorMessage = error.message;
                    break;
            }
        } else {
            changePasswordErrorMessage = error.message; 
        }

        if(!changePasswordErrorMessage) {
            changePasswordWarningMessage = 'Моля, отстранете възникналите грешки и опитайте отново.';
        }

        this.setState({
            validations,
            changePasswordErrorMessage,
            changePasswordWarningMessage
        });
    }

    changeTab = (key: any) => {
        const { stories, logins } = this.state;
        if(key === 'stories' && stories.length === 0) {
            this.setState({
                selectedTab: key
            }, async () => await this.getStories())
        } else if(key === 'polls' && stories.length === 0) {
                this.setState({
                    selectedTab: key
                }, async () => await this.getPolls())
        } else if(key === 'logins' && logins.length === 0) {
            this.setState({
                selectedTab: key
            }, async () => await this.getLogins())
        }  else {
            this.setState({ selectedTab: key });
        }
    }

    changeStoryTab = (key: any) => {
        const { stories, comments, storiesViews } = this.state;
        if(key === 'stories-list' && stories.length === 0) {
            this.setState({
                selectedStoryTab: key
            }, async () => await this.getStories())
        } else if(key === 'stories-comments' && comments.length === 0) {
            this.setState({
                selectedStoryTab: key
            }, async () => await this.getComments())
        } else if(key === 'stories-views' && storiesViews.length === 0) {
            this.setState({
                selectedStoryTab: key
            }, async () => await this.getStoriesViews())
        } else {
            this.setState({ selectedStoryTab: key });
        }
    }

    changePollTab = (key: any) => {
        const { polls, pollsComments, pollsViews, pollsVotes } = this.state;
        if(key === 'polls-list' && polls.length === 0) {
            this.setState({
                selectedPollTab: key
            }, async () => await this.getPolls())
        } else if(key === 'polls-comments' && pollsComments.length === 0) {
            this.setState({
                selectedPollTab: key
            }, async () => await this.getPollsComments())
        } else if(key === 'polls-views' && pollsViews.length === 0) {
            this.setState({
                selectedPollTab: key
            }, async () => await this.getPollsViews())
        } else if(key === 'polls-votes' && pollsVotes.length === 0) {
            this.setState({
                selectedPollTab: key
            }, async () => await this.getPollsVotes())
        } else {
            this.setState({ selectedPollTab: key });
        }
    }

    renderProfile = () => {
        const { errorMessage, user, userEditModel, validations, touched, editProfileErrorMessage, 
            editProfileSuccessMessage, editProfileWarningMessage, changePasswordModel, changePasswordTouched } = this.state;

        const userProfileUserNameValidationErrors = validations.find(v => v.key === 'userName');
        const userProfileEmailValidationErrors = validations.find(v => v.key === 'email');
        const changePasswordPasswordValidations = validations.find(v => v.key === 'password');
        const changePasswordConfirmPasswordValidations = validations.find(v => v.key === 'confirmPassword');

        return (
            <div style={{ marginBottom: 80, marginTop: 10}}>
                {errorMessage ? 
                <Alert variant={'danger'}>
                    {errorMessage}
                </Alert>
                : null}

                {user
                 ? <div>
                     <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 10, fontSize: '85%'}}>
                        <span>Създаден: {dateToLocal(user.createdOn)}</span>
                        <span>Последна промяна: {dateToLocal(user.updatedOn)}</span>
                    </div>
                    <Card>
                        <Card.Body style={ !user.isActive ? { backgroundColor: '#F5B7B1'}: {}}>
                            {editProfileSuccessMessage ? 
                                <Alert variant={'success'} style={{ fontSize: '85%'}}>
                                    {editProfileSuccessMessage}
                                </Alert>
                            : null}
                             {editProfileErrorMessage ? 
                                <Alert variant={'danger'} style={{ fontSize: '85%'}}>
                                    {editProfileErrorMessage}
                                </Alert>
                            : null}
                             {editProfileWarningMessage ? 
                                <Alert variant={'warning'} style={{ fontSize: '85%'}}>
                                    {editProfileWarningMessage}
                                </Alert>
                            : null}
                            <Form>
                                <Form.Group as={Row} controlId="userNameGroup">
                                        <Form.Label column sm="2" style={{ fontSize: '85%'}}>Потребителско име</Form.Label>
                                        <Col sm={10}>
                                            <Form.Control 
                                                type="text" 
                                                size="sm" 
                                                isValid={touched && !userProfileUserNameValidationErrors} 
                                                isInvalid={userProfileUserNameValidationErrors !== undefined}
                                                onChange={(e) => this.handleChange('userName', e.target['value'])}
                                                value={userEditModel.userName}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {touched && userProfileUserNameValidationErrors !== undefined 
                                                    ? userProfileUserNameValidationErrors.message 
                                                    : null }
                                            </Form.Control.Feedback>
                                        </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="emailGroup">
                                        <Form.Label column sm="2" style={{ fontSize: '85%'}}>Имейл</Form.Label>
                                        <Col sm={10}>
                                            <Form.Control 
                                                type="text" 
                                                size="sm" 
                                                isValid={touched && userEditModel.email !== '' && !userProfileEmailValidationErrors} 
                                                isInvalid={userProfileEmailValidationErrors !== undefined}
                                                onChange={(e) => this.handleChange('email', e.target['value'])}
                                                value={userEditModel.email ? userEditModel.email : ''}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {touched && userProfileEmailValidationErrors !== undefined 
                                                    ? userProfileEmailValidationErrors.message 
                                                    : null }
                                            </Form.Control.Feedback>
                                        </Col>
                                </Form.Group>
                                <Form.Group controlId="emailVerifiedGroup">
                                    <Form.Check 
                                        type={'checkbox'}
                                        disabled={userEditModel.email === null || userEditModel.email === ''}
                                        checked={userEditModel && userEditModel.emailVerified ? true : false}
                                        label={'Имейлът е потвърден'}
                                        style={{ fontSize: '85%'}}
                                        onChange={(e) => this.handleChange('emailVerified', e.target['value'])}
                                    />
                                </Form.Group>
                                <Form.Group controlId="isActiveGroup">
                                    <Form.Check 
                                        type={'checkbox'}
                                        checked={userEditModel.isActive}
                                        label={'Потребителят е активен'}
                                        style={{ fontSize: '85%'}}
                                        onChange={(e) => this.handleChange('isActive', e.target['value'])}
                                    />
                                </Form.Group>
                                <Form.Group as={Row} controlId="userRoleGroup">
                                    <Form.Label column sm="2" style={{ fontSize: '85%'}}>Роля</Form.Label>
                                    <Col sm="10">
                                        <Form.Control 
                                            size="sm" 
                                            as="select"
                                            value={userEditModel.roleId}
                                            onChange={(e) => this.handleChange('roleId', +e.target['value'])}>
                                            <option value={UserRole.Administrator}>Администратор</option>
                                            <option value={UserRole.User}>Потребител</option>
                                        </Form.Control>
                                    </Col>
                                </Form.Group>
                            </Form>
                            <br />
                            <Form.Group controlId="notificationSubscriptions" style={{ fontSize: '85%'}}>
                                <Form.Check 
                                    checked={user.notificationSubscriptions.find(s => s === NotificationType.StoryApproved) ? true : false} 
                                    id={NotificationType.StoryApproved.toString()}
                                    onChange={() => this.handleNotificationSubscriptionChange(NotificationType.StoryApproved)} 
                                    type="switch" 
                                    label="Известие при одобрена история"
                                    disabled={ !user.emailVerified} 
                                    style={{ color: '#000000'}}/>
                                <div style={{ marginLeft: 37, marginBottom: 15}}>
                                    <span style={{ fontStyle: 'italic'}}>
                                        Изпращане на известие към потребителя при одобрение на негова история. 
                                    </span>
                                </div>
                                <Form.Check 
                                    checked={user.notificationSubscriptions.find(s => s === NotificationType.CommentInUserStory) ? true : false}
                                    id={NotificationType.CommentInUserStory.toString()}
                                    onChange={() => this.handleNotificationSubscriptionChange(NotificationType.CommentInUserStory)}
                                    type="switch" 
                                    label="Известия при добавен коментар в истории публикувани от потребителят"
                                    disabled={ !user.emailVerified}
                                    style={{ color: '#000000'}}/>
                                <div style={{ marginLeft: 37, marginBottom: 15}}>
                                    <span style={{ fontStyle: 'italic'}}>
                                        Изпращане на известия при добавен нов коментар към истории, публикувани от потребителят.
                                    </span>
                                </div>
                                <Form.Check 
                                    checked={user.notificationSubscriptions.find(s => s === NotificationType.CommnetInCommentedStory) ? true : false}
                                    id={NotificationType.CommnetInCommentedStory.toString()}
                                    onChange={() => this.handleNotificationSubscriptionChange(NotificationType.CommnetInCommentedStory)}
                                    type="switch" 
                                    label="Известия при добавен коментар в истории коментирани от потребителят"
                                    disabled={ !user.emailVerified} 
                                    style={{ color: '#000000'}} />
                                <div style={{ marginLeft: 37, marginBottom: 15}}>
                                    <span style={{ fontStyle: 'italic'}}>
                                        Изпращане на известия при добавен нов коментар към истории, които потребителят е коментирал.
                                    </span>
                                </div>
                        </Form.Group>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginTop: 5}}> 
                                <Button size="sm" variant="primary" onClick={this.handleEditUserProfileBtnClick}>Запис</Button>
                            </div>
                        </Card.Body>
                    </Card> 
                    <Card style={{ marginTop: 10}}>
                        <Card.Body>
                            {this.state.changePasswordSuccessMessage ? 
                                <Alert variant={'success'} style={{ fontSize: '85%'}}>
                                    {this.state.changePasswordSuccessMessage}
                                </Alert>
                            : null}
                            {this.state.changePasswordErrorMessage ? 
                                <Alert variant={'danger'} style={{ fontSize: '85%'}}>
                                    {this.state.changePasswordErrorMessage}
                                </Alert>
                            : null}
                            {this.state.changePasswordWarningMessage ? 
                                <Alert variant={'warning'} style={{ fontSize: '85%'}}>
                                    {this.state.changePasswordWarningMessage}
                                </Alert>
                            : null}
                            <Form>
                                <Form.Group as={Row} controlId="newPasswordGroup">
                                        <Form.Label column sm="2" style={{ fontSize: '85%'}}>Нова парола</Form.Label>
                                        <Col sm="10">
                                            <Form.Control 
                                                type="password" 
                                                size="sm" 
                                                isValid={changePasswordTouched && !changePasswordPasswordValidations} 
                                                isInvalid={changePasswordPasswordValidations !== undefined}
                                                onChange={(e) => this.handlePasswordChange('password', e.target['value'])}
                                                value={changePasswordModel.password ? changePasswordModel.password : ''}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {changePasswordTouched && changePasswordPasswordValidations !== undefined 
                                                    ? changePasswordPasswordValidations.message 
                                                    : null }
                                            </Form.Control.Feedback>
                                        </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="confirmNewPassword">
                                        <Form.Label column sm="2" style={{ fontSize: '85%'}}>Потвърди паролата</Form.Label>
                                        <Col sm="10">
                                            <Form.Control 
                                                type="password" 
                                                size="sm" 
                                                isValid={changePasswordTouched && !changePasswordConfirmPasswordValidations} 
                                                isInvalid={changePasswordConfirmPasswordValidations !== undefined}
                                                onChange={(e) => this.handlePasswordChange('confirmPassword', e.target['value'])}
                                                value={changePasswordModel.confirmPassword ? changePasswordModel.confirmPassword : ''}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {changePasswordTouched && changePasswordConfirmPasswordValidations !== undefined 
                                                    ? changePasswordConfirmPasswordValidations.message 
                                                    : null }
                                            </Form.Control.Feedback>
                                        </Col>
                                </Form.Group>
                            </Form>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginTop: 5}}> 
                                <Button size="sm" variant="primary" onClick={this.handleChangePasswordBtnClicked}>Промени</Button>
                            </div>
                        </Card.Body>
                    </Card>
                  
                </div>
               : null}
            </div>
        );
    }

    getStories = async () => {
        const { userId } = this.state;

        try {
            this.setState({
                isLoading: true,
                storiesErrorMessage: '',
            });

            let stories = await this.storiesService.getByUser(userId);
            console.log(stories);

            this.setState({
                isLoading: false,
                stories
            })
        } catch (error) {
            this.setState({
                isLoading: false,
                storiesErrorMessage: error.message,
            })
        }
    }

    handleNotificationSubscriptionChange = async (notificationType: NotificationType) => {
        this.setState({
            isLoading: true
        });

        try {
            const { user, userId } = this.state;

            let subscription = new NotificationSubscriptionModel();
            subscription.type = notificationType;

            if(!user?.notificationSubscriptions.find(s => s === notificationType)) {
                await this.usersService.addNotificationSubscription(userId, subscription);
                user?.notificationSubscriptions.push(notificationType);
                this.setState({ isLoading: false, user })
            } else {
                await this.usersService.removeNotificationSubscription(userId, subscription);
                user.notificationSubscriptions.splice(user.notificationSubscriptions.findIndex(c => c === notificationType), 1)
                this.setState({ isLoading: false, user });
            }
        } catch (error) {
            this.setState({ 
                isLoading: false,
                errorMessage: error.message 
            });
        }
    }

    getComments = async () => {
        const { userId } = this.state;

        try {
            this.setState({
                isLoading: true,
                commentsErrorMessage: '',
            });

            let comments = await this.commentsService.getByUser(userId);

            this.setState({
                isLoading: false,
                comments
            });
        } catch (error) {
            this.setState({
                isLoading: false,
                commentsErrorMessage: error.message,
            })
        }
    }

    getStoriesViews = async () => {
        const { userId } = this.state;

        try {
            this.setState({
                isLoading: true,
                storiesViewsErrorMessage: '',
            });

            let views = await this.storiesService.getStoriesViewsByUser(userId);

            this.setState({
                isLoading: false,
                storiesViews: views,
            });
        } catch (error) {
            this.setState({
                isLoading: false,
                storiesViewsErrorMessage: error.message,
            })
        }
    }

    getLogins = async () => {
        const { userId } = this.state;

        try {
            this.setState({
                isLoading: true,
                loginsErrorMessage: '',
            });

            let logins = await this.usersService.getUserLogins(userId);

            this.setState({
                isLoading: false,
                logins
            });
        } catch (error) {
            this.setState({
                isLoading: false,
                loginsErrorMessage: error.message,
            })
        }
    }

    getPolls = async () => {
        const { userId } = this.state;

        try {
            this.setState({
                isLoading: true,
                pollsErrorMessage: '',
            });

            let polls = await this.pollsService.getByUser(userId);

            this.setState({
                isLoading: false,
                polls
            })
        } catch (error) {
            this.setState({
                isLoading: false,
                pollsErrorMessage: error.message,
            })
        }
    }

    getPollsComments = async () => {
        const { userId } = this.state;

        try {
            this.setState({
                isLoading: true,
                pollCommentsErrorMessage: '',
            });

            let pollsComments = await this.pollsCommentsService.getByUser(userId);

            this.setState({
                isLoading: false,
                pollsComments
            })
        } catch (error) {
            this.setState({
                isLoading: false,
                pollCommentsErrorMessage: error.message,
            })
        }
    }

    getPollsViews = async () => {
        const { userId } = this.state;

        try {
            this.setState({
                isLoading: true,
                pollsViewsErrorMessage: '',
            });

            let pollsViews = await this.pollsService.getPollsViewsByUser(userId);

            this.setState({
                isLoading: false,
                pollsViews
            })
        } catch (error) {
            this.setState({
                isLoading: false,
                pollsViewsErrorMessage: error.message,
            })
        }
    }

    getPollsVotes = async () => {
        const { userId } = this.state;

        try {
            this.setState({
                isLoading: true,
                pollsVotesErrorMessage: '',
            });

            let pollsVotes = await this.pollsService.getPollsVotesByUser(userId);

            this.setState({
                isLoading: false,
                pollsVotes
            })
        } catch (error) {
            this.setState({
                isLoading: false,
                pollsVotesErrorMessage: error.message,
            })
        }
    }

    render() {
        const { isLoading, isUpdatingUserProfile, user, editProfileSuccessMessage, changePasswordSuccessMessage, 
            selectedTab, stories, storiesErrorMessage, commentsErrorMessage, comments, storiesViews, storiesViewsErrorMessage,
            logins, loginsErrorMessage, selectedStoryTab, pollsErrorMessage, pollsVotesErrorMessage, pollsViewsErrorMessage,
            pollCommentsErrorMessage, polls, pollsComments, pollsViews, pollsVotes, selectedPollTab } = this.state;

        if(editProfileSuccessMessage || changePasswordSuccessMessage) {
            setTimeout(() => {
                this.setState({ 
                    editProfileSuccessMessage: '',
                    changePasswordSuccessMessage: '',
                });
            }, 5000)
        }

        return (
            <div style={{ marginBottom : 80 }}>
                {isLoading || isUpdatingUserProfile ? <Spinner /> : null}

                <Breadcrumb style={{ fontSize: '85%'}}>
                    <Breadcrumb.Item href="#">Начало</Breadcrumb.Item>
                    <Breadcrumb.Item href="/users">Потребители</Breadcrumb.Item>
                    <Breadcrumb.Item active>Редакция</Breadcrumb.Item>
                </Breadcrumb>

                <h5>
                    Потребител 
                    {user && user.userName ? <span style={{ color: '#FF7851'}}> {user.userName}</span> : null}
                </h5>

                <Tabs
                    id="userTabs"
                    activeKey={selectedTab}
                    onSelect={(k) => this.changeTab(k)}
                    style={{ marginTop: 15 }}
                    >
                    <Tab eventKey="profile" title="Профил">
                        {this.renderProfile()}
                    </Tab>
                    <Tab eventKey="stories" 
                        title={`Истории`} 
                        disabled={isLoading || (user?.storiesCount === 0 && user?.storyViewCount === 0 && user?.commentsCount === 0)}
                        style={{ marginTop: 15 }}>
                        <Tabs
                            id="storyTabs"
                            variant="pills"
                            style={{ justifyContent: 'center'}}
                            activeKey={selectedStoryTab}
                            onSelect={(k) => this.changeStoryTab(k)}>
                                   <Tab eventKey="stories-list" 
                                    title={`Списък (${user ? user.storiesCount : 0})`} 
                                    disabled={!isLoading && user?.storiesCount === 0}>
                                        <div style={{ marginTop: 10}}>
                                            {storiesErrorMessage ? 
                                                    <Alert variant={'danger'} style={{ fontSize: '85%'}}>
                                                        {storiesErrorMessage}
                                                    </Alert>
                                                : null}
                                            
                                            <StoriesGrid 
                                                stories={stories}
                                                excludeColumns={['User', 'Delete']}
                                            />
                                        </div>
                                    </Tab>
                                    <Tab eventKey="stories-views" 
                                        title={`Прегледи (${user ? user.storyViewCount : 0})`}
                                        disabled={!isLoading && user?.storyViewCount === 0}>
                                        <div style={{ marginTop: 10}}>
                                            {storiesViewsErrorMessage ? 
                                                <Alert variant={'danger'} style={{ fontSize: '85%'}}>
                                                    {storiesViewsErrorMessage}
                                                </Alert>
                                            : null}
                                            <StoriesViewsGrid 
                                                views={storiesViews}
                                                excludeColumns={['User']}
                                            />
                                        </div>
                                    </Tab>
                                    <Tab eventKey="stories-comments" 
                                        title={`Коментари (${user ? user.commentsCount : 0})`}
                                        disabled={!isLoading && user?.commentsCount === 0}>
                                        <div style={{ marginTop: 10}}>
                                            {commentsErrorMessage ? 
                                                <Alert variant={'danger'} style={{ fontSize: '85%'}}>
                                                    {commentsErrorMessage}
                                                </Alert>
                                            : null}
                                            <CommentsGrid 
                                                comments={comments}
                                                history={this.props.history}
                                                excludeColumns={['User', 'Delete']}
                                            />
                                        </div>
                                    </Tab>
                            </Tabs>
                    </Tab>
                    <Tab eventKey="polls" 
                        title={`Въпроси`} 
                        disabled={isLoading || 
                            (user?.pollsViewsCount === 0 && user?.pollsCount === 0 && user?.pollsCommentsCount === 0 && user?.pollsVotesCount === 0)}
                        style={{ marginTop: 15 }}>
                        <Tabs
                            id="pollTabs"
                            variant="pills"
                            style={{ justifyContent: 'center'}}
                            activeKey={selectedPollTab}
                            onSelect={(k) => this.changePollTab(k)}>
                                   <Tab eventKey="polls-list" 
                                    title={`Списък (${user ? user.pollsCount : 0})`} 
                                    disabled={!isLoading && user?.pollsCount === 0}>
                                        <div style={{ marginTop: 10}}>
                                            {pollsErrorMessage ? 
                                                    <Alert variant={'danger'} style={{ fontSize: '85%'}}>
                                                        {pollsErrorMessage}
                                                    </Alert>
                                                : null}
                                            
                                            <PollsGrid 
                                                polls={polls}
                                                excludeColumns={['User', 'Delete']}
                                                onDeletePoll={() => console.log('delete_poll')}
                                            />
                                        </div>
                                    </Tab>
                                    <Tab eventKey="polls-votes" 
                                        title={`Гласувания (${user ? user.pollsVotesCount : 0})`}
                                        disabled={!isLoading && user?.pollsVotesCount === 0}>
                                        <div style={{ marginTop: 10}}>
                                            {pollsVotesErrorMessage ? 
                                                <Alert variant={'danger'} style={{ fontSize: '85%'}}>
                                                    {pollsVotesErrorMessage}
                                                </Alert>
                                            : null}
                                            <PollsVotesGrid 
                                                votes={pollsVotes}
                                                excludeColumns={['User', 'Delete']}
                                            />
                                        </div>
                                    </Tab>
                                    <Tab eventKey="polls-views" 
                                        title={`Прегледи (${user ? user.pollsViewsCount : 0})`}
                                        disabled={!isLoading && user?.pollsViewsCount === 0}>
                                        <div style={{ marginTop: 10}}>
                                            {pollsViewsErrorMessage ? 
                                                <Alert variant={'danger'} style={{ fontSize: '85%'}}>
                                                    {pollsViewsErrorMessage}
                                                </Alert>
                                            : null}
                                            <PollsViewsGrid 
                                                views={pollsViews}
                                                excludeColumns={['User']}
                                            />
                                        </div>
                                    </Tab>
                                    <Tab eventKey="polls-comments" 
                                        title={`Коментари (${user ? user.pollsCommentsCount : 0})`}
                                        disabled={!isLoading && user?.pollsCommentsCount === 0}>
                                        <div style={{ marginTop: 10}}>
                                            {pollCommentsErrorMessage ? 
                                                <Alert variant={'danger'} style={{ fontSize: '85%'}}>
                                                    {pollCommentsErrorMessage}
                                                </Alert>
                                            : null}
                                            <PollCommentsGrid 
                                                comments={pollsComments}
                                                excludeColumns={['User', 'Delete']}
                                            />
                                        </div>
                                    </Tab>
                                </Tabs>
                            </Tab>
                    <Tab eventKey="logins" 
                        title={`Влизания (${user ? user.loginsCount : 0})`}
                        disabled={!isLoading && user?.loginsCount === 0}>
                           <div style={{ marginTop: 10}}>
                                {loginsErrorMessage ? 
                                    <Alert variant={'danger'} style={{ fontSize: '85%'}}>
                                        {loginsErrorMessage}
                                    </Alert>
                                : null}
                                <UserLoginsGrid 
                                    logins={logins}
                                    excludeColumns={['User']}
                                />
                            </div>
                    </Tab>
                </Tabs>
            </div>
        )
    }
}

export default EditUserComponent;