import axios from 'axios';
import * as HelperFunctions  from '../common/functions/helperFunctions';
import { PollEditModel } from '../models/polls/pollEditModel';
import { PollListFilterModel } from '../models/polls/pollListFilterModel';
import { PollOptionVotesListFilterModel } from '../models/polls/pollOptionVotesListFilterModel';
import { PollViewsListFilterModel } from '../models/polls/pollViewsListFilterModel';

class PollsService {
    getAll(from: number, filter: PollListFilterModel) {
        return axios({
            url: `polls/list/${from}`,
            method: 'post', headers: {
              "Content-Type": "application/json; charset=utf-8",
            },
            data: JSON.stringify(filter),
            withCredentials: true
          })
            .then(res => res.data.data)
            .catch(error => 
              Promise.reject(HelperFunctions.getErrorMessage(error))
          )
    }

    getPoll(pollId: string) {
      return axios({
        url: `polls/${pollId}`,
        method: 'get', headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
        withCredentials: true
      })
        .then(res => res.data.data)
        .catch(error => 
          Promise.reject(HelperFunctions.getErrorMessage(error))
      )
    }

    updatePoll(pollEditModel: PollEditModel) {
      return axios({
        url: `polls`,
        method: 'post', headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
        withCredentials: true,
        data: JSON.stringify(pollEditModel)
      })
        .then(res => res.data.data)
        .catch(error => 
          Promise.reject(HelperFunctions.getErrorMessage(error))
      )
    }

    getPollOptionVotes(pollOptionId: string) {
      return axios({
        url: `polls/option/${pollOptionId}/get-votes`,
        method: 'get', headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
        withCredentials: true
      })
        .then(res => res.data.data)
        .catch(error => 
          Promise.reject(HelperFunctions.getErrorMessage(error))
      )
    }

    getPollViews(pollId: string) {
      return axios({
        url: `polls/views/${pollId}`,
        method: 'get', headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
        withCredentials: true
      })
        .then(res => res.data.data)
        .catch(error => 
          Promise.reject(HelperFunctions.getErrorMessage(error))
      )
    }

    getAllPollsViews(from: number, filter: PollViewsListFilterModel) {
      return axios({
        url: `polls/views/${from}`,
        method: 'post', headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
        data: JSON.stringify(filter),
        withCredentials: true
      })
        .then(res => res.data.data)
        .catch(error => 
          Promise.reject(HelperFunctions.getErrorMessage(error))
      )
    }

    getAllPollsOptionsVotes(from: number, filter: PollOptionVotesListFilterModel) {
      return axios({
        url: `polls/votes/${from}`,
        method: 'post', headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
        data: JSON.stringify(filter),
        withCredentials: true
      })
        .then(res => res.data.data)
        .catch(error => 
          Promise.reject(HelperFunctions.getErrorMessage(error))
      )
    }

    getByUser(userId: string) {
      return axios({
        url: `polls/user/${userId}`,
        method: 'get', headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
        withCredentials: true
      })
        .then(res => res.data.data)
        .catch(error => 
          Promise.reject(HelperFunctions.getErrorMessage(error))
      )
    }

    getPollsViewsByUser(userId: string) {
      return axios({
        url: `polls/views/byUser/${userId}`,
        method: 'get', headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
        withCredentials: true
      })
        .then(res => res.data.data)
        .catch(error => 
          Promise.reject(HelperFunctions.getErrorMessage(error))
      )
    }

    getPollsVotesByUser(userId: string) {
      return axios({
        url: `polls/votes/byUser/${userId}`,
        method: 'get', headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
        withCredentials: true
      })
        .then(res => res.data.data)
        .catch(error => 
          Promise.reject(HelperFunctions.getErrorMessage(error))
      )
    }

    deletePoll(pollId: string) {
      return axios({
        url: `polls/${pollId}`,
        method: 'delete', headers: {
          "Content-Type": "application/json; charset=utf-8",
          //Authorization: `Bearer ${this.authService.getToken()}`,
        },
        withCredentials: true
      })
        .then(res => res.data.data)
        .catch(error => 
          Promise.reject(HelperFunctions.getErrorMessage(error))
      )
    }
}

export default PollsService;