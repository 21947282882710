import React from 'react';
import Spinner from '../../common/components/spinner/spinner';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Alert from 'react-bootstrap/Alert';
import { CommentModel } from '../../models/comment/commentModel';
import CommentsService from '../../services/commentsService';
import { dateToLocal } from '../../common/functions/helperFunctions';
import Form from 'react-bootstrap/Form';
import { CommentEditModel } from '../../models/comment/commentEditModel';
import Button from 'react-bootstrap/Button';
import { CommentStatus } from '../../common/enums';
import { InputValidationModel } from '../../common/models/inputValidationModel';

interface IProps {
    history: any;
    match: any;
    location: any;
}

interface IState {
    isLoading: boolean,
    errorMessage: string,
    successMessage: string;
    commentId: string;
    comment: CommentModel | null,
    commentEditModel: CommentEditModel;
    touched: boolean;
    validations: Array<InputValidationModel>;
}

class EditCommentComponent extends React.Component<IProps, IState> {
    commentsService = new CommentsService();

    constructor(props: IProps) {
        super(props);

        let commentId = this.props.match.params.commentId;

        this.state = {
            isLoading: false,
            errorMessage: '',
            successMessage: '',
            commentId,
            comment: null,
            commentEditModel: new CommentEditModel(),
            validations: new Array<InputValidationModel>(),
            touched: false,
        }
    }

    async componentDidMount() {
        await this.getComment()
    }

    getComment = async () => {
        const { commentId, commentEditModel } = this.state;

        if(!commentId) { return; }

        try {
            this.setState({
                isLoading: true,
                errorMessage: '',
            });

            let comment: CommentModel = await this.commentsService.getComment(commentId);

            if(comment) {
                console.log(comment);
                commentEditModel.id = comment.id;
                commentEditModel.content = comment.content;
                commentEditModel.statusId = comment.statusId;
                commentEditModel.deletionNotes = comment.deletionNotes;
            }

            this.setState({
                isLoading: false,
                comment,
                commentEditModel
            });
        } catch (error) {
            this.setState({
                isLoading: false,
                errorMessage: error.message
            });
        }
    }

    handleChange = (key: any, value: any) => {
        const commentEditModel: CommentEditModel = { ...this.state.commentEditModel };
        let touched = this.state.touched;

        commentEditModel[key] = value;

        if(key === 'statusId') {
            touched = false
        }

        this.setState({
            commentEditModel,
            touched
        });
    }

    validate = () => {
        const { commentEditModel } = this.state;

        this.setState({
            errorMessage: '',
            successMessage: '',
        });

        let validations = new Array<InputValidationModel>();

        if(!commentEditModel.content) {
            validations.push({ key: 'content', message: 'Въведете съдържание.'});
        }

        if(commentEditModel.statusId === CommentStatus.DeletedByUser || commentEditModel.statusId === CommentStatus.StoppedByAdmin) {
            if(!commentEditModel.deletionNotes) {
                validations.push({ key: 'deletionNotes', message: 'Въведете причина за деактивиране.'})
            }
        }
        
        this.setState({
            validations,
            touched: true
        });

        return validations.length === 0;
    }

    handleSaveClick = async () => {
        if(!this.validate()) {
            return;
        }

        try {
            this.setState({
                isLoading: true,
                errorMessage: '',
                successMessage: '',
                touched: false,
            });

            const { commentEditModel } = this.state;

            await this.commentsService.editComment(commentEditModel);
            await this.getComment();

            this.setState({
                isLoading: false,
                successMessage: 'Данните бяха записани успешно.'
            })
        }
        catch (error){
            this.setState({
                isLoading: false,
                errorMessage: error.message,
            });
        }
    }

    render() {
        const { isLoading, errorMessage, comment, commentEditModel, validations, touched, successMessage } = this.state;
        const contentValidationErrors = validations.find(v => v.key === 'content');
        const deletionNotesValidationErrors = validations.find(v => v.key === 'deletionNotes');

        if(successMessage) {
            setTimeout(() => {
                this.setState({ successMessage: ''});
            }, 5000)
        }

        return (
            <div style={{ marginBottom: 80}}>
                {isLoading ? <Spinner /> : null}

                <Breadcrumb style={{ fontSize: '85%'}}>
                    <Breadcrumb.Item href="/">Начало</Breadcrumb.Item>
                    <Breadcrumb.Item href="/comments">Коментари</Breadcrumb.Item>
                    <Breadcrumb.Item active>Редакция</Breadcrumb.Item>
                </Breadcrumb>
                
                <h5>Редактиране на коментар</h5>

                {errorMessage ? 
                <Alert variant={'danger'}>
                    {errorMessage}
                </Alert>
                : null}

                {successMessage ? 
                    <Alert variant={'success'}>
                        {successMessage}
                    </Alert>
                : null}

                {comment && !isLoading
                 ? <div>
                     <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 10, fontSize: '85%'}}>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <a href={`/add-edit-story/${comment.storyId}`}>{comment.storyTitle}</a>
                        </div>
                        <span>Добавен: {dateToLocal(comment.addedOn)}</span>
                        <span>Потребител: {comment.user && comment.user.userName ? comment.user.userName : 'Анонимен'}</span>
                        <span>IP Адрес: {comment.ipAddress ? comment.ipAddress : 'Неизвестен'}</span>
                    </div>
                    <Form>
                         <Form.Group controlId="statusGroup">
                                <Form.Label style={{ fontSize: '85%'}}>Статус</Form.Label>
                                <Form.Control size="sm" as="select"
                                    value={commentEditModel.statusId}
                                    onChange={(e) => this.handleChange('statusId', +e.target['value'])}
                                 >
                                    <option value={CommentStatus.Active}>Активен</option>
                                    <option value={CommentStatus.StoppedByAdmin}>Спрян / неактивен</option>
                                    <option value={CommentStatus.DeletedByUser}>Спрян / изтрит потребителски профил</option>
                                </Form.Control>
                        </Form.Group>
                        {commentEditModel.statusId === CommentStatus.StoppedByAdmin || commentEditModel.statusId === CommentStatus.DeletedByUser
                         ?   <Form.Group controlId="deletionNotesGroup">
                                <Form.Label style={{ fontSize: '85%'}}>Причина за деактивиране</Form.Label>
                                <Form.Control 
                                    as="textarea" 
                                    size="sm"
                                    isInvalid={deletionNotesValidationErrors !== undefined}
                                    isValid={touched && !deletionNotesValidationErrors}
                                    rows={5}
                                    value={commentEditModel.deletionNotes}
                                    onChange={(e) => this.handleChange('deletionNotes', e.target['value'])} />
                            <Form.Control.Feedback type="invalid">
                                {touched && deletionNotesValidationErrors !== undefined ? deletionNotesValidationErrors.message : null }
                            </Form.Control.Feedback>
                            </Form.Group>
                        : null}
                        <Form.Group controlId="contentGroup">
                            <Form.Label style={{ fontSize: '85%'}}>Съдържание</Form.Label>
                            <Form.Control 
                                as="textarea" 
                                size="sm"
                                rows={15}
                                isInvalid={contentValidationErrors !== undefined}
                                isValid={touched && !contentValidationErrors}
                                value={commentEditModel.content}
                                onChange={(e) => this.handleChange('content', e.target['value'])} />
                            <Form.Control.Feedback type="invalid">
                                {touched && contentValidationErrors !== undefined ? contentValidationErrors.message : null }
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Form>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginTop: 5}}> 
                        <Button variant="primary" onClick={this.handleSaveClick}>Запис</Button>
                    </div>
                </div>
               : null}
            </div>
        )
    }
}

export default EditCommentComponent;