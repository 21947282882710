import axios from 'axios';
import * as HelperFunctions  from '../common/functions/helperFunctions';

class DashboardService {
    // authService = new AuthService();

    // constructor() {
    //     axios.defaults.baseURL = `${Constants.apiUrl}`;
    //     axios.defaults.headers = {
    //         "Content-Type": "application/json; charset=utf-8",
    //         //Authorization: `Bearer ${this.authService.getToken()}`,
    //         Pragma: 'no-cache'
    //     }
    // }

    getData() {
        return axios({
            url: `dashboard`,
            method: 'get', headers: {
              "Content-Type": "application/json; charset=utf-8",
              //Authorization: `Bearer ${this.authService.getToken()}`,
            },
            withCredentials: true
          })
            .then(res => res.data.data)
            .catch(error => 
              Promise.reject(HelperFunctions.getErrorMessage(error))
          )
    }
}

export default DashboardService;