import React, { Component } from 'react';
import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';
import { dateToLocal } from '../../functions/helperFunctions';
import { StoryViewModel } from '../../../models/story/storyViewModel';

interface IProps {
    views: Array<StoryViewModel> | null;
    excludeColumns: Array<string>;
}

interface IState {
}

class StoriesViewsGrid extends Component<IProps, IState> {
    public static defaultProps = {
        excludeColumns: null
    };

    // constructor(props: IProps) {
    //     super(props);

    // }

    renderAsList = () => {
        const { views, excludeColumns } = this.props;

        return(
            <div>
                {views?.map((view, index) => {
                    return(
                        <Card key={view.storyId + index} style={{marginBottom: 10}}>
                            <Card.Body>
                                <a href={`/add-edit-story/${view.storyId}`} style={{ marginBottom: 5 }}>{view.storyTitle}</a>
                                <div style={{ display: 'flex', flexDirection: 'column', fontSize: '85%'}}>
                                    {!excludeColumns || !excludeColumns.find(c => c === 'User')
                                     ?   <span>Потребител: {view.userName}</span>
                                     : null}
                                    {!excludeColumns || !excludeColumns.find(c => c === 'Date')
                                     ?  <span>Дата: {dateToLocal(view.date)}</span>
                                     : null}
                                    {!excludeColumns || !excludeColumns.find(c => c === 'IPAddress')
                                     ?  <span>IP Адрес:  <a rel="noopener noreferrer" target="_blank" href={`https://whatismyipaddress.com/ip/${view.ipAddress}`}>{view.ipAddress}</a></span>
                                     : null}
                                </div>
                               
                            </Card.Body>
                    </Card>
                    )
                })}
            </div>
        )
    }

    renderAsTable = () => {
        const { views, excludeColumns } = this.props;

        return (
            <Table striped bordered hover variant="light" responsive style={{ fontSize: '85%'}}>
                    <thead>
                    <tr>
                        {!excludeColumns || !excludeColumns.find(c => c === 'Story')
                         ? <th>История</th>
                         : null }
                        {!excludeColumns || !excludeColumns.find(c => c === 'User')
                            ? <th>Потребител</th>
                            : null}
                        {!excludeColumns || !excludeColumns.find(c => c === 'Date')
                            ? <th>Дата</th>
                            : null}
                        {!excludeColumns || !excludeColumns.find(c => c === 'IPAddress')
                            ? <th>IP Адрес</th>
                            : null}
                    </tr>
                    </thead>
                    <tbody>
                        {views?.map((view, index) => {
                            return (
                            <tr key={view.storyId + index}>
                                {!excludeColumns || !excludeColumns.find(c => c === 'Story')
                                 ? <td><a href={`/add-edit-story/${view.storyId}`}>{view.storyTitle}</a></td>
                                 : null}
                                {!excludeColumns || !excludeColumns.find(c => c === 'User')
                                    ? <td>{view.userName}</td>
                                    : null}
                                {!excludeColumns || !excludeColumns.find(c => c === 'Date')
                                    ? <td>{dateToLocal(view.date)}</td>
                                    : null}
                                {!excludeColumns || !excludeColumns.find(c => c === 'IPAddress')
                                    ? <td>
                                        <a rel="noopener noreferrer" target="_blank" href={`https://whatismyipaddress.com/ip/${view.ipAddress}`}>{view.ipAddress}</a>
                                    </td>
                                    : null}
                            </tr>
                            )
                        })}
                    </tbody>
            </Table> 
        )
    }

    render() {
        const { views } = this.props;

        return (
            <>
                {views && views.length > 0 && window.innerWidth > 960
                ? this.renderAsTable()
               : null}

                {views && views.length > 0 && window.innerWidth <= 960
                ? this.renderAsList()
                : null}
            </>
        )
    }
}

export default StoriesViewsGrid;