import React, { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import PollCommentsGrid from '../../../common/components/pollComments/pollCommentsGrid';
import Spinner from '../../../common/components/spinner/spinner';
import PollCommentModel from '../../../models/pollComment/pollCommentModel';
import PollCommentsService from '../../../services/pollCommentsService';

const PollCommentsComponent = ({poll}) => {
   const pollsCommentsService = new PollCommentsService();
   const [isLoading, setIsLoading] = useState(false);
   const [comments, setComments] = useState<Array<PollCommentModel>>(new Array<PollCommentModel>());
   const [errorMessage, setErrorMessage] = useState('');

   const getComments = async () => {
    try {
     setIsLoading(true);
     let pollComments = await pollsCommentsService.getAllByPoll(poll.id);
     setComments(pollComments);
     setIsLoading(false)
    } catch(error) {
        setErrorMessage(error.message);
        setIsLoading(false);
    }
}
// eslint-disable-line react-hooks/exhaustive-deps
   useEffect(() => {
    if(poll && poll.id) {
        getComments();
    }
    // eslint-disable-next-line
   },[poll]);

   const deleteComment = (commentId: string) => {

   }

   return(
       <div>
            {isLoading ? <Spinner /> : null}
            {errorMessage
                ?   <Alert className="mt-3" variant={'danger'} style={{ fontSize: '85%'}}>
                        {errorMessage}
                    </Alert>
            : null}
            {poll 
             ? <div className='mt-2'>
                 {comments
                  ? <PollCommentsGrid onDeleteComment={deleteComment} comments={comments} excludeColumns={['PollQuestion']} />
                  : null
                 }
               </div>
             : null}
       </div>
    
   )
}

export default PollCommentsComponent;