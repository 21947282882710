import React from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import Spinner from '../../common/components/spinner/spinner';
import AuthService from '../../services/authService';
import StoriesService from '../../services/storiesService';
import { StoryGridModel } from '../../models/story/storyGridModel';
import { StoryListModel } from '../../models/story/storyListModel';
import Card from 'react-bootstrap/Card';
import { StoryStatus } from '../../common/enums';
import Paginator from '../../common/components/paginator/paginator';
import { faSearch, faPlusSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { CategorySimpleModel } from '../../models/category/categorySimpleModel';
import CategoriesService from '../../services/categoriesService';
import StoryListFilterModel from '../../models/story/storyListFilterModel';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import StoriesGrid from '../../common/components/story/storiesGrid';

interface IProps {
    history: any;
    match: any;
}

interface IState {
    isLoading: boolean;
    from: number;
    stories: Array<StoryGridModel> | null;
    totalRecords: number;
    errorMessage: string;
    currentPage: number;
    pageSize: number;
    filterIsVisible: boolean;
    isFiltering: boolean;
    categories: Array<CategorySimpleModel>;
    filter: StoryListFilterModel;
}

class StoriesListComponent extends React.Component<IProps, IState> {
    authService = new AuthService();
    storiesService = new StoriesService();
    categoriesService = new CategoriesService();

    constructor(props: IProps) {
        super(props);

        let currentPage = +this.props.match.params.page;

        if(!currentPage) {
            currentPage = 1
        }

        let pageSize = 15;
        let from = (currentPage - 1) * pageSize;

        let filter = new StoryListFilterModel();
        filter.categoryId = -1;

        this.state = {
            isLoading: false,
            errorMessage: '',
            stories: null,
            from,
            totalRecords: 0,
            currentPage,
            pageSize,
            filterIsVisible: false,
            isFiltering: false,
            categories: new Array<CategorySimpleModel>(),
            filter,
        }
    }

    componentDidMount() {
        this.getStories();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let nextPage = this.props.match.params.page;
        let prevPage = prevProps.match.params.page;

        if(prevPage !== nextPage) {
            if(!nextPage) {
                nextPage = 1;
            }

            let from = (nextPage - 1) * this.state.pageSize;

            this.setState({
                from,
                currentPage: +nextPage
            }, () => this.getStories());
        }
    }

    getCategories = async () => {
        const { categories } = this.state;

        if(categories.length === 0) {
            this.setState({
                isLoading: true,
            });
    
            try {
                let categoriesList: Array<CategorySimpleModel> = await this.categoriesService.getAllSimple();
    
                if(categoriesList && categoriesList.length > 0) {
                    this.setState({
                        isLoading: false,
                        categories: categoriesList
                    });
                } else {
                    this.setState({
                        isLoading: false
                    });
                }
            } catch (error) {
                this.setState({
                    isLoading: false,
                    errorMessage: error.message
                });
            }
        }
    }

    getStories = async () => {
        this.setState({
            isLoading: true,
            stories: new Array<StoryGridModel>()
        });

        const { from, filter } = this.state;

        try {
            let storiesListModel: StoryListModel = await this.storiesService.getAll(from, filter);

            let totalRecords = 0;
            let stories = new Array<StoryGridModel>();

            if(storiesListModel) {
                totalRecords = storiesListModel.storiesCount;
                stories = storiesListModel.stories;
            }

            this.setState({
                totalRecords,
                stories,
                isLoading: false
            });

        } catch (error) {
            this.setState({
                isLoading: false,
                errorMessage: error.message
            });
        }
    }

    getStoryStatus = (storyStatusId: number) => {
        switch(storyStatusId) {
            case StoryStatus.New : 
                return (<span style={{ color: '#FFC300'}}>Нова / чака одобрение</span>);
            case StoryStatus.Active :
                return (<span style={{ color: '#28B463'}}>Активна</span>);
            case StoryStatus.StoppedByAdmin :
                return (<span style={{ color: '#E74C3C'}}>Спряна / неактивна</span>);
            case StoryStatus.DeletedByUser :
                return (<span style={{ color: '#6E2C00'}}>Спряна / изтрит потребителски профил</span>);
        }
    }

    renderFilter = () => {
        const { categories, filter } = this.state;
        let pinnedValue = 0;

        if(filter.pinned != null) {
            pinnedValue = filter.pinned ? 1 : 2;
        }

        return (
            <Card style={{marginBottom: 10, marginTop: 10}}>
                <Card.Body>
                    <h6>Филтър</h6>
                    <Form>
                        <Form.Group as={Row} controlId="titleSearch">
                            <Form.Label column sm="2" style={{ fontSize: '85%'}}>Заглавие</Form.Label>
                            <Col sm="10">
                                <Form.Control 
                                    type="text" 
                                    size="sm" 
                                    placeholder="Търсене в заглавие ..." 
                                    onChange={(e) => this.handleChange('title', e.target['value'])}
                                    value={filter.title}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="contentSearch">
                            <Form.Label column sm="2" style={{ fontSize: '85%'}}>Съдържание</Form.Label>
                            <Col sm="10">
                                <Form.Control 
                                    type="text" 
                                    size="sm" 
                                    placeholder="Търсене в съдържание ..." 
                                    onChange={(e) => this.handleChange('content', e.target['value'])}
                                    value={filter.content}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="categorySearch">
                            <Form.Label column sm="2" style={{ fontSize: '85%'}}>Категория</Form.Label>
                            <Col sm="10">
                            <Form.Control 
                                size="sm" 
                                as="select"
                                value={filter.categoryId}
                                onChange={(e) => this.handleChange('categoryId', +e.target['value'])}>
                                <option value={-1}>--- изберете категория ---</option>
                                <option value={0}>Без категория</option>
                                {categories.map((category, index) => {
                                    return (
                                        <option value={category.id} key={index}>{category.name}</option>
                                    );
                                })}
                            </Form.Control>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="statusSearch">
                            <Form.Label column sm="2" style={{ fontSize: '85%'}}>Статус</Form.Label>
                            <Col sm="10">
                            <Form.Control size="sm" as="select"
                                value={filter.statusId}
                                onChange={(e) => this.handleChange('statusId', +e.target['value'])}>
                                <option value={0}>--- избери статус ---</option>
                                <option value={StoryStatus.New}>Нова / чака одобрение</option>
                                <option value={StoryStatus.Active}>Активна</option>
                                <option value={StoryStatus.StoppedByAdmin}>Спряна / неактивна</option>
                                <option value={StoryStatus.DeletedByUser}>Спряна / изтрит потребителски профил</option>
                            </Form.Control>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="pinned">
                            <Form.Label column sm="2" style={{ fontSize: '85%'}}>Закачена</Form.Label>
                            <Col sm="10">
                            <Form.Control size="sm" as="select"
                                value={pinnedValue}
                                onChange={(e) => this.handleChange('pinned', +e.target['value'])}>
                                <option value={0}>--- избери ---</option>
                                <option value={1}>Да</option>
                                <option value={2}>Не</option>
                            </Form.Control>
                            </Col>
                        </Form.Group>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
                            <div style={{ display: 'flex', flexDirection: 'row'}}>
                                <Button size="sm" variant="primary" onClick={this.handleOnFilterPressed}>Търси</Button>
                                <Button size="sm" variant="danger" onClick={this.handleOnClearFilterPressed} style={{ marginLeft: 10}}>Изчисти</Button>
                            </div>
                        </div>
                    </Form>
                </Card.Body>
            </Card>
        );
    }

    renderFilterValues = () => {
        const { filter, categories } = this.state;

        return (
            <div style={{ fontSize: '85%', marginBottom: 15}}>
                {filter.title
                 ? <div>
                     Заглавие: <span style={{ fontWeight: 'bold'}}>{filter.title}</span> 
                   </div>
                : null}
                 {filter.content
                 ? <div>
                     Съдържание: <span style={{ fontWeight: 'bold'}}>{filter.content}</span> 
                   </div>
                : null}
                 {filter.statusId && filter.statusId !== 0
                 ? <div>
                     Статус: <span style={{ fontWeight: 'bold'}}>{this.getStoryStatus(+filter.statusId)}</span>
                   </div>
                : null}
                {filter.categoryId !== -1
                  ? filter.categoryId === 0
                     ? <div>
                         Категория: <span style={{ fontWeight: 'bold'}}>Без категория</span>  
                      </div>
                     : <div>
                         Категория: <span style={{ fontWeight: 'bold'}}>{categories?.find(c => c.id === +filter.categoryId)?.name}</span>
                      </div>
                  : null}
                {filter.pinned !== null
                    ? <div>Закачена: <span style={{ fontWeight: 'bold'}}>{filter.pinned ? 'Да' : 'Не'}</span></div>
                    : null}
                 <Button 
                    variant="danger" 
                    onClick={this.handleOnClearFilterPressed}
                    size="sm"
                    style={{ marginTop: 5}}>
                        Изчисти
                </Button>
            </div>
        )
    }

    toggleFilter = () => {
        let filterIsVisible = this.state.filterIsVisible;
        this.setState({
            filterIsVisible: !filterIsVisible
        }, () => !filterIsVisible ? this.getCategories() : null);
    }

    onChangePage = (page) => {
        this.props.history.push(`/stories/${page}`);
    }

    handleChange = (key: any, value: any) => {
        const filter: StoryListFilterModel = { ...this.state.filter };

        if(key === 'pinned') {
            if(value === 0) {
                filter.pinned = null
            } else if(value === 1) {
                filter.pinned = true
            } else if(value === 2) {
                filter.pinned = false
            }
        } else {
            filter[key] = value;
        }

        this.setState({
            filter
        });
    }

    handleOnFilterPressed = () => {
        this.props.history.replace('/stories');

        const { filter } = this.state;

        let isFiltering = false;

        if(filter) {
            if(filter.title) { isFiltering = true }
            if(filter.content) { isFiltering = true }
            if(filter.categoryId && filter.categoryId !== -1 ) { isFiltering = true }
            if(filter.statusId) { isFiltering = true }
            if(filter.pinned) { isFiltering = true }
        }

        this.setState({
            from: 0,
            currentPage: 1,
            stories: null,
            totalRecords: 0,
            isFiltering,
            filterIsVisible: false,
        }, () => this.getStories());
    }

    handleOnClearFilterPressed = () => {
        this.props.history.replace('/stories');
        let filter = new StoryListFilterModel();
        filter.categoryId = -1;

        this.setState({
            from: 0,
            currentPage: 1,
            stories: null,
            totalRecords: 0,
            filter,
            isFiltering: false,
        }, () => this.getStories());
    }

    deleteStory = async (storyId: string) => {
        if(storyId) {
            try {
                this.setState({ isLoading: true, errorMessage: '' });
                await this.storiesService.delete(storyId);
                this.setState({
                    isLoading: false,
                }, () => this.getStories())
            } catch(error) {
                this.setState({
                    isLoading: false,
                    errorMessage: error.message
                });
            }
        }
    }

    render() {
        const { isLoading, errorMessage, stories, totalRecords, pageSize, currentPage,
            filterIsVisible, isFiltering } = this.state;

        return (
            <>
            <div style={{ marginBottom: 50 }}>
                {isLoading ? <Spinner /> : null}
                <Breadcrumb style={{ fontSize: '85%'}}>
                    <Breadcrumb.Item href="/">Начало</Breadcrumb.Item>
                    <Breadcrumb.Item active>
                        Истории
                    </Breadcrumb.Item>
                </Breadcrumb>
                <div style={{ 
                    display: 'flex', 
                    flexDirection: 'row', 
                    justifyContent: 'space-between', 
                    textAlign: 'center'}}>
                    <h5>Истории ({totalRecords})</h5>
                    <div style={{ display: 'flex', flexDirection: 'row'}}>
                        <FontAwesomeIcon 
                            icon={faSearch} 
                            style={{ color: "#FF7851", cursor: 'pointer', marginRight: 15 }} 
                            size='lg' 
                            onClick={this.toggleFilter}
                        />
                        <FontAwesomeIcon 
                            icon={faPlusSquare} 
                            style={{ color: "#32A224", cursor: 'pointer' }} 
                            size='lg' 
                            onClick={() => this.props.history.push('/add-edit-story')}
                        />
                    </div> 
                </div>
                {errorMessage ? 
                  <Alert variant={'danger'}>
                  {errorMessage}
                </Alert>
                : null}

                {!filterIsVisible && isFiltering
                 ? this.renderFilterValues()
                 : null}
               
                {filterIsVisible
                 ? this.renderFilter()
                 : null}

               <StoriesGrid 
                    stories={stories}
                    onDeleteStory={this.deleteStory}
               />

                {totalRecords > 15 && !isLoading
                ? <Paginator 
                    totalRecords={totalRecords}
                    pageSize={pageSize}
                    currentPage={currentPage}
                    style={{marginTop: 10, justifyContent: 'center'}}
                    onClick={this.onChangePage} />
              : null}
            </div>
            
        </>
        )
    }
}

export default StoriesListComponent;