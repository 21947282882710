import React from 'react';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import { InputValidationModel } from '../../common/models/inputValidationModel';
import Spinner from '../../common/components/spinner/spinner';
import { LoginUserModel } from '../../models/user/loginUserModel';
import AuthService from '../../services/authService';

interface IProps {
    history: any;
    match: any;
}

interface IState {
    validations: Array<InputValidationModel>;
    touched: boolean;
    isLoading: boolean;
    errorMessage: string;
    loginUserModel: LoginUserModel;
}

class LoginComponent extends React.Component<IProps, IState> {
    authService = new AuthService();

    constructor(props: IProps) {
        super(props);

        this.state = {
            validations: new Array<InputValidationModel>(),
            touched: false,
            isLoading: false,
            errorMessage: '',
            loginUserModel: new LoginUserModel(),
        }
    }

    componentDidMount() {

    }

    handleChange = (key: any, value: any) => {
        const loginUserModel: LoginUserModel = { ...this.state.loginUserModel };
        loginUserModel[key] = value;
        this.setState({
            loginUserModel
        }, () => this.state.touched ? this.validate() : null);
    }

    validate = (): boolean => {
        const { loginUserModel } = this.state;
        let validations = new Array<InputValidationModel>();

        if(!loginUserModel.userName)
            validations.push({ key: 'userName', message: 'Въведете потребителско име.'});
        
        if(!loginUserModel.password)
            validations.push({ key: 'password', message: 'Въведете парола.'});
        
        this.setState({
            validations,
            touched: true
        });

        return validations.length === 0;
     }

    handleLoginBtn = (event) => {
        event.preventDefault();

        this.setState({
            errorMessage: '',
        });

        if(!this.validate()) {
            return;
        }

        const { loginUserModel } = this.state;
        let userName = loginUserModel.userName.trim();
        let password = loginUserModel.password.trim();

        this.setState({
            isLoading: true
        });

        this.authService.loginWithCookie(userName, password).then(
            (data) => {
                this.setState({
                    isLoading: false
                });
                
                this.props.history.replace('/dashboard');
            }
        ).catch(error => {
                this.setState({
                    isLoading: false,
                    errorMessage: error.message,
                    loginUserModel: new LoginUserModel(),
                    touched: false,
                });
            });
     }

     handleKeyPress = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            this.handleLoginBtn(e);
        }
     }

    render() {
        const { validations, touched, isLoading, errorMessage, loginUserModel } = this.state;
        const userNameValidationErrors = validations.find(v => v.key === 'userName');
        const passwordValidationsErrors = validations.find(v => v.key === 'password');

        return (
            <>
            <Container>
                {isLoading ? <Spinner /> : null}
                <h4>Вход</h4>
                {errorMessage ? 
                  <Alert variant={'danger'}>
                  {errorMessage}
                </Alert>
                : null}
                <Form>
                    <Form.Group controlId="formUserName">
                        <Form.Label>Потребителско име</Form.Label>
                        <Form.Control 
                            type="text" 
                            placeholder="Въведете потребителско име" 
                            // isValid={touched && !userNameValidationErrors} 
                            isInvalid={userNameValidationErrors !== undefined}
                            onChange={(e) => this.handleChange('userName', e.target['value'])}
                            value={loginUserModel.userName ? loginUserModel.userName : ''}
                            onKeyPress={this.handleKeyPress} />
                        <Form.Control.Feedback type="invalid">
                           {touched && userNameValidationErrors !== undefined ? userNameValidationErrors.message : null }
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="formPassword">
                        <Form.Label>Парола</Form.Label>
                        <Form.Control 
                            type="password" 
                            isInvalid={passwordValidationsErrors !== undefined}
                            // isValid={touched && !passwordValidationsErrors}
                            value={loginUserModel.password ? loginUserModel.password : ''}
                            placeholder="Въведете парола"
                            onKeyPress={this.handleKeyPress}
                            onChange={(e) => this.handleChange('password', e.target['value'])} />
                        <Form.Control.Feedback type="invalid">
                            {touched && passwordValidationsErrors !== undefined ? passwordValidationsErrors.message : null }
                        </Form.Control.Feedback>
                        <Button type="button" style={{ marginTop: 15 }} onClick={this.handleLoginBtn}>Влез</Button>
                    </Form.Group>
                </Form>
            </Container>
            
        </>
        )
    }
}

export default LoginComponent;