import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Card, Table } from 'react-bootstrap';
import { dateToLocal } from '../../functions/helperFunctions';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { PollOptionVoteModel } from '../../../models/polls/pollOptionVoteModel';

const PollsVotesGrid = ({ votes, excludeColumns }) => {
    const [voteToDelete, setVoteToDelete] = useState('');
    const [deleteConformationVisible, setDeleteConfirmationVisible] = useState(false);

    const handleDeleteBtnPressed = (voteId: string) => {
        setDeleteConfirmationVisible(true);
        setVoteToDelete(voteId);
    }

    const handleConfirmDeletionPressed = () => {
        if(voteToDelete) {
            onDeleteVote();
        }

        setVoteToDelete('');
        setDeleteConfirmationVisible(false);
    }

    const trimPollQuestion = (question: string) => {
        if(question && question.length > 150) {
            return `${question.substring(0, 147)}...`;
        }

        return question;
    }

    const onDeleteVote = () => {

    }

    const renderAsList = () => {
        return(
            <div>
                {votes?.map((vote: PollOptionVoteModel, index) => {
                    return(
                        <Card key={vote.id} className="mb-1">
                            <Card.Body>
                                {!excludeColumns || !excludeColumns.find(c => c === 'PollQuestion')
                                     ?   <a href={`/edit-poll/${vote.pollId}`} className="mt-1">{trimPollQuestion(vote.pollQuestion)}</a>
                                     : null}
                                <div style={{ display: 'flex', flexDirection: 'column', fontSize: '85%'}}>
                                    {!excludeColumns || !excludeColumns.find(c => c === 'OptionContent')
                                     ?   <span>Отговор: {vote.optionContent}</span>
                                     : null}
                                    {!excludeColumns || !excludeColumns.find(c => c === 'CreatedOn')
                                     ?    <span>Дата: {dateToLocal(vote.createdOn)}</span>
                                     : null}
                                    {!excludeColumns || !excludeColumns.find(c => c === 'User')
                                     ?   <span>Потребител: {vote.user ? vote.user : 'Анонимен'}</span>
                                     : null}
                                    {!excludeColumns || !excludeColumns.find(c => c === 'IpAddress')
                                     ?    <span>Ip Address: {vote.ipAddress}</span>
                                     : null}
                                    {!excludeColumns || !excludeColumns.find(c => c === 'Delete')
                                     ? <FontAwesomeIcon 
                                            icon={faTrashAlt} 
                                            size='lg'
                                            style={{ color: "#dc3545", cursor: 'pointer' }} 
                                            onClick={() => handleDeleteBtnPressed(vote.id)}
                                        />
                                    : null}
                                </div>
                            </Card.Body>
                    </Card>
                    );
                })}
            </div>);
    }

    const renderAsTable = () => {
        return (
            <Table striped bordered hover variant="light" responsive style={{ fontSize: '85%'}}>
                    <thead>
                    <tr>
                        {!excludeColumns || !excludeColumns.find(c => c === 'PollQuestion')
                            ? <th>Въпрос</th>
                            : null}
                        {!excludeColumns || !excludeColumns.find(c => c === 'OptionContent')
                            ? <th>Отговор</th>
                            : null}
                        {!excludeColumns || !excludeColumns.find(c => c === 'User')
                            ? <th>Потребител</th>
                            : null}
                        {!excludeColumns || !excludeColumns.find(c => c === 'CreatedOn')
                            ? <th>Дата</th>
                            : null}
                        {!excludeColumns || !excludeColumns.find(c => c === 'IpAddress')
                            ? <th>IP Адрес</th>
                            : null}
                        {!excludeColumns || !excludeColumns.find(c => c === 'Delete')
                            ? <th></th>
                            : null}
                    </tr>
                    </thead>
                    <tbody>
                        {votes?.map((vote: PollOptionVoteModel, index) => {
                            return (
                            <tr key={vote.id}>
                                {!excludeColumns || !excludeColumns.find(c => c === 'PollQuestion')
                                      ?  <td> <a href={`/edit-poll/${vote.pollId}`} className="mt-1">{trimPollQuestion(vote.pollQuestion)}</a></td>
                                      : null}
                                {!excludeColumns || !excludeColumns.find(c => c === 'OptionContent')
                                      ?   <td>{vote.optionContent}</td>
                                      : null}
                                {!excludeColumns || !excludeColumns.find(c => c === 'User')
                                      ? <td>{vote.user ? vote.user : 'Анонимен'}</td>
                                      : null}
                                {!excludeColumns || !excludeColumns.find(c => c === 'CreatedOn')
                                      ?   <td>{dateToLocal(vote.createdOn)}</td>
                                      : null}
                                {!excludeColumns || !excludeColumns.find(c => c === 'IpAddress')
                                    ?  <td>{vote.ipAddress}</td>
                                    : null}
                                {!excludeColumns || !excludeColumns.find(c => c === 'Delete')
                                    ?   <td style={{ textAlign: 'center'}}>
                                             <FontAwesomeIcon 
                                                icon={faTrashAlt} 
                                                size='lg'
                                                style={{ color: "#dc3545", cursor: 'pointer' }} 
                                                onClick={() => handleDeleteBtnPressed(vote.id)}
                                            />
                                        </td>
                                    : null}
                            </tr>
                            )
                        })}
                    </tbody>
            </Table> 
        )
    }

    return(
       <div className='mt-2'>
            {votes && votes.length > 0 && window.innerWidth > 960
                ? renderAsTable()
               : null}

            {votes && votes.length > 0 && window.innerWidth <= 960
                ? renderAsList()
                : null}

            <Modal show={deleteConformationVisible} onHide={() => {
                setDeleteConfirmationVisible(false);
                setVoteToDelete('');
            }}>
                    <Modal.Header closeButton>
                    <Modal.Title>Изтриване на глас</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Сигурни ли сте, че искате да изтриете избраното гласуване?</Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={() => {
                        setDeleteConfirmationVisible(false);
                        setVoteToDelete('');
                    }}>
                        Не
                    </Button>
                    <Button variant="danger" onClick={handleConfirmDeletionPressed}>
                       Да
                    </Button>
                    </Modal.Footer>
            </Modal>
       </div>
    );
}

export default PollsVotesGrid;