import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
//import 'bootswatch/dist/spacelab/bootstrap.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import * as Constants from './common/constants';
import AuthService from './services/authService';
import * as HelperFunctions  from './common/functions/helperFunctions';

const authService = new AuthService();
axios.defaults.baseURL = `${Constants.apiUrl}`;
axios.defaults.headers = {
    "Content-Type": "application/json; charset=utf-8",
    Pragma: 'no-cache'
}

axios.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config;
    console.log(error);

    // Prevent infinite loops
    if (error && error.response && error.response.status === 401 && originalRequest.url === Constants.tokenUrl) {
      window.location.href = '/login';
      return Promise.reject(HelperFunctions.getErrorMessage(error))
    }

    if(error && error.response && (error.response.status === 401 || error.response.statusText === "Unauthorized")) {
       if(authService.isAuthenticated()) {
         let userName = authService.getLoginName();
         return authService.getRefreshToken(userName).then(
              () => {
                return axios(originalRequest);
              }
            ).catch(error => {
              authService.logout();
              window.location.href = '/login';
              return Promise.reject(HelperFunctions.getErrorMessage(error))
            })
       }
    } else {
      if(error && error.response && error.response.status === 403) {
          await authService.serverLogOut();
          authService.logout();
      } else {
        return Promise.reject(HelperFunctions.getErrorMessage(error))
      }
    }
  }
)

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
