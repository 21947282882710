import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Card, Table } from 'react-bootstrap';
import { PollStatus } from '../../enums';
import { dateToLocal } from '../../functions/helperFunctions';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const PollsGrid = ({ polls, excludeColumns, onDeletePoll }) => {
    const [pollToDelete, setPollToDelete] = useState('');
    const [deleteConformationVisible, setDeleteConfirmationVisible] = useState(false);

    const getPollStatus = (pollStatusId: number) => {
        switch(pollStatusId) {
            case PollStatus.New : 
                return (<span style={{ color: '#FFC300'}}>Нов / чака одобрение</span>);
            case PollStatus.Active :
                return (<span style={{ color: '#28B463'}}>Активен</span>);
            case PollStatus.Stopped :
                return (<span style={{ color: '#E74C3C'}}>Спрян / неактивен</span>);
        }
    }

    const handleDeleteBtnPressed = (pollId: string) => {
        setDeleteConfirmationVisible(true);
        setPollToDelete(pollId);
    }

    const handleConfirmDeletionPressed = () => {
        if(pollToDelete) {
            onDeletePoll(pollToDelete);
        }

        setPollToDelete('');
        setDeleteConfirmationVisible(false);
    }

    const renderAsList = () => {
        return(
            <div>
                {polls?.map((poll, index) => {
                    return(
                        <Card key={poll.id} className="mb-1">
                            <Card.Body>
                                <a href={`/edit-poll/${poll.id}`} className="mt-1">{poll.question}</a>
                                <div style={{ display: 'flex', flexDirection: 'column', fontSize: '85%'}}>
                                    {!excludeColumns || !excludeColumns.find(c => c === 'User')
                                     ?   <span>Потребител: {poll.user ? poll.user : 'Анонимен'}</span>
                                     : null}
                                    {!excludeColumns || !excludeColumns.find(c => c === 'CreatedOn')
                                     ?    <span>Добавен: {dateToLocal(poll.createdOn)}</span>
                                     : null}
                                    {!excludeColumns || !excludeColumns.find(c => c === 'IpAddress')
                                     ?    <span>Ip Address: {poll.ipAddress}</span>
                                     : null}
                                    {!excludeColumns || !excludeColumns.find(c => c === 'ViewsCount')
                                     ?   <span>Прегледи: {poll.viewsCount}</span>
                                     : null}
                                    {!excludeColumns || !excludeColumns.find(c => c === 'VotesCount')
                                     ?    <span>Гласувания: {poll.votesCount}</span>
                                     : null}
                                    {!excludeColumns || !excludeColumns.find(c => c === 'CommentsCount')
                                     ?    <span>Брой коментари: {poll.commentsCount}</span>
                                     : null}
                                    {!excludeColumns || !excludeColumns.find(c => c === 'Status')
                                     ?      <span>Статус: {getPollStatus(poll.statusId)}</span>
                                     : null}
                                    {!excludeColumns || !excludeColumns.find(c => c === 'Delete')
                                     ? <FontAwesomeIcon 
                                            icon={faTrashAlt} 
                                            size='lg'
                                            style={{ color: "#dc3545", cursor: 'pointer' }} 
                                            onClick={() => handleDeleteBtnPressed(poll.id)}
                                        />
                                    : null}
                                </div>
                            </Card.Body>
                    </Card>
                    );
                })}
            </div>);
    }

    const renderAsTable = () => {
        return (
            <Table striped bordered hover variant="light" responsive style={{ fontSize: '85%'}}>
                    <thead>
                    <tr>
                        <th>Въпрос</th>
                        {!excludeColumns || !excludeColumns.find(c => c === 'User')
                            ? <th>Потребител</th>
                            : null}
                        {!excludeColumns || !excludeColumns.find(c => c === 'CreatedOn')
                            ? <th>Добавен</th>
                            : null}
                        {!excludeColumns || !excludeColumns.find(c => c === 'IpAddress')
                            ? <th style={{ textAlign: 'center' }}>IP Адрес</th>
                            : null}
                        {!excludeColumns || !excludeColumns.find(c => c === 'ViewsCount')
                            ? <th style={{ textAlign: 'center' }}>Прегледи</th>
                            : null}
                        {!excludeColumns || !excludeColumns.find(c => c === 'VotesCount')
                            ? <th style={{ textAlign: 'center' }}>Гласувания</th>
                            : null}
                        {!excludeColumns || !excludeColumns.find(c => c === 'CommentsCount')
                            ? <th style={{ textAlign: 'center' }}>Брой коментари</th>
                            : null}
                        {!excludeColumns || !excludeColumns.find(c => c === 'Status')
                            ? <th style={{ textAlign: 'center' }}>Статус</th>
                            : null}
                        {!excludeColumns || !excludeColumns.find(c => c === 'Delete')
                            ? <th></th>
                            : null}
                    </tr>
                    </thead>
                    <tbody>
                        {polls?.map((poll, index) => {
                            return (
                            <tr key={poll.id}>
                                <td><a href={`/edit-poll/${poll.id}`}>{poll.question}</a></td>
                                {!excludeColumns || !excludeColumns.find(c => c === 'User')
                                      ? <td>{poll.user ? poll.user : 'Анонимен'}</td>
                                      : null}
                                {!excludeColumns || !excludeColumns.find(c => c === 'CreatedOn')
                                      ?   <td>{dateToLocal(poll.createdOn)}</td>
                                      : null}
                                {!excludeColumns || !excludeColumns.find(c => c === 'IpAddress')
                                    ?  <td>{poll.ipAddress}</td>
                                    : null}
                                {!excludeColumns || !excludeColumns.find(c => c === 'ViewsCount')
                                    ?   <td style={{ textAlign: 'center'}}>{poll.viewsCount}</td>
                                    : null}
                                {!excludeColumns || !excludeColumns.find(c => c === 'VotesCount')
                                    ?   <td style={{ textAlign: 'center'}}>{poll.votesCount}</td>
                                    : null}
                                {!excludeColumns || !excludeColumns.find(c => c === 'CommentsCount')
                                    ?   <td style={{ textAlign: 'center'}}>{poll.commentsCount}</td>
                                    : null}
                                {!excludeColumns || !excludeColumns.find(c => c === 'Status')
                                    ?   <td>{getPollStatus(poll.statusId)}</td>
                                    : null}
                                {!excludeColumns || !excludeColumns.find(c => c === 'Delete')
                                    ?   <td>
                                             <FontAwesomeIcon 
                                                icon={faTrashAlt} 
                                                size='lg'
                                                style={{ color: "#dc3545", cursor: 'pointer' }} 
                                                onClick={() => handleDeleteBtnPressed(poll.id)}
                                            />
                                        </td>
                                    : null}
                            </tr>
                            )
                        })}
                    </tbody>
            </Table> 
        )
    }

    return(
       <div>
            {polls && polls.length > 0 && window.innerWidth > 960
                ? renderAsTable()
               : null}

                {polls && polls.length > 0 && window.innerWidth <= 960
                ? renderAsList()
                : null}

            <Modal show={deleteConformationVisible} onHide={() => {
                setDeleteConfirmationVisible(false);
                setPollToDelete('');
            }}>
                    <Modal.Header closeButton>
                    <Modal.Title>Изтриване на въпрос</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Сигурни ли сте, че искате да изтриете избраният въпрос?</Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={() => {
                        setDeleteConfirmationVisible(false);
                        setPollToDelete('');
                    }}>
                        Не
                    </Button>
                    <Button variant="danger" onClick={handleConfirmDeletionPressed}>
                       Да
                    </Button>
                    </Modal.Footer>
            </Modal>
       </div>
    );
}

export default PollsGrid;