import React, { CSSProperties } from 'react';
import Alert from 'react-bootstrap/Alert';
import Spinner from '../../common/components/spinner/spinner';
import SystemOptionsService from '../../services/systemOptionsService';
import Card from 'react-bootstrap/esm/Card';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { RestrictedAddressGridModel } from '../../models/options/restrictedAddressGridModel';
import Table from 'react-bootstrap/Table';
import { dateToLocal } from '../../common/functions/helperFunctions';
import { faTimes, faCheck, faPlusSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from 'react-bootstrap/Modal';
import { RestrictedAddressAddEditModel } from '../../models/options/restrictedAddressAddEditModel';
import { InputValidationModel } from '../../common/models/inputValidationModel';

interface IProps {
    style: CSSProperties | undefined;
}

interface IState {
    restrictedAddresses: Array<RestrictedAddressGridModel> | null,
    isLoading: boolean;
    errorMessage: string;
    successMassage: string;
    addEditModalVisible: boolean;
    currentRestrictedAddress: RestrictedAddressAddEditModel;
    validations: Array<InputValidationModel>;
    touched: boolean;
    addEditErrorMessage: string;
}

class RestrictedAddressesComponent extends React.Component<IProps, IState> {
    systemOptionsService = new SystemOptionsService();

    constructor(props: IProps) {
        super(props);

        let currentRestrictedAddress = new RestrictedAddressAddEditModel();
        currentRestrictedAddress.isActive = true;

        this.state = {
            restrictedAddresses: null,
            isLoading: false,
            errorMessage: '',
            successMassage: '',
            addEditModalVisible: false,
            currentRestrictedAddress,
            validations: new Array<InputValidationModel>(),
            touched: false,
            addEditErrorMessage: '',
        }
    }

    async componentDidMount() {
        await this.getAll();
    }

    getAll = async () => {
        try {
            this.setState({
                isLoading: true
            });

            let restrictedAddresses: Array<RestrictedAddressGridModel> = await this.systemOptionsService.getAllRestrictedAddresses();

            if(!restrictedAddresses) {
                restrictedAddresses = new Array<RestrictedAddressGridModel>();
            }

            this.setState({
                isLoading: false,
                restrictedAddresses,
            })

        } catch (error) {
            this.setState({
                isLoading: false,
                errorMessage: error.message,
            });
        }
    }

    onAddressRowPressed = (id: number) => {
        const { restrictedAddresses } = this.state;

        let selectedAddress = restrictedAddresses?.find(a => a.id === id);

        if(selectedAddress) {
            this.setState({
                currentRestrictedAddress: selectedAddress,
                addEditModalVisible: true,
                validations: new Array<InputValidationModel>(),
                touched: false,
            });
        }
    }

    renderAddressesAsList = () => {
        const { restrictedAddresses } = this.state;

        return (
            <div style={{ marginTop: 15}}>
            {restrictedAddresses?.map((address, index) => {
                return(
                    <div key={address.id} style={{ display: 'flex', flexDirection: 'column', fontSize: '85%'}}>
                        <span>IP Адрес: {address.ipAddress}</span>
                        <span>Бележки:</span>
                        <div>
                            {address.notes}
                        </div>
                        <span>Добавен: {dateToLocal(address.addedOn)}</span>
                        <div style={{ display: 'flex', flexDirection: 'row'}}>
                            <span>Пълно ограничение</span>
                            {address.fullRestrict 
                            ? <FontAwesomeIcon 
                                icon={faCheck} 
                                style={{ color: "#32CD32", marginLeft: 5 }} 
                                size='lg' 
                                /> 
                            : <FontAwesomeIcon 
                                icon={faTimes} 
                                style={{ color: "#FF7851", marginLeft: 5 }} 
                                size='lg' 
                            />}
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row'}}>
                            <span>Активен</span>
                            {address.isActive 
                            ? <FontAwesomeIcon 
                                icon={faCheck} 
                                style={{ color: "#32CD32", marginLeft: 5 }} 
                                size='lg' 
                                /> 
                            : <FontAwesomeIcon 
                                icon={faTimes} 
                                style={{ color: "#FF7851", marginLeft: 5 }} 
                                size='lg' 
                            />}
                        </div>
                        <Button 
                            variant="primary" 
                            size='sm' 
                            onClick={() => this.onAddressRowPressed(address.id)}
                            style={{ marginTop: 8, width:'40%'}}>
                            Редактирай
                        </Button>
                        {index < restrictedAddresses.length - 1
                         ?  <hr
                                style={{
                                    color: 'red',
                                    backgroundColor: '#e9ecef',
                                    height: 0.5,
                                    width: '100%',
                                    marginTop: 8,
                                    marginBottom: 8,
                                }}
                            />
                        : null}
                    </div>
                )
            })}
        </div>
        );
    }

    renderAddressesAsTable = () => {
        const { restrictedAddresses } = this.state;

        return (
            <Table striped bordered hover variant="light" responsive style={{ fontSize: '85%'}}>
            <thead>
            <tr>
                <th>IP Адрес</th>
                <th>Бележки</th>
                <th>Добавен</th>
                <th style={{ textAlign: 'center'}}>Пълно ограничение</th>
                <th style={{ textAlign: 'center'}}>Активен</th>
            </tr>
            </thead>
            <tbody>
                {restrictedAddresses?.map((address, index) => {
                    return (
                    <tr key={address.id} style={{ cursor: 'pointer'}} onClick={() => this.onAddressRowPressed(address.id)}>
                        <td>{address.ipAddress}</td>
                        <td>{address.notes}</td>
                        <td>{dateToLocal(address.addedOn)}</td>
                        <td style={{ textAlign: 'center'}}>
                            {address.fullRestrict 
                                ? <FontAwesomeIcon 
                                    icon={faCheck} 
                                    style={{ color: "#32CD32" }} 
                                    size='lg' 
                                    /> 
                                : <FontAwesomeIcon 
                                    icon={faTimes} 
                                    style={{ color: "#FF7851" }} 
                                    size='lg' 
                            />}
                        </td>
                        <td style={{ textAlign: 'center'}}>
                            {address.isActive 
                                ? <FontAwesomeIcon 
                                    icon={faCheck} 
                                    style={{ color: "#32CD32" }} 
                                    size='lg' 
                                    /> 
                                : <FontAwesomeIcon 
                                    icon={faTimes} 
                                    style={{ color: "#FF7851" }} 
                                    size='lg' 
                            />}
                        </td>
                    </tr>
                    )
                })}
            </tbody>
    </Table> 
        )
    }

    toggleAddEditModal = () => {
        this.setState({
            addEditModalVisible: !this.state.addEditModalVisible
        });
    }

    handleChange = (key: any, value: any) => {
        const currentRestrictedAddress: RestrictedAddressAddEditModel = { ...this.state.currentRestrictedAddress };

        currentRestrictedAddress[key] = value;

        this.setState({
            currentRestrictedAddress
        });
    }

    validate = (): boolean => {
        const { currentRestrictedAddress } = this.state;

        this.setState({
            errorMessage: '',
            addEditErrorMessage: '',
        });

        let validations = new Array<InputValidationModel>();

        if(!currentRestrictedAddress.ipAddress)
            validations.push({ key: 'ipAddress', message: 'Въведете IP адрес.'});
        
        this.setState({
            validations,
            touched: true
        });

        return validations.length === 0;
    }

    handleAddAddressBtnPressed = () => {
        let currentRestrictedAddress = new RestrictedAddressAddEditModel();
        currentRestrictedAddress.isActive = true;

        this.setState({
            currentRestrictedAddress,
            validations: new Array<InputValidationModel>(),
            addEditModalVisible: true,
            touched: false,
        });
    }

    handleSaveButtonPressed = async () => {
        if(!this.validate()) { return; }

        this.setState({
            isLoading: true
        });

        try {
            const { currentRestrictedAddress } = this.state;

            if(!currentRestrictedAddress.id) {
                await this.systemOptionsService.addRestrictedAddress(currentRestrictedAddress);
            } else {
                await this.systemOptionsService.editRestrictedAddress(currentRestrictedAddress);
            }

            this.setState({
                isLoading: false,
                successMassage: 'Данните бяха записани успешно.',
                addEditModalVisible: false,
            }, async () => await this.getAll());

        } catch (error) {
            this.setState({
                isLoading: false,
                addEditErrorMessage: error.message
            });
        }
    }

    render() {
        const { isLoading, restrictedAddresses, errorMessage, successMassage, addEditModalVisible, 
                currentRestrictedAddress, validations, touched, addEditErrorMessage } = this.state;
        const ipAddressValidationErrors = validations.find(v => v.key === 'ipAddress');

        if(successMassage) {
            setTimeout(() => {
                this.setState({ 
                    successMassage: '',
                });
            }, 5000)
        }

        return (
            <>
            <Card style={ this.props.style ? this.props.style : {}} hidden={restrictedAddresses === null}>
                {isLoading ? <Spinner /> : null}
                <Card.Body>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                        <h6>Ограничени адреси</h6>
                        <FontAwesomeIcon 
                            icon={faPlusSquare} 
                            style={{ color: "#32A224", cursor: 'pointer' }} 
                            size='lg'
                            onClick={this.handleAddAddressBtnPressed}
                        />
                    </div>
                    {errorMessage
                    ?  <Alert style={{ fontSize: '85%'}} variant={'danger'}>
                            {errorMessage}
                        </Alert>
                    : null}
                    {successMassage
                    ?  <Alert style={{ fontSize: '85%'}} variant={'success'}>
                            {successMassage}
                        </Alert>
                    : null}
                    {restrictedAddresses && restrictedAddresses.length > 0
                     ? window.innerWidth > 960 ? this.renderAddressesAsTable() : this.renderAddressesAsList()
                     : null}
                </Card.Body>
             </Card>
            {addEditModalVisible
                ? <Modal show={addEditModalVisible} centered size='lg' onHide={this.toggleAddEditModal}>
                    <Modal.Header closeButton>
                    <Modal.Title style={{ fontSize: '90%'}}>
                        {currentRestrictedAddress.id
                         ? 'Редактиране на ограничен адрес'
                         : 'Добавяне на ограничен адрес'}
                    </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                            {addEditErrorMessage
                            ?  <Alert style={{ fontSize: '85%'}} variant={'danger'}>
                                    {addEditErrorMessage}
                                </Alert>
                            : null}
                            <Form>
                                <Form.Group controlId="ipAddress">
                                    <Form.Label style={{ fontSize: '85%'}}>IP Адрес:</Form.Label>
                                    <Form.Control 
                                            type="text" 
                                            size="sm" 
                                            isValid={touched && !ipAddressValidationErrors} 
                                            isInvalid={ipAddressValidationErrors !== undefined}
                                            placeholder="Въведете IP Адрес ..." 
                                            onChange={(e) => this.handleChange('ipAddress', e.target['value'])}
                                            value={currentRestrictedAddress.ipAddress}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {touched && ipAddressValidationErrors !== undefined ? ipAddressValidationErrors.message : null }
                                    </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group controlId="notes">
                                <Form.Label style={{ fontSize: '85%'}}>Бележки:</Form.Label>
                                <Form.Control 
                                    as="textarea" 
                                    size="sm"
                                    rows={3}
                                    placeholder="Въведете бележки ..." 
                                    onChange={(e) => this.handleChange('notes', e.target['value'])}
                                    value={currentRestrictedAddress.notes}
                                />
                            </Form.Group>
                            <Form.Group controlId="fullRestrict">
                                <Form.Check 
                                    type={'checkbox'}
                                    checked={currentRestrictedAddress.fullRestrict}
                                    label={'Пълно ограничение'}
                                    style={{ fontSize: '85%', marginBottom: 5}}
                                    onChange={(e) => this.handleChange('fullRestrict', !this.state.currentRestrictedAddress.fullRestrict)}
                                />
                            </Form.Group>
                            <Form.Group controlId="isActive">
                                <Form.Check 
                                    type={'checkbox'}
                                    checked={currentRestrictedAddress.isActive}
                                    label={'Ограничението е активно'}
                                    style={{ fontSize: '85%', marginBottom: 5}}
                                    onChange={(e) => this.handleChange('isActive', !this.state.currentRestrictedAddress.isActive)}
                                />
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" size='sm' onClick={this.toggleAddEditModal}>Затвори</Button>
                        <Button variant="primary" size='sm' onClick={this.handleSaveButtonPressed}>Запиши</Button>
                    </Modal.Footer>
                </Modal>
            : null}
            </>
         )
    }
}

export default RestrictedAddressesComponent;