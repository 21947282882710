import React, { Component } from 'react';
import Card from 'react-bootstrap/Card';
import { dateToLocal } from '../../functions/helperFunctions';
import { CommentStatus } from '../../enums';
import Button from 'react-bootstrap/Button';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from 'react-bootstrap/Modal';
import PollCommentModel from '../../../models/pollComment/pollCommentModel';

interface IProps {
    comments: Array<PollCommentModel> | null;
    excludeColumns: Array<string>;
    onDeleteComment: any;
}

interface IState {
    showFull: Array<string>;
    showDeleteConformation: boolean;
    commentToDelete: string,
}

class PollCommentsGrid extends Component<IProps, IState> {
    public static defaultProps = {
        excludeColumns: null,
        onDeleteComment: null,
    };

    constructor(props: IProps) {
        super(props);

        this.state = {
            showFull: new Array<string>(),
            showDeleteConformation: false,
            commentToDelete: '',
        }
    }

    getCommentStatus = (commentStatusId: number) => {
        switch(commentStatusId) {
            case CommentStatus.Active :
                return (<span style={{ color: '#28B463'}}>Активен</span>);
            case CommentStatus.StoppedByAdmin :
                return (<span style={{ color: '#E74C3C'}}>Спрян / неактивен</span>);
            case CommentStatus.DeletedByUser :
                return (<span style={{ color: '#6E2C00'}}>Спрян / изтрит потребителски профил</span>);
        }
    }

    getCommentContent = (content: string, commentId: string) => {
        const { showFull } = this.state;

        if(content.length > 500 && !showFull.find(s => s === commentId)) {
            return (<>
                     {`${content.slice(0, 500)}.......`}
                     <br />
                     <a href="/" onClick={(e) => { 
                         e.preventDefault();
                         showFull.push(commentId);
                         this.setState({ showFull });
                     }}>
                         виж още
                    </a>
                   </>)
        } else {
            return (
                <>{content}</>
            );
        }
    }

    handleDeleteBtnPressed = (commentId: string) => {
        this.setState({
            commentToDelete: commentId,
            showDeleteConformation: true,
        })
    }

    handleConfirmDeletionPressed = () => {
        const { commentToDelete } = this.state;

        this.props.onDeleteComment(commentToDelete);

        this.setState({
            commentToDelete: '',
            showDeleteConformation: false,
        })
    }

    renderComments = () => {
        const { comments, excludeColumns } = this.props;

        if(!comments || comments.length === 0)
            return null;

        return (
            <div style={{ marginTop: 15}}>
                {comments.map((comment, index) => {
                    return(
                        <Card key={comment.id} style={{marginBottom: 10, fontSize: '85%'}}>
                            <Card.Body>
                                <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: '#F0FFF0', borderRadius: 4, padding: 7}}>
                                        {excludeColumns && !excludeColumns.find(c => c === 'PollQuestion') && excludeColumns.find(c => c === 'Delete')
                                        ?  <div style={{ display: 'flex', flexDirection: 'row', fontSize: '120%', justifyContent: 'space-between'}}>
                                                <span style={{ textDecoration: 'underline' }}>{comment.pollQuestion}</span>
                                            </div>
                                        : null}
                                         {!excludeColumns || (!excludeColumns.find(c => c === 'PollQuestion') && !excludeColumns.find(c => c === 'Delete'))
                                        ?  <div style={{ display: 'flex', flexDirection: 'row', fontSize: '120%', justifyContent: 'space-between'}}>
                                                <span style={{ textDecoration: 'underline' }}>{comment.pollQuestion}</span>
                                                <FontAwesomeIcon 
                                                    icon={faTrashAlt} 
                                                    size='1x'
                                                    style={{ color: "#dc3545", cursor: 'pointer' }} 
                                                    onClick={() => this.handleDeleteBtnPressed(comment.id)}
                                                />
                                            </div>
                                        : null}
                                        {!excludeColumns || !excludeColumns.find(c => c === 'AddedOn')
                                         ? <span>Добавен: {dateToLocal(comment.addedOn)}</span>
                                         : null}
                                        {!excludeColumns || !excludeColumns.find(c => c === 'User')
                                         ? <span>Потребител: {comment.user ? comment.user : 'Анонимен'}</span>
                                         : null}
                                        {!excludeColumns || !excludeColumns.find(c => c === 'Status')
                                         ? <span>Статус: {this.getCommentStatus(comment.statusId)}</span>
                                         : null}
                                        {!excludeColumns || !excludeColumns.find(c => c === 'IPAddress')
                                         ?  <span>IP Адрес: {comment.ipAddress ? comment.ipAddress : 'Неизвестен'}</span>
                                         : null}
                                </div>
                                <div style={{whiteSpace: 'pre-line', marginTop: 5}}>
                                    {this.getCommentContent(comment.content, comment.id)}
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginTop: 5}}> 
                                <a className="btn btn-primary btn-sm" href={`/edit-pollcomment/${comment.id}`}>Редактирай</a>
                                {/* <Button size="sm" variant="primary" onClick={() => this.props.history.push(`/edit-pollcomment/${comment.id}`)}>Редактирай</Button> */}
                                </div>
                            </Card.Body>
                        </Card>
                    )
                })}
            </div>
        );
    }

    render() {
        const { showDeleteConformation } = this.state;

        return (
            <>
               {this.renderComments()}

               <Modal show={showDeleteConformation} onHide={() => this.setState({ showDeleteConformation: false, commentToDelete: '' })}>
                    <Modal.Header closeButton>
                    <Modal.Title>Изтриване на коментар</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Сигурни ли сте, че искате да изтриете коментара?</Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={() => this.setState({ showDeleteConformation: false, commentToDelete: '' })}>
                        Не
                    </Button>
                    <Button variant="danger" onClick={this.handleConfirmDeletionPressed}>
                       Да
                    </Button>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }
}

export default PollCommentsGrid;