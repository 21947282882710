import axios from 'axios';
import * as HelperFunctions  from '../common/functions/helperFunctions';
import { CommentsListFilterModel } from '../models/comment/commentsListFilterModel';
import { PollCommentEditModel } from '../models/pollComment/pollCommentEditModel';

class PollCommentsService {
    getAllByPoll(pollId: string) {
      return axios({
          url: `pollcomments/list/byPoll/${pollId}`,
          method: 'get', headers: {
            "Content-Type": "application/json; charset=utf-8",
          },
          withCredentials: true
        })
          .then(res => res.data.data)
          .catch(error => 
            Promise.reject(HelperFunctions.getErrorMessage(error))
        )
  }

  getComment(id: string) {
    return axios({
        url: `pollcomments/${id}`,
        method: 'get', headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
        withCredentials: true
      })
        .then(res => res.data.data)
        .catch(error => 
          Promise.reject(HelperFunctions.getErrorMessage(error))
      )
  }

  editComment(comment: PollCommentEditModel) {
    return axios({
      url: `pollcomments`,
      method: 'put', headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
      data: JSON.stringify(comment),
      withCredentials: true
    })
      .then(res => res.data.data)
      .catch(error => 
        Promise.reject(HelperFunctions.getErrorMessage(error))
    )
  }

  getAll(from: number, filter: CommentsListFilterModel) {
    return axios({
        url: `pollcomments/list/${from}`,
        method: 'post', headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
        data: JSON.stringify(filter),
        withCredentials: true
      })
        .then(res => res.data.data)
        .catch(error => 
          Promise.reject(HelperFunctions.getErrorMessage(error))
      )
}

getByUser(userId: string) {
  return axios({
    url: `pollcomments/list/byUser/${userId}`,
    method: 'get', headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
    withCredentials: true
  })
    .then(res => res.data.data)
    .catch(error => 
      Promise.reject(HelperFunctions.getErrorMessage(error))
  )
}

delete(commentId: string) {
  return axios({
    url: `pollcomments/${commentId}`,
    method: 'delete', headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
    withCredentials: true
  })
    .then(res => res.data.data)
    .catch(error => 
      Promise.reject(HelperFunctions.getErrorMessage(error))
  )
}

}

export default PollCommentsService;