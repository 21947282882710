import React, { Component } from 'react';
import { StoryGridModel } from '../../../models/story/storyGridModel';
import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';
import { dateToLocal } from '../../functions/helperFunctions';
import { StoryStatus } from '../../enums';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface IProps {
    stories: Array<StoryGridModel> | null;
    excludeColumns: Array<string>;
    onDeleteStory: any;
}

interface IState {
    showDeleteConformation: boolean;
    storyToDelete: string;
}

class StoriesGrid extends Component<IProps, IState> {
    public static defaultProps = {
        excludeColumns: null,
        onDeleteStory: null
    };

    constructor(props: IProps) {
        super(props);

        this.state = {
            showDeleteConformation: false,
            storyToDelete: '',
        }
    }

    getStoryStatus = (storyStatusId: number) => {
        switch(storyStatusId) {
            case StoryStatus.New : 
                return (<span style={{ color: '#FFC300'}}>Нова / чака одобрение</span>);
            case StoryStatus.Active :
                return (<span style={{ color: '#28B463'}}>Активна</span>);
            case StoryStatus.StoppedByAdmin :
                return (<span style={{ color: '#E74C3C'}}>Спряна / неактивна</span>);
            case StoryStatus.DeletedByUser :
                return (<span style={{ color: '#6E2C00'}}>Спряна / изтрит потребителски профил</span>);
        }
    }

    renderAsList = () => {
        const { stories, excludeColumns } = this.props;

        return(
            <div>
                {stories?.map((story, index) => {
                    return(
                        <Card key={story.id} style={{marginBottom: 10}}>
                            <Card.Body>
                                <a href={`/add-edit-story/${story.id}`} style={{ marginBottom: 5 }}>{story.title}</a>
                                <div style={{ display: 'flex', flexDirection: 'column', fontSize: '85%'}}>
                                    {!excludeColumns || !excludeColumns.find(c => c === 'Category')
                                     ?  <span>Категория: {story.category ? story.category : '-'}</span>
                                     : null}
                                    {!excludeColumns || !excludeColumns.find(c => c === 'User')
                                     ?   <span>Потребител: {story.user ? story.user : 'Анонимен'}</span>
                                     : null}
                                    {!excludeColumns || !excludeColumns.find(c => c === 'Views')
                                     ?   <span>Прегледи: {story.views}</span>
                                     : null}
                                    {!excludeColumns || !excludeColumns.find(c => c === 'Rating')
                                     ?    <span>Рейтинг: {story.rating}/{story.votes}</span>
                                     : null}
                                    {!excludeColumns || !excludeColumns.find(c => c === 'DateAdded')
                                     ?     <span>Добавена: {dateToLocal(story.dateAdded)}</span>
                                     : null}
                                    {!excludeColumns || !excludeColumns.find(c => c === 'Status')
                                     ?      <span>Статус: {this.getStoryStatus(story.statusId)}</span>
                                     : null}
                                    {story.comments > 0
                                     ? <a href={`/story-comments/${story.id}`}>Коментари: {story.comments}</a>
                                     : <span>Коментари: {story.comments}</span>}
                                </div>
                               
                            </Card.Body>
                    </Card>
                    )
                })}
            </div>
        )
    }

    handleDeleteBtnPressed = (storyId: string) => {
        this.setState({
            showDeleteConformation: true,
            storyToDelete: storyId,
        });
    }

    handleConfirmDeletionPressed = () => {
        const { storyToDelete } = this.state;

        if(storyToDelete) {
            this.props.onDeleteStory(storyToDelete);
        }

        this.setState({
            storyToDelete: '',
            showDeleteConformation: false,
        })
    }

    renderAsTable = () => {
        const { stories, excludeColumns } = this.props;

        return (
            <Table striped bordered hover variant="light" responsive style={{ fontSize: '85%'}}>
                    <thead>
                    <tr>
                        <th>Заглавие</th>
                        <th>Коментари</th>
                        {!excludeColumns || !excludeColumns.find(c => c === 'Category')
                            ? <th>Категория</th>
                            : null}
                        {!excludeColumns || !excludeColumns.find(c => c === 'User')
                            ? <th>Потребител</th>
                            : null}
                        {!excludeColumns || !excludeColumns.find(c => c === 'Views')
                            ? <th>Прегледи</th>
                            : null}
                        {!excludeColumns || !excludeColumns.find(c => c === 'Rating')
                            ? <th>Рейтинг</th>
                            : null}
                        {!excludeColumns || !excludeColumns.find(c => c === 'DateAdded')
                            ? <th>Добавена</th>
                            : null}
                        {!excludeColumns || !excludeColumns.find(c => c === 'Status')
                            ? <th>Статус</th>
                            : null}
                        {!excludeColumns || !excludeColumns.find(c => c === 'Delete')
                            ? <th></th>
                            : null}
                    </tr>
                    </thead>
                    <tbody>
                        {stories?.map((story, index) => {
                            return (
                            <tr key={story.id}>
                                <td><a href={`/add-edit-story/${story.id}`}>{story.title}</a></td>
                                <td style={{ textAlign: 'center'}}>
                                   {story.comments > 0
                                    ? <a href={`/story-comments/${story.id}`}>{story.comments}</a>
                                    : <span>{story.comments}</span>}
                                </td>
                                {!excludeColumns || !excludeColumns.find(c => c === 'Category')
                                    ? <td>{story.category}</td>
                                    : null}
                                {!excludeColumns || !excludeColumns.find(c => c === 'User')
                                    ? <td>{story.user ? story.user : 'Анонимен'}</td>
                                    : null}
                                {!excludeColumns || !excludeColumns.find(c => c === 'Views')
                                    ?  <td style={{ textAlign: 'center'}}>{story.views}</td>
                                    : null}
                                {!excludeColumns || !excludeColumns.find(c => c === 'Rating')
                                    ?   <td style={{ textAlign: 'center'}}>{story.rating}/{story.votes}</td>
                                    : null}
                                {!excludeColumns || !excludeColumns.find(c => c === 'DateAdded')
                                    ?   <td>{dateToLocal(story.dateAdded)}</td>
                                    : null}
                                {!excludeColumns || !excludeColumns.find(c => c === 'Status')
                                    ?   <td>{this.getStoryStatus(story.statusId)}</td>
                                    : null}
                                {!excludeColumns || !excludeColumns.find(c => c === 'Delete')
                                    ?   <td>
                                             <FontAwesomeIcon 
                                                icon={faTrashAlt} 
                                                size='lg'
                                                style={{ color: "#dc3545", cursor: 'pointer' }} 
                                                onClick={() => this.handleDeleteBtnPressed(story.id)}
                                            />
                                        </td>
                                    : null}
                            </tr>
                            )
                        })}
                    </tbody>
            </Table> 
        )
    }

    render() {
        const { stories } = this.props;
        const { showDeleteConformation } = this.state;

        return (
            <>
                {stories && stories.length > 0 && window.innerWidth > 960
                ? this.renderAsTable()
               : null}

                {stories && stories.length > 0 && window.innerWidth <= 960
                ? this.renderAsList()
                : null}

                <Modal show={showDeleteConformation} onHide={() => this.setState({ showDeleteConformation: false, storyToDelete: '' })}>
                    <Modal.Header closeButton>
                    <Modal.Title>Изтриване на история</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Сигурни ли сте, че искате да изтриете историята?</Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={() => this.setState({ showDeleteConformation: false, storyToDelete: '' })}>
                        Не
                    </Button>
                    <Button variant="danger" onClick={this.handleConfirmDeletionPressed}>
                       Да
                    </Button>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }
}

export default StoriesGrid;