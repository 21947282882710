import React, { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import PollsViewsGrid from '../../../common/components/polls/pollsViewsGrid';
import Spinner from '../../../common/components/spinner/spinner';
import { PollViewModel } from '../../../models/polls/pollViewModel';
import PollsService from '../../../services/pollsService';

const PollViewsComponent = ({poll}) => {
   const pollsService = new PollsService();
   const [isLoading, setIsLoading] = useState(false);
   const [views, setViews] = useState<Array<PollViewModel>>(new Array<PollViewModel>());
   const [errorMessage, setErrorMessage] = useState('');

   const getViews = async () => {
    try {
     setIsLoading(true);
     let views = await pollsService.getPollViews(poll.id);
     setViews(views);
     setIsLoading(false)
    } catch(error) {
        setErrorMessage(error.message);
        setIsLoading(false);
    }
}
// eslint-disable-line react-hooks/exhaustive-deps
   useEffect(() => {
    if(poll && poll.id) {
        getViews();
    }
    // eslint-disable-next-line
   },[poll])

   return(
       <div>
            {isLoading ? <Spinner /> : null}
            {errorMessage
                ?   <Alert className="mt-3" variant={'danger'} style={{ fontSize: '85%'}}>
                        {errorMessage}
                    </Alert>
            : null}
            {poll 
             ? <div className='mt-2'>
                 {views
                  ? <PollsViewsGrid views={views} excludeColumns={['PollQuestion']} />
                  : null
                 }
               </div>
             : null}
       </div>
    
   )
}

export default PollViewsComponent;