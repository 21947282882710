import React from 'react';
import { Card, Table } from 'react-bootstrap';
import { dateToLocal } from '../../functions/helperFunctions';
import { PollViewModel } from '../../../models/polls/pollViewModel';

const PollsViewsGrid = ({ views, excludeColumns }) => {

    const trimPollQuestion = (question: string) => {
        if(question && question.length > 150) {
            return `${question.substring(0, 147)}...`;
        }

        return question;
    }

    const renderAsList = () => {
        return(
            <div>
                {views?.map((view: PollViewModel, index) => {
                    return(
                        <Card key={view.id} className="mb-1">
                            <Card.Body>
                                {!excludeColumns || !excludeColumns.find(c => c === 'PollQuestion')
                                     ?   <a href={`/edit-poll/${view.pollId}`} className="mt-1">{trimPollQuestion(view.pollQuestion)}</a>
                                     : null}
                                <div style={{ display: 'flex', flexDirection: 'column', fontSize: '85%'}}>
                                    {!excludeColumns || !excludeColumns.find(c => c === 'Date')
                                     ?    <span>Дата: {dateToLocal(view.date)}</span>
                                     : null}
                                    {!excludeColumns || !excludeColumns.find(c => c === 'User')
                                     ?   <span>Потребител: {view.user ? view.user : 'Анонимен'}</span>
                                     : null}
                                    {!excludeColumns || !excludeColumns.find(c => c === 'IpAddress')
                                     ?    <span>Ip Address: {view.ipAddress}</span>
                                     : null}
                                </div>
                            </Card.Body>
                    </Card>
                    );
                })}
            </div>);
    }

    const renderAsTable = () => {
        return (
            <Table striped bordered hover variant="light" responsive style={{ fontSize: '85%'}}>
                    <thead>
                    <tr>
                        {!excludeColumns || !excludeColumns.find(c => c === 'PollQuestion')
                            ? <th>Въпрос</th>
                            : null}
                        {!excludeColumns || !excludeColumns.find(c => c === 'CreatedOn')
                            ? <th>Дата</th>
                            : null}
                        {!excludeColumns || !excludeColumns.find(c => c === 'User')
                            ? <th>Потребител</th>
                            : null}
                   
                        {!excludeColumns || !excludeColumns.find(c => c === 'IpAddress')
                            ? <th>IP Адрес</th>
                            : null}
                    </tr>
                    </thead>
                    <tbody>
                        {views?.map((view: PollViewModel, index) => {
                            return (
                            <tr key={view.id}>
                                {!excludeColumns || !excludeColumns.find(c => c === 'PollQuestion')
                                      ?  <td> <a href={`/edit-poll/${view.pollId}`} className="mt-1">{trimPollQuestion(view.pollQuestion)}</a></td>
                                      : null}
                                {!excludeColumns || !excludeColumns.find(c => c === 'CreatedOn')
                                      ?   <td>{dateToLocal(view.date)}</td>
                                      : null}
                                {!excludeColumns || !excludeColumns.find(c => c === 'User')
                                      ? <td>{view.user ? view.user : 'Анонимен'}</td>
                                      : null}
                                {!excludeColumns || !excludeColumns.find(c => c === 'IpAddress')
                                    ?  <td>{view.ipAddress}</td>
                                    : null}
                            </tr>
                            )
                        })}
                    </tbody>
            </Table> 
        )
    }

    return(
       <div className='mt-2'>
            {views && views.length > 0 && window.innerWidth > 960
                ? renderAsTable()
               : null}

            {views && views.length > 0 && window.innerWidth <= 960
                ? renderAsList()
                : null}
       </div>
    );
}

export default PollsViewsGrid;