import React from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import Spinner from '../../common/components/spinner/spinner';
import Card from 'react-bootstrap/Card';
import { PollStatus } from '../../common/enums';
import Paginator from '../../common/components/paginator/paginator';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { PollGridModel } from '../../models/polls/pollGridModel';
import { PollListFilterModel } from '../../models/polls/pollListFilterModel';
import PollsService from '../../services/pollsService';
import { PollListModel } from '../../models/polls/pollListModel';
import PollsGrid from '../../common/components/polls/pollsGrid';

interface IProps {
    history: any;
    match: any;
}

interface IState {
    isLoading: boolean;
    from: number;
    polls: Array<PollGridModel> | null;
    totalRecords: number;
    errorMessage: string;
    currentPage: number;
    pageSize: number;
    filterIsVisible: boolean;
    isFiltering: boolean;
    filter: PollListFilterModel;
}

class PollslListComponent extends React.Component<IProps, IState> {
    pollsService = new PollsService();

    constructor(props: IProps) {
        super(props);

        let currentPage = +this.props.match.params.page;

        if(!currentPage) {
            currentPage = 1
        }

        let pageSize = 15;
        let from = (currentPage - 1) * pageSize;

        let filter = new PollListFilterModel();

        this.state = {
            isLoading: false,
            errorMessage: '',
            polls: null,
            from,
            totalRecords: 0,
            currentPage,
            pageSize,
            filterIsVisible: false,
            isFiltering: false,
            filter,
        }
    }

    componentDidMount() {
        this.getPolls();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let nextPage = this.props.match.params.page;
        let prevPage = prevProps.match.params.page;

        if(prevPage !== nextPage) {
            if(!nextPage) {
                nextPage = 1;
            }

            let from = (nextPage - 1) * this.state.pageSize;

            this.setState({
                from,
                currentPage: +nextPage
            }, () => this.getPolls());
        }
    }

    getPolls = async () => {
        this.setState({
            isLoading: true,
            polls: new Array<PollGridModel>()
        });

        const { from, filter } = this.state;

        try {
            let pollListModel: PollListModel = await this.pollsService.getAll(from, filter);

            let totalRecords = 0;
            let polls = new Array<PollGridModel>();

            if(pollListModel) {
                totalRecords = pollListModel.pollsCount;
                polls = pollListModel.polls;
            }

            this.setState({
                totalRecords,
                polls,
                isLoading: false
            });

        } catch (error) {
            this.setState({
                isLoading: false,
                errorMessage: error.message
            });
        }
    }

    getPollStatus = (pollStatusId: number) => {
        switch(pollStatusId) {
            case PollStatus.New : 
                return (<span style={{ color: '#FFC300'}}>Нов / чака одобрение</span>);
            case PollStatus.Active :
                return (<span style={{ color: '#28B463'}}>Активен</span>);
            case PollStatus.Stopped :
                return (<span style={{ color: '#E74C3C'}}>Спрян / неактивен</span>);
        }
    }

    renderFilter = () => {
        const { filter } = this.state;

        return (
            <Card style={{marginBottom: 10, marginTop: 10}}>
                <Card.Body>
                    <h6>Филтър</h6>
                    <Form>
                        <Form.Group as={Row} controlId="titleSearch">
                            <Form.Label column sm="2" style={{ fontSize: '85%'}}>Заглавие</Form.Label>
                            <Col sm="10">
                                <Form.Control 
                                    type="text" 
                                    size="sm" 
                                    placeholder="Търсене във въпрос ..." 
                                    onChange={(e) => this.handleChange('question', e.target['value'])}
                                    value={filter.question ? filter.question : ''}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="userSearch">
                            <Form.Label column sm="2" style={{ fontSize: '85%'}}>Потребител</Form.Label>
                            <Col sm="10">
                                <Form.Control 
                                    type="text" 
                                    size="sm" 
                                    placeholder="Търсене по потребителско име ..." 
                                    onChange={(e) => this.handleChange('user', e.target['value'])}
                                    value={filter.user ? filter.user : ''}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="ipSearch">
                            <Form.Label column sm="2" style={{ fontSize: '85%'}}>Ip Адрес</Form.Label>
                            <Col sm="10">
                                <Form.Control 
                                    type="text" 
                                    size="sm" 
                                    placeholder="Търсене в IP Адрес ..." 
                                    onChange={(e) => this.handleChange('ipAddress', e.target['value'])}
                                    value={filter.ipAddress ? filter.ipAddress : ''}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="statusSearch">
                            <Form.Label column sm="2" style={{ fontSize: '85%'}}>Статус</Form.Label>
                            <Col sm="10">
                            <Form.Control size="sm" as="select"
                                value={filter.statusId ? filter.statusId : 0}
                                onChange={(e) => this.handleChange('statusId', +e.target['value'])}>
                                <option value={0}>--- избери статус ---</option>
                                <option value={PollStatus.New}>Нов / чака одобрение</option>
                                <option value={PollStatus.Active}>Активен</option>
                                <option value={PollStatus.Stopped}>Спрян / неактивен</option>
                            </Form.Control>
                            </Col>
                        </Form.Group>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
                            <div style={{ display: 'flex', flexDirection: 'row'}}>
                                <Button size="sm" variant="primary" onClick={this.handleOnFilterPressed}>Търси</Button>
                                <Button size="sm" variant="danger" onClick={this.handleOnClearFilterPressed} style={{ marginLeft: 10}}>Изчисти</Button>
                            </div>
                        </div>
                    </Form>
                </Card.Body>
            </Card>
        );
    }

    renderFilterValues = () => {
        const { filter } = this.state;

        return (
            <div style={{ fontSize: '85%', marginBottom: 15}}>
                {filter.question
                 ? <div>
                     Въпрос: <span style={{ fontWeight: 'bold'}}>{filter.question}</span> 
                   </div>
                : null}
                 {filter.user
                 ? <div>
                     Потребител: <span style={{ fontWeight: 'bold'}}>{filter.user}</span> 
                   </div>
                : null}
                  {filter.ipAddress
                 ? <div>
                     Ip Адрес: <span style={{ fontWeight: 'bold'}}>{filter.ipAddress}</span> 
                   </div>
                : null}
                 {filter.statusId && filter.statusId !== 0
                 ? <div>
                     Статус: <span style={{ fontWeight: 'bold'}}>{this.getPollStatus(+filter.statusId)}</span>
                   </div>
                : null}
                 <Button 
                    variant="danger" 
                    onClick={this.handleOnClearFilterPressed}
                    size="sm"
                    style={{ marginTop: 5}}>
                        Изчисти
                </Button>
            </div>
        )
    }

    toggleFilter = () => {
        let filterIsVisible = this.state.filterIsVisible;
        this.setState({
            filterIsVisible: !filterIsVisible
        }, () => !filterIsVisible ? this.getPolls() : null);
    }

    onChangePage = (page) => {
        this.props.history.push(`/polls/${page}`);
    }

    handleChange = (key: any, value: any) => {
        const filter: PollListFilterModel = { ...this.state.filter };

        filter[key] = value;

        this.setState({
            filter
        });
    }

    handleOnFilterPressed = () => {
        this.props.history.replace('/polls');

        const { filter } = this.state;

        let isFiltering = false;

        if(filter) {
            if(filter.question) { isFiltering = true }
            if(filter.user) { isFiltering = true }
            if(filter.ipAddress ) { isFiltering = true }
            if(filter.statusId) { isFiltering = true }
        }

        this.setState({
            from: 0,
            currentPage: 1,
            polls: null,
            totalRecords: 0,
            isFiltering,
            filterIsVisible: false,
        }, () => this.getPolls());
    }

    handleOnClearFilterPressed = () => {
        this.props.history.replace('/polls');
        let filter = new PollListFilterModel();

        this.setState({
            from: 0,
            currentPage: 1,
            polls: null,
            totalRecords: 0,
            filter,
            isFiltering: false,
        }, () => this.getPolls());
    }

    deletePoll = async (pollId: string) => {
        if(pollId) {
            try {
                this.setState({ isLoading: true, errorMessage: '' });
                await this.pollsService.deletePoll(pollId);
                this.setState({
                    isLoading: false,
                }, () => this.getPolls())
            } catch(error) {
                this.setState({
                    isLoading: false,
                    errorMessage: error.message
                });
            }
        }
    }

    render() {
        const { isLoading, errorMessage, polls, totalRecords, pageSize, currentPage,
            filterIsVisible, isFiltering } = this.state;

        return (
            <>
            <div style={{ marginBottom: 50 }}>
                {isLoading ? <Spinner /> : null}
                <Breadcrumb style={{ fontSize: '85%'}}>
                    <Breadcrumb.Item href="/">Начало</Breadcrumb.Item>
                    <Breadcrumb.Item active>
                        Въпроси
                    </Breadcrumb.Item>
                </Breadcrumb>
                <div style={{ 
                    display: 'flex', 
                    flexDirection: 'row', 
                    justifyContent: 'space-between', 
                    textAlign: 'center'}}>
                    <h5>Въпроси ({totalRecords})</h5>
                    <div style={{ display: 'flex', flexDirection: 'row'}}>
                        <FontAwesomeIcon 
                            icon={faSearch} 
                            style={{ color: "#FF7851", cursor: 'pointer', marginRight: 15 }} 
                            size='lg' 
                            onClick={this.toggleFilter}
                        />
                    </div> 
                </div>
                {errorMessage ? 
                  <Alert variant={'danger'}>
                  {errorMessage}
                </Alert>
                : null}

                {!filterIsVisible && isFiltering
                 ? this.renderFilterValues()
                 : null}
               
                {filterIsVisible
                 ? this.renderFilter()
                 : null}

               <PollsGrid 
                    polls={polls}
                    onDeletePoll={this.deletePoll}
                    excludeColumns={[]}
               />

                {totalRecords > 15 && !isLoading
                ? <Paginator 
                    totalRecords={totalRecords}
                    pageSize={pageSize}
                    currentPage={currentPage}
                    style={{marginTop: 10, justifyContent: 'center'}}
                    onClick={this.onChangePage} />
              : null}
            </div>
            
        </>
        )
    }
}

export default PollslListComponent;