import React from 'react';
import Alert from 'react-bootstrap/Alert';
import Spinner from '../../common/components/spinner/spinner';
import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';
import { dateToLocal } from '../../common/functions/helperFunctions';
import { faTimes, faCheck, faPlusSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { CategoryModel } from '../../models/category/categoryModel';
import CategoriesService from '../../services/categoriesService';

interface IProps {
    history: any;
    match: any;
}

interface IState {
    isLoading: boolean;
    categories: Array<CategoryModel> | null;
    errorMessage: string;
}

class CategoriesListComponent extends React.Component<IProps, IState> {
    categoriesService = new CategoriesService();

    constructor(props: IProps) {
        super(props);

        this.state = {
            isLoading: false,
            errorMessage: '',
            categories: null,
        }
    }

    componentDidMount() {
        this.getCategories();
    }

    getCategories = async () => {
        this.setState({
            isLoading: true,
            categories: new Array<CategoryModel>()
        });

        try {
            let categories: Array<CategoryModel> = await this.categoriesService.getAll();

            this.setState({
                categories,
                isLoading: false
            });

        } catch (error) {
            this.setState({
                isLoading: false,
                errorMessage: error.message
            });
        }
    }

    renderCategories = () => {
        if (window.innerWidth > 960) {
            return this.renderCategoriesAsList();
        } else {
            return this.renderCategoriesAsTable();
        }
    }

    renderCategoriesAsTable = () => {
        const { categories } = this.state;

        return (
            <Table striped bordered hover variant="light" responsive style={{ fontSize: '85%'}}>
                    <thead>
                    <tr>
                        <th>Име</th>
                        <th style={{ textAlign: 'center'}}>Активна</th>
                        <th style={{ textAlign: 'center'}}>Само рег. потребители</th>
                        <th style={{ textAlign: 'center'}}>Брой истории</th>
                        <th>Добавена</th>
                        <th>Последна промяна</th>
                    </tr>
                    </thead>
                    <tbody>
                        {categories?.map((category, index) => {
                            return (
                            <tr key={category.id} style={ !category.isActive ? { backgroundColor: '#F5B7B1'} : {}}>
                                <td><a href={`/edit-category/${category.id}`}>{category.name}</a></td>
                                <td style={{ textAlign: 'center'}}>
                                    {category.isActive 
                                        ? <FontAwesomeIcon 
                                            icon={faCheck} 
                                            style={{ color: "#32CD32" }} 
                                            size='lg' 
                                            /> 
                                        : <FontAwesomeIcon 
                                            icon={faTimes} 
                                            style={{ color: "#FF7851" }} 
                                            size='lg' 
                                    />}
                                </td>
                                <td style={{ textAlign: 'center'}}>
                                    {category.authUsersOnly 
                                        ? <FontAwesomeIcon 
                                            icon={faCheck} 
                                            style={{ color: "#32CD32" }} 
                                            size='lg' 
                                            /> 
                                        : <FontAwesomeIcon 
                                            icon={faTimes} 
                                            style={{ color: "#FF7851" }} 
                                            size='lg' 
                                    />}
                                </td>
                                <td style={{ textAlign: 'center'}}>{category.storiesCount}</td>
                                <td>{dateToLocal(category.createdOn)}</td>
                                <td>{dateToLocal(category.updatedOn)}</td>
                            </tr>
                            )
                        })}
                    </tbody>
            </Table> 
        )
    }

    renderCategoriesAsList = () => {
        const { categories } = this.state;

        return(
            <div>
                {categories?.map((category, index) => {
                    return(
                        <Card key={category.id} style={{marginBottom: 10}}>
                            <Card.Body style={ !category.isActive ? { backgroundColor: '#F5B7B1'}: {}}>
                                <a href={`/edit-category/${category.id}`} style={{ marginBottom: 5 }}>{category.name}</a>
                                <div style={{ display: 'flex', flexDirection: 'column', fontSize: '85%'}}>
                                    <div style={{ display: 'flex', flexDirection: 'row'}}>
                                        <span>Активна</span>
                                        {category.isActive 
                                        ? <FontAwesomeIcon 
                                            icon={faCheck} 
                                            style={{ color: "#32CD32", marginLeft: 5 }} 
                                            size='lg' 
                                            /> 
                                        : <FontAwesomeIcon 
                                            icon={faTimes} 
                                            style={{ color: "#FF7851", marginLeft: 5 }} 
                                            size='lg' 
                                        />}
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'row'}}>
                                        <span>Само рег. потребители</span>
                                        {category.authUsersOnly 
                                        ? <FontAwesomeIcon 
                                            icon={faCheck} 
                                            style={{ color: "#32CD32", marginLeft: 5 }} 
                                            size='lg' 
                                            /> 
                                        : <FontAwesomeIcon 
                                            icon={faTimes} 
                                            style={{ color: "#FF7851", marginLeft: 5 }} 
                                            size='lg' 
                                        />}
                                    </div>
                                    <span>Брой истории: {category.storiesCount}</span>
                                    <span>Добавена: {dateToLocal(category.createdOn)}</span>
                                    <span>Последна промяна: {dateToLocal(category.updatedOn)}</span>
                                </div>
                            </Card.Body>
                    </Card>
                    )
                })}
            </div>
        )
    }

    render() {
        const { isLoading, errorMessage, categories } = this.state;

        return (
            <>
            <div style={{ marginBottom: 50 }}>
                {isLoading ? <Spinner /> : null}
                <Breadcrumb style={{ fontSize: '85%'}}>
                    <Breadcrumb.Item href="/">Начало</Breadcrumb.Item>
                    <Breadcrumb.Item active>
                        Категории
                    </Breadcrumb.Item>
                </Breadcrumb>
                <div style={{ 
                    display: 'flex', 
                    flexDirection: 'row', 
                    justifyContent: 'space-between', 
                    textAlign: 'center'}}>
                    <h5>Категории {categories ? `(${categories.length})` : null}</h5>
                    <FontAwesomeIcon 
                        icon={faPlusSquare} 
                        style={{ color: "#32A224", cursor: 'pointer' }} 
                        size='lg' 
                        onClick={() => this.props.history.push('add-category')}
                    />
                </div>

                {errorMessage ? 
                  <Alert variant={'danger'}>
                  {errorMessage}
                </Alert>
                : null}

                {categories && categories.length > 0 && window.innerWidth > 960
                 ? this.renderCategoriesAsTable()
                : null}

                {categories && categories.length > 0 && window.innerWidth <= 960
                 ? this.renderCategoriesAsList()
                : null}

            </div>
            
        </>
        )
    }
}

export default CategoriesListComponent;