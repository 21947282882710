import React from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import Spinner from '../../common/components/spinner/spinner';
import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';
import { dateToLocal } from '../../common/functions/helperFunctions';
import { UserRole } from '../../common/enums';
import Paginator from '../../common/components/paginator/paginator';
import { faSearch, faTimes, faCheck, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { UserListFilterModel } from '../../models/user/userListFilterModel';
import { UserGridModel } from '../../models/user/userGridModel';
import UsersService from '../../services/usersService';
import { UserListModel } from '../../models/user/userListModel';
import Modal from 'react-bootstrap/Modal';

interface IProps {
    history: any;
    match: any;
}

interface IState {
    isLoading: boolean;
    from: number;
    users: Array<UserGridModel> | null;
    totalRecords: number;
    errorMessage: string;
    currentPage: number;
    pageSize: number;
    filterIsVisible: boolean;
    isFiltering: boolean;
    filter: UserListFilterModel;
    showDeleteConformation: boolean;
    userToDelete: string,
}

class UsersListComponent extends React.Component<IProps, IState> {
    usersService = new UsersService();

    constructor(props: IProps) {
        super(props);

        let currentPage = +this.props.match.params.page;

        if(!currentPage) {
            currentPage = 1
        }

        let pageSize = 15;
        let from = (currentPage - 1) * pageSize;

        let filter = new UserListFilterModel();
        filter.isActive = null;
        filter.roleId = null;
        filter.emailVerified = null;

        this.state = {
            isLoading: false,
            errorMessage: '',
            users: null,
            from,
            totalRecords: 0,
            currentPage,
            pageSize,
            filterIsVisible: false,
            isFiltering: false,
            filter,
            showDeleteConformation: false,
            userToDelete: '',
        }
    }

    componentDidMount() {
        this.getUsers();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let nextPage = this.props.match.params.page;
        let prevPage = prevProps.match.params.page;

        if(prevPage !== nextPage) {
            if(!nextPage) {
                nextPage = 1;
            }

            let from = (nextPage - 1) * this.state.pageSize;

            this.setState({
                from,
                currentPage: +nextPage
            }, () => this.getUsers());
        }
    }

    getUsers = async () => {
        this.setState({
            isLoading: true,
            users: new Array<UserGridModel>()
        });

        const { from, filter } = this.state;

        try {
            let usersListModel: UserListModel = await this.usersService.getAll(from, filter);

            let totalRecords = 0;
            let users = new Array<UserGridModel>();

            if(usersListModel) {
                totalRecords = usersListModel.usersCount;
                users = usersListModel.users;
            }

            this.setState({
                totalRecords,
                users,
                isLoading: false
            });

        } catch (error) {
            this.setState({
                isLoading: false,
                errorMessage: error.message
            });
        }
    }

    renderUsers = () => {
        if (window.innerWidth > 960) {
            return this.renderUsersAsList();
        } else {
            return this.renderUsersAsTable();
        }
    }

    renderUsersAsTable = () => {
        const { users } = this.state;

        return (
            <Table striped bordered hover variant="light" responsive style={{ fontSize: '85%'}}>
                    <thead>
                    <tr>
                        <th>Потребителско име</th>
                        <th>Роля</th>
                        <th>Имейл</th>
                        <th style={{ textAlign: 'center'}}>Потвърден имейл</th>
                        <th style={{ textAlign: 'center'}}>Активен</th>
                        <th>Създаден</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                        {users?.map((user, index) => {
                            return (
                            <tr key={user.id} style={ !user.isActive ? { backgroundColor: '#F5B7B1'} : {}}>
                                <td><a href={`/edit-user/${user.id}`}>{user.userName}</a></td>
                                <td>
                                    {user.roleId === UserRole.Administrator ? 'Администратор' : null}
                                    {user.roleId === UserRole.User ? 'Потребител' : null}
                                </td>
                                <td>{user.email}</td>
                                <td style={{ textAlign: 'center'}}>
                                    {user.emailVerified 
                                        ? <FontAwesomeIcon 
                                            icon={faCheck} 
                                            style={{ color: "#32CD32" }} 
                                            size='lg' 
                                            /> 
                                        : <FontAwesomeIcon 
                                            icon={faTimes} 
                                            style={{ color: "#FF7851" }} 
                                            size='lg' 
                                    />}
                                </td>
                                <td style={{ textAlign: 'center'}}>
                                    {user.isActive 
                                        ? <FontAwesomeIcon 
                                            icon={faCheck} 
                                            style={{ color: "#32CD32" }} 
                                            size='lg' 
                                            /> 
                                        : <FontAwesomeIcon 
                                            icon={faTimes} 
                                            style={{ color: "#FF7851" }} 
                                            size='lg' 
                                    />}
                                </td>
                                <td>{dateToLocal(user.createdOn)}</td>
                                <td>
                                    <FontAwesomeIcon 
                                        icon={faTrashAlt} 
                                        size='lg'
                                        style={{ color: "#dc3545", cursor: 'pointer' }} 
                                        onClick={() => this.handleDeleteBtnPressed(user.id)}
                                    />
                                </td>
                            </tr>
                            )
                        })}
                    </tbody>
            </Table> 
        )
    }

    renderUsersAsList = () => {
        const { users } = this.state;

        return(
            <div>
                {users?.map((user, index) => {
                    return(
                        <Card key={user.id} style={{marginBottom: 10}}>
                            <Card.Body style={ !user.isActive ? { backgroundColor: '#F5B7B1'}: {}}>
                                <a href={`/edit-user/${user.id}`} style={{ marginBottom: 5 }}>{user.userName}</a>
                                <div style={{ display: 'flex', flexDirection: 'column', fontSize: '85%'}}>
                                    <span>Роля: {user.roleId === UserRole.Administrator ? 'Администратор' : null}
                                                {user.roleId === UserRole.User ? 'Потребител' : null}
                                    </span>
                                    <span>Имейл: {user.email}</span>
                                    <div style={{ display: 'flex', flexDirection: 'row'}}>
                                        <span>Потвърден имейл</span>
                                        {user.emailVerified 
                                        ? <FontAwesomeIcon 
                                            icon={faCheck} 
                                            style={{ color: "#32CD32", marginLeft: 5 }} 
                                            size='lg' 
                                            /> 
                                        : <FontAwesomeIcon 
                                            icon={faTimes} 
                                            style={{ color: "#FF7851", marginLeft: 5 }} 
                                            size='lg' 
                                        />}
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'row'}}>
                                        <span>Активен</span>
                                        {user.isActive 
                                        ? <FontAwesomeIcon 
                                            icon={faCheck} 
                                            style={{ color: "#32CD32", marginLeft: 5 }} 
                                            size='lg' 
                                            /> 
                                        : <FontAwesomeIcon 
                                            icon={faTimes} 
                                            style={{ color: "#FF7851", marginLeft: 5 }} 
                                            size='lg' 
                                        />}
                                    </div>
                                    <span>Създаден: {dateToLocal(user.createdOn)}</span>
                                </div>
                            </Card.Body>
                    </Card>
                    )
                })}
            </div>
        )
    }

    renderFilter = () => {
        const { filter } = this.state;

        return (
            <Card style={{marginBottom: 10, marginTop: 10}}>
                <Card.Body>
                    <h6>Филтър</h6>
                    <Form>
                        <Form.Group as={Row} controlId="userNameSearch">
                            <Form.Label column sm="2" style={{ fontSize: '85%'}}>Потребителско име</Form.Label>
                            <Col sm="10">
                                <Form.Control 
                                    type="text" 
                                    size="sm" 
                                    onChange={(e) => this.handleChange('userName', e.target['value'])}
                                    value={filter.userName}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="emailSearch">
                            <Form.Label column sm="2" style={{ fontSize: '85%'}}>Имейл</Form.Label>
                            <Col sm="10">
                                <Form.Control 
                                    type="text" 
                                    size="sm" 
                                    onChange={(e) => this.handleChange('email', e.target['value'])}
                                    value={filter.email}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="userActive">
                            <Form.Label column sm="2" style={{ fontSize: '85%'}}>Активен</Form.Label>
                            <Col sm="10">
                            <Form.Control 
                                size="sm" 
                                as="select"
                                value={filter.isActive == null ? -1 : filter.isActive ? 1 : 0}
                                onChange={(e) => this.handleChange('isActive', +e.target['value'])}>
                                <option value={-1}>--- изберете ---</option>
                                <option value={0}>Неактивен</option>
                                <option value={1}>Активен</option>
                            </Form.Control>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="emailVerified">
                            <Form.Label column sm="2" style={{ fontSize: '85%'}}>Потвърден имейл</Form.Label>
                            <Col sm="10">
                            <Form.Control 
                                size="sm" 
                                as="select"
                                value={filter.emailVerified == null ? -1 : filter.emailVerified ? 1 : 0}
                                onChange={(e) => this.handleChange('emailVerified', +e.target['value'])}>
                                <option value={-1}>--- изберете ---</option>
                                <option value={0}>Не</option>
                                <option value={1}>Да</option>
                            </Form.Control>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="userRole">
                            <Form.Label column sm="2" style={{ fontSize: '85%'}}>Роля</Form.Label>
                            <Col sm="10">
                            <Form.Control 
                                size="sm" 
                                as="select"
                                value={filter.roleId == null ? -1 : filter.roleId}
                                onChange={(e) => this.handleChange('roleId', +e.target['value'])}>
                                <option value={-1}>--- изберете ---</option>
                                <option value={UserRole.Administrator}>Администратор</option>
                                <option value={UserRole.User}>Потребител</option>
                            </Form.Control>
                            </Col>
                        </Form.Group>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
                            <div style={{ display: 'flex', flexDirection: 'row'}}>
                                <Button size="sm" variant="primary" onClick={this.handleOnFilterPressed}>Търси</Button>
                                <Button size="sm" variant="danger" onClick={this.handleOnClearFilterPressed} style={{ marginLeft: 10}}>Изчисти</Button>
                            </div>
                        </div>
                    </Form>
                </Card.Body>
            </Card>
        );
    }

    renderFilterValues = () => {
        const { filter } = this.state;

        return (
            <div style={{ fontSize: '85%', marginBottom: 15}}>
                {filter.userName
                 ? <div>
                     Потребителско име: <span style={{ fontWeight: 'bold'}}>{filter.userName}</span> 
                   </div>
                : null}
                 {filter.email
                 ? <div>
                     Имейл: <span style={{ fontWeight: 'bold'}}>{filter.email}</span> 
                   </div>
                : null}
                 {filter.isActive !== null
                 ? <div>
                     Активен: <span style={{ fontWeight: 'bold'}}>{filter.isActive ? 'ДА' : 'НЕ'}</span>
                   </div>
                : null}
                {filter.emailVerified !== null
                 ? <div>
                     Потвърден имейл: <span style={{ fontWeight: 'bold'}}>{filter.emailVerified ? 'ДА' : 'НЕ'}</span>
                   </div>
                : null}
                 {filter.roleId !== null
                 ? <div>
                     Роля: <span style={{ fontWeight: 'bold'}}>
                                {filter.roleId === UserRole.Administrator ? 'Администратор' : null}
                                {filter.roleId === UserRole.User ? 'Потребител' : null}
                            </span>
                   </div>
                : null}
                 <Button 
                    variant="danger" 
                    onClick={this.handleOnClearFilterPressed}
                    size="sm"
                    style={{ marginTop: 5}}>
                        Изчисти
                </Button>
            </div>
        )
    }

    toggleFilter = () => {
        let filterIsVisible = this.state.filterIsVisible;
        this.setState({
            filterIsVisible: !filterIsVisible
        });
    }

    onChangePage = (page) => {
        this.props.history.push(`/users/${page}`);
    }

    handleChange = (key: any, value: any) => {
        const filter: UserListFilterModel = { ...this.state.filter };

        if(key === 'isActive') {
            if(value === 1) { value = true }
            if(value === 0) { value = false }
            if(value === -1) { value = null }
        }

        if(key === 'emailVerified') {
            if(value === 1) { value = true }
            if(value === 0) { value = false }
            if(value === -1) { value = null }
        }

        if(key === 'roleId') {
            if(value === -1) { value = null }
        }

        filter[key] = value;

        this.setState({
            filter
        });
    }

    handleOnFilterPressed = () => {
        this.props.history.replace('/users');

        const { filter } = this.state;

        let isFiltering = false;

        if(filter) {
            if(filter.userName) { isFiltering = true }
            if(filter.email) { isFiltering = true }
            if(filter.isActive !== null) { isFiltering = true }
            if(filter.emailVerified !== null) { isFiltering = true }
            if(filter.roleId !== null) { isFiltering = true }
        }

        this.setState({
            from: 0,
            currentPage: 1,
            users: null,
            totalRecords: 0,
            isFiltering,
            filterIsVisible: false,
        }, () => this.getUsers());
    }

    handleOnClearFilterPressed = () => {
        this.props.history.replace('/users');

        let filter = new UserListFilterModel();
        filter.isActive = null;
        filter.roleId = null;
        filter.emailVerified = null;

        this.setState({
            from: 0,
            currentPage: 1,
            users: null,
            totalRecords: 0,
            filter,
            isFiltering: false,
        }, () => this.getUsers());
    }

    handleConfirmDeletionPressed = async () => {
        const { userToDelete } = this.state;

        if(userToDelete) {
            try {
                this.setState({
                    isLoading: true,
                    showDeleteConformation: false
                });

                await this.usersService.delete(userToDelete);

                this.setState({
                    isLoading: false,
                    userToDelete: '',
                }, () => this.getUsers())

            } catch(error) {
                this.setState({
                    isLoading: false,
                    errorMessage: error.message
                });
            }
        }
    }

    handleDeleteBtnPressed = (userId: string) => {
        this.setState({
            showDeleteConformation: true,
            userToDelete: userId
        });
    }

    render() {
        const { isLoading, errorMessage, users, totalRecords, pageSize, currentPage,
            filterIsVisible, isFiltering, showDeleteConformation } = this.state;

        return (
            <>
            <div style={{ marginBottom: 50 }}>
                {isLoading ? <Spinner /> : null}
                <Breadcrumb style={{ fontSize: '85%'}}>
                    <Breadcrumb.Item href="/">Начало</Breadcrumb.Item>
                    <Breadcrumb.Item active>
                        Потребители
                    </Breadcrumb.Item>
                </Breadcrumb>
                <div style={{ 
                    display: 'flex', 
                    flexDirection: 'row', 
                    justifyContent: 'space-between', 
                    textAlign: 'center'}}>
                    <h5>Потребители ({totalRecords})</h5>
                    <FontAwesomeIcon 
                        icon={faSearch} 
                        style={{ color: "#FF7851", cursor: 'pointer' }} 
                        size='lg' 
                        onClick={this.toggleFilter}
                    />
                </div>
                {errorMessage ? 
                  <Alert variant={'danger'}>
                  {errorMessage}
                </Alert>
                : null}

                {!filterIsVisible && isFiltering
                 ? this.renderFilterValues()
                 : null}
               
                {filterIsVisible
                 ? this.renderFilter()
                 : null}

                {users && users.length > 0 && window.innerWidth > 960
                 ? this.renderUsersAsTable()
                : null}

                {users && users.length > 0 && window.innerWidth <= 960
                 ? this.renderUsersAsList()
                : null}

                {totalRecords > 15 && !isLoading
                ? <Paginator 
                    totalRecords={totalRecords}
                    pageSize={pageSize}
                    currentPage={currentPage}
                    style={{marginTop: 10, justifyContent: 'center'}}
                    onClick={this.onChangePage} />
              : null}

                 <Modal show={showDeleteConformation} onHide={() => this.setState({ showDeleteConformation: false, userToDelete: '' })}>
                    <Modal.Header closeButton>
                    <Modal.Title>Изтриване на потребител</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Сигурни ли сте, че искате да изтриете избрания потребител?</Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={() => this.setState({ showDeleteConformation: false, userToDelete: '' })}>
                        Не
                    </Button>
                    <Button variant="danger" onClick={this.handleConfirmDeletionPressed}>
                       Да
                    </Button>
                    </Modal.Footer>
                </Modal>
            </div>
            
        </>
        )
    }
}

export default UsersListComponent;