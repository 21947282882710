import axios from 'axios';
import * as Constants from '../common/constants';
import * as HelperFunctions  from '../common/functions/helperFunctions';
import { CacheResetModel } from '../models/options/cacheResetModel';
import { RestrictedAddressAddEditModel } from '../models/options/restrictedAddressAddEditModel';
import { SystemOptionModel } from '../models/options/systemOptionModel';

class SystemOptionsService {
    // authService = new AuthService();

    // constructor() {
    //     axios.defaults.baseURL = `${Constants.apiUrl}`;
    //     axios.defaults.headers = {
    //         "Content-Type": "application/json; charset=utf-8",
    //         //Authorization: `Bearer ${this.authService.getToken()}`,
    //         Pragma: 'no-cache'
    //     }
    // }

    getAllSystemOptions() {
        return axios({
            url: `options`,
            method: 'get', headers: {
              "Content-Type": "application/json; charset=utf-8",
              //Authorization: `Bearer ${this.authService.getToken()}`,
            },
            withCredentials: true
          })
            .then(res => res.data.data)
            .catch(error => 
              Promise.reject(HelperFunctions.getErrorMessage(error))
          )
    }

    updateAllSystemOptions(options: Array<SystemOptionModel>) {
        return axios({
            url: `options`,
            method: 'post', headers: {
              "Content-Type": "application/json; charset=utf-8",
              //Authorization: `Bearer ${this.authService.getToken()}`,
            },
            data: JSON.stringify(options),
            withCredentials: true
          })
            .then(res => res.data.data)
            .catch(error => 
              Promise.reject(HelperFunctions.getErrorMessage(error))
          )
    }

    getAllRestrictedAddresses() {
      return axios({
        url: `options/restrictedAdresses`,
        method: 'get', headers: {
          "Content-Type": "application/json; charset=utf-8",
          //Authorization: `Bearer ${this.authService.getToken()}`,
        },
        withCredentials: true
      })
        .then(res => res.data.data)
        .catch(error => 
          Promise.reject(HelperFunctions.getErrorMessage(error))
      )
    }

    addRestrictedAddress(address: RestrictedAddressAddEditModel) {
      return axios({
        url: `options/restrictedAdresses`,
        method: 'post', headers: {
          "Content-Type": "application/json; charset=utf-8",
          //Authorization: `Bearer ${this.authService.getToken()}`,
        },
        data: JSON.stringify(address),
        withCredentials: true
      })
        .then(res => res.data.data)
        .catch(error => 
          Promise.reject(HelperFunctions.getErrorMessage(error))
      )
    }

    editRestrictedAddress(address: RestrictedAddressAddEditModel) {
      return axios({
        url: `options/restrictedAdresses`,
        method: 'put', headers: {
          "Content-Type": "application/json; charset=utf-8",
          //Authorization: `Bearer ${this.authService.getToken()}`,
        },
        data: JSON.stringify(address),
        withCredentials: true
      })
        .then(res => res.data.data)
        .catch(error => 
          Promise.reject(HelperFunctions.getErrorMessage(error))
      )
    }

    resetCache(cacheResetModel: CacheResetModel) {
      return axios({
        baseURL: Constants.publicApiUrl,
        url: `options/resetCache`,
        method: 'post', headers: {
          "Content-Type": "application/json; charset=utf-8",
          //Authorization: `Bearer ${this.authService.getToken()}`,
        },
        data: JSON.stringify(cacheResetModel),
        withCredentials: true
      })
        .then(res => res.data.data)
        .catch(error => 
          Promise.reject(HelperFunctions.getErrorMessage(error))
      )
    }

    getLastExecutedNotificationsTasks(){
      return axios({
        url: `notificationtasks`,
        method: 'get', headers: {
          "Content-Type": "application/json; charset=utf-8",
          //Authorization: `Bearer ${this.authService.getToken()}`,
        },
        withCredentials: true
      })
        .then(res => res.data.data)
        .catch(error => 
          Promise.reject(HelperFunctions.getErrorMessage(error))
      )
    }
}

export default SystemOptionsService;