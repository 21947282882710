import axios from 'axios';
import * as HelperFunctions  from '../common/functions/helperFunctions';

class FeedbackService {
    getAll() {
        return axios({
            url: `feedback/list`,
            method: 'get', headers: {
              "Content-Type": "application/json; charset=utf-8",
              //Authorization: `Bearer ${this.authService.getToken()}`,
            },
            withCredentials: true
          })
            .then(res => res.data.data)
            .catch(error => 
              Promise.reject(HelperFunctions.getErrorMessage(error))
          )
    }

    delete(feedbackId: string) {
      return axios({
        url: `feedback/${feedbackId}`,
        method: 'delete', headers: {
          "Content-Type": "application/json; charset=utf-8",
          //Authorization: `Bearer ${this.authService.getToken()}`,
        },
        withCredentials: true
      })
        .then(res => res.data.data)
        .catch(error => 
          Promise.reject(HelperFunctions.getErrorMessage(error))
      )
    }
}

export default FeedbackService;