import React, { useEffect, useState } from 'react';
import { Alert, Form } from 'react-bootstrap';
import PollsVotesGrid from '../../../common/components/polls/pollsVotesGrid';
import Spinner from '../../../common/components/spinner/spinner';
import { PollOptionVoteModel } from '../../../models/polls/pollOptionVoteModel';
import PollsService from '../../../services/pollsService';

const PollVotesComponent = ({poll}) => {
   const pollsService = new PollsService();
   const [isLoading, setIsLoading] = useState(false);
   const [selectedOptionId, setSelectedOptionId] = useState('');
   const [votes, setVotes] = useState<Array<PollOptionVoteModel> | null>(null);
   const [errorMessage, setErrorMessage] = useState('');

   const getVotes = async () => {
       try {
        setIsLoading(true);
        let votes = await pollsService.getPollOptionVotes(selectedOptionId);
        setVotes(votes);
        setIsLoading(false)
       } catch(error) {
           setErrorMessage(error.message);
           setIsLoading(false);
       }
   }

   useEffect(() => {
     if(poll && poll.options && poll.options.length > 0) {
        setSelectedOptionId(poll.options[0].id);
     }
   }, [poll]);

   useEffect(() => {
        if(selectedOptionId) {
            getVotes();
        }
        // eslint-disable-next-line
   }, [selectedOptionId]);

   return(
       <div>
            {isLoading ? <Spinner /> : null}
            {errorMessage
                ?   <Alert className="mt-3" variant={'danger'} style={{ fontSize: '85%'}}>
                        {errorMessage}
                    </Alert>
            : null}
            {poll 
             ? <div className='mt-2'>
                  <Form.Group controlId="statusGroup">
                        <Form.Label style={{ fontSize: '85%'}}>Опция</Form.Label>
                        <Form.Control size="sm" as="select"
                            value={selectedOptionId}
                            onChange={(e) => setSelectedOptionId(e.target['value'])}
                        >
                            {poll.options.map((option, index) => {
                                 return (<option key={index} value={option.id}>{option.content}</option>)
                            })}
                        </Form.Control>
                 </Form.Group>
                 {votes
                  ? <PollsVotesGrid votes={votes} excludeColumns={['PollQuestion', 'OptionContent']} />
                  : null
                 }
               </div>
             : null}
       </div>
    
   )
}

export default PollVotesComponent;