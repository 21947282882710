import axios from 'axios';
import * as HelperFunctions  from '../common/functions/helperFunctions';
import { AddEditStoryModel } from '../models/story/addEditStoryModel';
import StoryListFilterModel from '../models/story/storyListFilterModel';
import { StoryViewsListFilterModel } from '../models/story/storyViewsListFilterModel';

class StoriesService {
    // authService = new AuthService();

    // constructor() {
    //     axios.defaults.baseURL = `${Constants.apiUrl}`;
    //     axios.defaults.headers = {
    //         "Content-Type": "application/json; charset=utf-8",
    //         //Authorization: `Bearer ${this.authService.getToken()}`,
    //         Pragma: 'no-cache'
    //     }
    // }

    getAll(from: number, filter: StoryListFilterModel) {
        return axios({
            url: `stories/list/${from}`,
            method: 'post', headers: {
              "Content-Type": "application/json; charset=utf-8",
              //Authorization: `Bearer ${this.authService.getToken()}`,
            },
            data: JSON.stringify(filter),
            withCredentials: true
          })
            .then(res => res.data.data)
            .catch(error => 
              Promise.reject(HelperFunctions.getErrorMessage(error))
          )
    }

    getStory(storyId: string) {
      return axios({
        url: `stories/${storyId}`,
        method: 'get', headers: {
          "Content-Type": "application/json; charset=utf-8",
          //Authorization: `Bearer ${this.authService.getToken()}`,
        },
        withCredentials: true
      })
        .then(res => res.data.data)
        .catch(error => 
          Promise.reject(HelperFunctions.getErrorMessage(error))
      )
    }

    addEdit(storyAddEditModel: AddEditStoryModel) {
      return axios({
        url: `stories`,
        method: 'post', headers: {
          "Content-Type": "application/json; charset=utf-8",
          //Authorization: `Bearer ${this.authService.getToken()}`
        },
        withCredentials: true,
        data: JSON.stringify(storyAddEditModel)
      })
        .then(res => res.data.data)
        .catch(error => 
          Promise.reject(HelperFunctions.getErrorMessage(error))
      )
    }

    getByUser(userId: string) {
      return axios({
        url: `stories/user/${userId}`,
        method: 'get', headers: {
          "Content-Type": "application/json; charset=utf-8",
          //Authorization: `Bearer ${this.authService.getToken()}`
        },
        withCredentials: true
      })
        .then(res => res.data.data)
        .catch(error => 
          Promise.reject(HelperFunctions.getErrorMessage(error))
      )
    }

    getStoriesViewsByUser(userId: string) {
      return axios({
        url: `stories/views/byUser/${userId}`,
        method: 'get', headers: {
          "Content-Type": "application/json; charset=utf-8",
          //Authorization: `Bearer ${this.authService.getToken()}`
        },
        withCredentials: true
      })
        .then(res => res.data.data)
        .catch(error => 
          Promise.reject(HelperFunctions.getErrorMessage(error))
      )
    }

    getStoryViews(storyId: string) {
      return axios({
        url: `stories/views/byStory/${storyId}`,
        method: 'get', headers: {
          "Content-Type": "application/json; charset=utf-8",
          //Authorization: `Bearer ${this.authService.getToken()}`
        },
        withCredentials: true
      })
        .then(res => res.data.data)
        .catch(error => 
          Promise.reject(HelperFunctions.getErrorMessage(error))
      )
    }

    getAllStooriesViews(from: number, filter: StoryViewsListFilterModel) {
      return axios({
        url: `stories/views/${from}`,
        method: 'post', headers: {
          "Content-Type": "application/json; charset=utf-8",
          //Authorization: `Bearer ${this.authService.getToken()}`,
        },
        data: JSON.stringify(filter),
        withCredentials: true
      })
        .then(res => res.data.data)
        .catch(error => 
          Promise.reject(HelperFunctions.getErrorMessage(error))
      )
    }

    delete(storyId: string) {
      return axios({
        url: `stories/${storyId}`,
        method: 'delete', headers: {
          "Content-Type": "application/json; charset=utf-8",
          //Authorization: `Bearer ${this.authService.getToken()}`,
        },
        withCredentials: true
      })
        .then(res => res.data.data)
        .catch(error => 
          Promise.reject(HelperFunctions.getErrorMessage(error))
      )
    }

    list() {
      return axios({
        url: `stories/list`,
        method: 'get', headers: {
          "Content-Type": "application/json; charset=utf-8",
          //Authorization: `Bearer ${this.authService.getToken()}`
        },
        withCredentials: true
      })
        .then(res => res.data.data)
        .catch(error => 
          Promise.reject(HelperFunctions.getErrorMessage(error))
      )
    }

    basicSearch(searchTerm: string) {
      return axios({
        url: `stories/basic-search/${searchTerm}`,
        method: 'get', headers: {
          "Content-Type": "application/json; charset=utf-8",
          //Authorization: `Bearer ${this.authService.getToken()}`
        },
        withCredentials: true
      })
        .then(res => res.data.data)
        .catch(error => 
          Promise.reject(HelperFunctions.getErrorMessage(error))
      )
    }
}

export default StoriesService;