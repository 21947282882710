import React from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Badge from 'react-bootstrap/Badge';
import Row from 'react-bootstrap/Row';
import Alert from 'react-bootstrap/Alert';
import { DashboardModel } from '../../models/dashboard/dashboardModel';
import Spinner from '../../common/components/spinner/spinner';
import DashboardService from '../../services/dashboardService';

interface IProps {
    history: any;
    match: any;
    location: any;
}

interface IState {
    dashboardModel: DashboardModel | null,
    isLoading: boolean;
    errorMessage: string;
}

class DashboradComponent extends React.Component<IProps, IState> {
    dashboardService = new DashboardService();

    constructor(props: IProps) {
        super(props);

        this.state = {
            dashboardModel: null,
            isLoading: false,
            errorMessage: '',
        }
    }

    async componentDidMount() {
        await this.loadData();
    }

    loadData = async () => {
        try {
            this.setState({
                isLoading: true
            });

            let dashboardModel: DashboardModel = await this.dashboardService.getData();

            this.setState({
                isLoading: false,
                dashboardModel,
            })

        } catch (error) {
            this.setState({
                isLoading: false,
                errorMessage: error.message,
            })
        }
    }

    render() {
        const { isLoading, dashboardModel, errorMessage } = this.state;

        return (
            <Container>
                {isLoading ? <Spinner /> : null}
                {errorMessage
                 ?  <Alert variant={'danger'}>
                        {errorMessage}
                    </Alert>
                : null}
                {dashboardModel
                 ? <>
                  <Row style={{ marginTop: 20}}>
                        <Col md={4}  xs={12}>
                            <div style={{ borderStyle: 'solid', borderWidth: 1, borderColor: 'darksalmon', 
                                            borderRadius: 5, padding: 10, marginBottom: 10, cursor: 'pointer'}}
                                    onClick={() => this.props.history.push('/stories')}>
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                                    <h5>Истории</h5>
                                    <h4><Badge variant="info">{dashboardModel.storiesCount}</Badge></h4>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column'}}>
                                    <span>Активни: {dashboardModel.storiesActiveCount}</span>
                                    <span>Неактивни: {dashboardModel.storiesCount - dashboardModel.storiesActiveCount}</span>
                                    <span>Чакащи одобрение: {dashboardModel.storiesWaitingCount}</span>
                                </div>
                            </div>
                        </Col>
                        <Col md={4}  xs={12}>
                        <div style={{ borderStyle: 'solid', borderWidth: 1, borderColor: 'darksalmon', 
                                        borderRadius: 5, padding: 10, marginBottom: 10, cursor: 'pointer'}}
                                onClick={() => this.props.history.push('/comments')}>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                                <h5>Коментари (към истории)</h5>
                                <h4><Badge variant="info">{dashboardModel.commentsCount}</Badge></h4>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column'}}>
                                <span>Активни: {dashboardModel.commentsCount}</span>
                                <span>Неактивни: {dashboardModel.commentsCount - dashboardModel.commentsActiveCount}</span>
                                <span>Нови (за последните 24ч.): {dashboardModel.commentsNewCount}</span>
                            </div>
                        </div>
                        </Col>
                        <Col md={4} xs={12}>
                        <div style={{ borderStyle: 'solid', borderWidth: 1, borderColor: 'darksalmon',
                                        borderRadius: 5, padding: 10, marginBottom: 10, cursor: 'pointer'}}
                                onClick={() => this.props.history.push('/users')}>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                                <h5>Потребители</h5>
                                <h4><Badge variant="info">{dashboardModel.usersCount}</Badge></h4>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column'}}>
                                <span>Активни: {dashboardModel.usersActiveCount}</span>
                                <span>Неактивни: {dashboardModel.usersActiveCount - dashboardModel.usersActiveCount}</span>
                                <span>Нови (за последните 24ч.): {dashboardModel.usersNewCount}</span>
                            </div>
                        </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4}  xs={12}>
                            <div style={{ borderStyle: 'solid', borderWidth: 1, borderColor: 'darksalmon', 
                                            borderRadius: 5, padding: 10, marginBottom: 10, cursor: 'pointer'}}
                                    onClick={() => this.props.history.push('/polls')}>
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                                    <h5>Въпроси</h5>
                                    <h4><Badge variant="info">{dashboardModel.pollsCount}</Badge></h4>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column'}}>
                                    <span>Активни: {dashboardModel.pollsActiveCount}</span>
                                    <span>Неактивни: {dashboardModel.pollsCount - dashboardModel.pollsCount}</span>
                                    <span>Нови (за последните 24ч.): {dashboardModel.pollsNewCount}</span>
                                </div>
                            </div>
                        </Col>
                        <Col md={4}  xs={12}>
                            <div style={{ borderStyle: 'solid', borderWidth: 1, borderColor: 'darksalmon', 
                                            borderRadius: 5, padding: 10, marginBottom: 10, cursor: 'pointer'}}
                                    onClick={() => this.props.history.push('/pollscomments')}>
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                                    <h5>Коментари (към въпроси)</h5>
                                    <h4><Badge variant="info">{dashboardModel.pollCommentsCount}</Badge></h4>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column'}}>
                                    <span>Активни: {dashboardModel.pollCommentsActiveCount}</span>
                                    <span>Неактивни: {dashboardModel.pollCommentsCount - dashboardModel.pollCommentsActiveCount}</span>
                                    <span>Нови (за последните 24ч.): {dashboardModel.pollCommentsNewCount}</span>
                                </div>
                            </div>
                        </Col>
                    </Row>
                 </>
               : null}
                
             </Container>
            )
    }
}

export default DashboradComponent;