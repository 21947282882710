import axios from 'axios';
import * as HelperFunctions  from '../common/functions/helperFunctions';
import { NotificationSubscriptionModel } from '../models/user/notificationSubscriptionModel';
import { UserChangePasswordModel } from '../models/user/userChangePasswordModel';
import { UserEditModel } from '../models/user/userEditModel';
import { UserListFilterModel } from '../models/user/userListFilterModel';
import { UserLoginsListFilterModel } from '../models/user/userLoginsListFilterModel';

class UsersService {
    // authService = new AuthService();

    // constructor() {
    //     axios.defaults.baseURL = `${Constants.apiUrl}`;
    //     axios.defaults.headers = {
    //         "Content-Type": "application/json; charset=utf-8",
    //         //Authorization: `Bearer ${this.authService.getToken()}`,
    //         Pragma: 'no-cache'
    //     }
    // }

    getAllActive() {
        return axios({
            url: `users/listActive`,
            method: 'get', headers: {
              "Content-Type": "application/json; charset=utf-8",
              //Authorization: `Bearer ${this.authService.getToken()}`,
            },
            withCredentials: true
          })
            .then(res => res.data.data)
            .catch(error => 
              Promise.reject(HelperFunctions.getErrorMessage(error))
          )
    }

    getAll(from: number, filter: UserListFilterModel) {
      return axios({
          url: `users/list/${from}`,
          method: 'post', headers: {
            "Content-Type": "application/json; charset=utf-8",
            //Authorization: `Bearer ${this.authService.getToken()}`,
          },
          withCredentials: true,
          data: JSON.stringify(filter)
        })
          .then(res => res.data.data)
          .catch(error => 
            Promise.reject(HelperFunctions.getErrorMessage(error))
        )
  }

  getUser(userId: string) {
    return axios({
      url: `users/${userId}`,
      method: 'get', headers: {
        "Content-Type": "application/json; charset=utf-8",
        //Authorization: `Bearer ${this.authService.getToken()}`,
      },
      withCredentials: true
    })
      .then(res => res.data.data)
      .catch(error => 
        Promise.reject(HelperFunctions.getErrorMessage(error))
    )
  }

  updateProfile(userProfile: UserEditModel) {
    return axios({
      url: `users`,
      method: 'put', headers: {
        "Content-Type": "application/json; charset=utf-8",
        //Authorization: `Bearer ${this.authService.getToken()}`,
      },
      data: JSON.stringify(userProfile),
      withCredentials: true
    })
      .then(res => res.data.data)
      .catch(error => 
        Promise.reject(HelperFunctions.getErrorMessage(error))
    )
  }

  changePassword(changePasswordModel: UserChangePasswordModel) {
    return axios({
      url: `users/changePassword`,
      method: 'put', headers: {
        "Content-Type": "application/json; charset=utf-8",
        //Authorization: `Bearer ${this.authService.getToken()}`,
      },
      data: JSON.stringify(changePasswordModel),
      withCredentials: true
    })
      .then(res => res.data.data)
      .catch(error => 
        Promise.reject(HelperFunctions.getErrorMessage(error))
    )
  }

  getUserLogins(userId: string) {
    return axios({
      url: `users/logins/byUser/${userId}`,
      method: 'get', headers: {
        "Content-Type": "application/json; charset=utf-8",
        //Authorization: `Bearer ${this.authService.getToken()}`,
      },
      withCredentials: true
    })
      .then(res => res.data.data)
      .catch(error => 
        Promise.reject(HelperFunctions.getErrorMessage(error))
    )
  }

  getLogins(from: number, filter: UserLoginsListFilterModel) {
    return axios({
      url: `users/logins/${from}`,
      method: 'post', headers: {
        "Content-Type": "application/json; charset=utf-8",
        //Authorization: `Bearer ${this.authService.getToken()}`,
      },
      data: JSON.stringify(filter),
      withCredentials: true
    })
      .then(res => res.data.data)
      .catch(error => 
        Promise.reject(HelperFunctions.getErrorMessage(error))
    )
  }

  addNotificationSubscription(userId: string, subscription: NotificationSubscriptionModel) {
    return axios({
      url: `users/addNotificationSubscription/${userId}`,
      method: 'post', headers: {
        "Content-Type": "application/json; charset=utf-8",
        //Authorization: `Bearer ${this.authService.getToken()}`
      },
      data: JSON.stringify(subscription),
      withCredentials: true
    })
      .then(res => res.data.data)
      .catch(error => 
        Promise.reject(HelperFunctions.getErrorMessage(error))
    )
  }

  removeNotificationSubscription(userId: string, subscription: NotificationSubscriptionModel) {
    return axios({
      url: `users/removeNotificationSubscription/${userId}`,
      method: 'delete', headers: {
        "Content-Type": "application/json; charset=utf-8",
        //Authorization: `Bearer ${this.authService.getToken()}`
      },
      data: JSON.stringify(subscription),
      withCredentials: true
    })
      .then(res => res.data.data)
      .catch(error => 
        Promise.reject(HelperFunctions.getErrorMessage(error))
    )
  }

  delete(userId: string) {
    return axios({
      url: `users/${userId}`,
      method: 'delete', headers: {
        "Content-Type": "application/json; charset=utf-8",
        //Authorization: `Bearer ${this.authService.getToken()}`,
      },
      withCredentials: true
    })
      .then(res => res.data.data)
      .catch(error => 
        Promise.reject(HelperFunctions.getErrorMessage(error))
    )
  }
}

export default UsersService;