import React, { Component } from 'react';
import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';
import { dateToLocal } from '../../functions/helperFunctions';
import { UserLoginModel } from '../../../models/user/userLoginModel';

interface IProps {
    logins: Array<UserLoginModel> | null;
    excludeColumns: Array<string>;
}

interface IState {
    totalPages: number;
}

class UserLoginsGrid extends Component<IProps, IState> {
    public static defaultProps = {
        excludeColumns: null
    };

    // constructor(props: IProps) {
    //     super(props);
    // }

    renderAsList = () => {
        const { logins, excludeColumns } = this.props;

        return(
            <div>
                {logins?.map((login, index) => {
                    return(
                        <Card key={index} style={{marginBottom: 10}}>
                            <Card.Body>
                                <div style={{ display: 'flex', flexDirection: 'column', fontSize: '85%'}}>
                                    {!excludeColumns || !excludeColumns.find(c => c === 'User')
                                     ?  <span>Потребител: {login.userName}</span>
                                     : null}
                                    {!excludeColumns || !excludeColumns.find(c => c === 'Date')
                                     ?   <span>Дата: {dateToLocal(login.date)}</span>
                                     : null}
                                    {!excludeColumns || !excludeColumns.find(c => c === 'IPAddress')
                                     ?   <span>IP Адрес: {login.ipAddress}</span>
                                     : null}
                                </div>
                               
                            </Card.Body>
                    </Card>
                    )
                })}
            </div>
        )
    }

    renderAsTable = () => {
        const { logins, excludeColumns } = this.props;

        return (
            <Table striped bordered hover variant="light" responsive style={{ fontSize: '85%'}}>
                    <thead>
                    <tr>
                        {!excludeColumns || !excludeColumns.find(c => c === 'User')
                            ? <th>Потребител</th>
                            : null}
                        {!excludeColumns || !excludeColumns.find(c => c === 'Date')
                            ? <th>Дата</th>
                            : null}
                        {!excludeColumns || !excludeColumns.find(c => c === 'IPAdress')
                            ? <th>IP Адрес</th>
                            : null}
                    </tr>
                    </thead>
                    <tbody>
                        {logins?.map((login, index) => {
                            return (
                            <tr key={index}>
                                {!excludeColumns || !excludeColumns.find(c => c === 'User')
                                    ? <td>{login.userName}</td>
                                    : null}
                                {!excludeColumns || !excludeColumns.find(c => c === 'Date')
                                    ? <td>{dateToLocal(login.date)}</td>
                                    : null}
                                {!excludeColumns || !excludeColumns.find(c => c === 'IPAddress')
                                    ?  <td>{login.ipAddress}</td>
                                    : null}
                            </tr>
                            )
                        })}
                    </tbody>
            </Table> 
        )
    }

    render() {
        const { logins } = this.props;

        return (
            <>
                {logins && logins.length > 0 && window.innerWidth > 960
                ? this.renderAsTable()
               : null}

                {logins && logins.length > 0 && window.innerWidth <= 960
                ? this.renderAsList()
                : null}
            </>
        )
    }
}

export default UserLoginsGrid;