import React from 'react';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import Spinner from '../../common/components/spinner/spinner';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import CommentsService from '../../services/commentsService';
import { CommentModel } from '../../models/comment/commentModel';
import { CommentsListModel } from '../../models/comment/commentsListModel';
import Card from 'react-bootstrap/Card';
import Paginator from '../../common/components/paginator/paginator';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CommentsListFilterModel } from '../../models/comment/commentsListFilterModel';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { CommentStatus } from '../../common/enums';
import CommentsGrid from '../../common/components/comment/commentsGrid';

interface IProps {
    history: any;
    match: any;
}

interface IState {
    touched: boolean;
    isLoading: boolean;
    errorMessage: string;
    storyId: string;
    from: number;
    currentPage: number;
    pageSize: number;
    comments: Array<CommentModel> | null;
    totalRecords: number;
    storyTitle: string;
    filterIsVisible: boolean;
    filter: CommentsListFilterModel;
    isFiltering: boolean;
}

class CommentsListComponent extends React.Component<IProps, IState> {
    commentsService = new CommentsService();

    constructor(props: IProps) {
        super(props);

        let storyId = this.props.match.params.storyId;
        let currentPage = +this.props.match.params.page;

        if(!currentPage) {
            currentPage = 1
        }

        let pageSize = 15;
        let from = (currentPage - 1) * pageSize;

        this.state = {
            touched: false,
            isLoading: false,
            errorMessage: '',
            from,
            currentPage,
            pageSize,
            comments: null,
            totalRecords: 0,

            storyId,
            storyTitle: '',

            filterIsVisible: false,
            filter: new CommentsListFilterModel(),
            isFiltering: false,
        }
    }

    async componentDidMount() {
        await this.getComments();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let nextPage = this.props.match.params.page;
        let prevPage = prevProps.match.params.page;

        if(prevPage !== nextPage) {
            if(!nextPage) {
                nextPage = 1;
            }

            let from = (nextPage - 1) * this.state.pageSize;

            this.setState({
                from,
                currentPage: +nextPage
            }, () => this.getComments());
        }
    }

    getComments = async () => {
        const { storyId, from, filter } = this.state;

        try {
            this.setState({
                isLoading: true,
                comments: new Array<CommentModel>(),
            });

            let commentsListModel: CommentsListModel;

            if(!storyId) {
                commentsListModel = await this.commentsService.getAll(from, filter);
            } else {
                commentsListModel = await this.commentsService.getByStory(storyId, from, filter);
            }

            if(commentsListModel) {
                this.setState({
                    isLoading: false,
                    comments: commentsListModel.comments ? commentsListModel.comments : new Array<CommentModel>(),
                    totalRecords: commentsListModel.count,
                    storyTitle: commentsListModel.storyTitle,
                })
            } else {
                this.setState({
                    isLoading: false,
                    comments: new Array<CommentModel>(),
                    totalRecords: 0,
                    storyTitle: '',
                })
            }
        } catch (error) {
            this.setState({
                isLoading: false,
                errorMessage: error.message
            });
        }
    }

    handleChange = (key: any, value: any) => {
        const filter: CommentsListFilterModel = { ...this.state.filter };

        filter[key] = value;

        this.setState({
            filter
        });
    }

    renderBreadcrumb = () => {
        const { storyId, storyTitle } = this.state;

        return (
            <Breadcrumb style={{ fontSize: '85%'}}>
                    <Breadcrumb.Item href="/">Начало</Breadcrumb.Item>
                    {storyId 
                     ?  <>
                            <Breadcrumb.Item href="/stories">
                                Истории
                            </Breadcrumb.Item>
                            {storyTitle
                            ?  <Breadcrumb.Item href={`/add-edit-story/${storyId}`}>
                                    {storyTitle}
                                </Breadcrumb.Item>
                            : null}
                            <Breadcrumb.Item active>
                                Коментари
                            </Breadcrumb.Item>
                        </> 
                    : null}
                   {!storyId
                    ? <>
                        <Breadcrumb.Item active>
                                Коментари
                        </Breadcrumb.Item>
                    </>
                    : null}
                </Breadcrumb>
        );
    }

    onChangePage = (page) => {
        const { storyId } = this.state;

        if(storyId)
            this.props.history.push(`/story-comments/${storyId}/${page}`);
        else
            this.props.history.push(`/comments/${page}`);
    }

    toggleFilter = () => {
        let filterIsVisible = this.state.filterIsVisible;
        this.setState({
            filterIsVisible: !filterIsVisible
        });
    }

    handleOnFilterPressed = () => {
        const { storyId, filter } = this.state;

        if(storyId) {
            this.props.history.push(`/story-comments/${storyId}`);
        } else {
            this.props.history.push(`/comments`);
        }

        let isFiltering = false;

        if(filter) {
            if(filter.content) { isFiltering = true }
            if(filter.ipAddress) { isFiltering = true }
            if(filter.user) { isFiltering = true }
            if(filter.statusId && filter.statusId !== 0) { isFiltering = true }
        }

        this.setState({
            from: 0,
            currentPage: 1,
            comments: null,
            totalRecords: 0,
            isFiltering,
            filterIsVisible: false,
        }, async () => await this.getComments());
    }

    handleOnClearFilterPressed = () => {
        const { storyId } = this.state;

        if(storyId) {
            this.props.history.replace(`/story-comments/${storyId}`)
        } else {
            this.props.history.replace('/comments');
        }

        let filter = new CommentsListFilterModel();

        this.setState({
            from: 0,
            currentPage: 1,
            comments: null,
            totalRecords: 0,
            filter,
            isFiltering: false,
        }, async () => await this.getComments());
    }

    renderFilter = () => {
        const { filter } = this.state;

        return (
            <Card style={{marginBottom: 10, marginTop: 10}}>
                <Card.Body>
                    <h6>Филтър</h6>
                    <Form>
                        <Form.Group as={Row} controlId="statusGroup">
                                <Form.Label column sm="2" style={{ fontSize: '85%'}}>Статус</Form.Label>
                                <Col sm="10">
                                    <Form.Control size="sm" as="select"
                                        value={filter && filter.statusId ? filter.statusId : 0}
                                        onChange={(e) => this.handleChange('statusId', +e.target['value'])}
                                    >
                                        <option value={0}>--- изберете статус ---</option>
                                        <option value={CommentStatus.Active}>Активен</option>
                                        <option value={CommentStatus.StoppedByAdmin}>Спрян / неактивен</option>
                                        <option value={CommentStatus.DeletedByUser}>Спрян / изтрит потребителски профил</option>
                                    </Form.Control>
                                </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="contentSearch">
                            <Form.Label column sm="2" style={{ fontSize: '85%'}}>Съдържание</Form.Label>
                            <Col sm="10">
                                <Form.Control 
                                    type="text" 
                                    size="sm" 
                                    placeholder="Търсене в съдържание ..." 
                                    onChange={(e) => this.handleChange('content', e.target['value'])}
                                    value={filter.content ? filter.content : ''}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="userSearch">
                            <Form.Label column sm="2" style={{ fontSize: '85%'}}>Потребител</Form.Label>
                            <Col sm="10">
                                <Form.Control 
                                    type="text" 
                                    size="sm" 
                                    placeholder="Потребителско име" 
                                    onChange={(e) => this.handleChange('user', e.target['value'])}
                                    value={filter.user ? filter.user : ''}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="ipAddressSearch">
                            <Form.Label column sm="2" style={{ fontSize: '85%'}}>IP Адрес</Form.Label>
                            <Col sm="10">
                                <Form.Control 
                                    type="text" 
                                    size="sm" 
                                    placeholder="Търсене по IP Адрес" 
                                    onChange={(e) => this.handleChange('ipAddress', e.target['value'])}
                                    value={filter.ipAddress ? filter.ipAddress : ''}
                                />
                            </Col>
                        </Form.Group>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
                            <div style={{ display: 'flex', flexDirection: 'row'}}>
                                <Button size="sm" variant="primary" onClick={this.handleOnFilterPressed}>Търси</Button>
                                <Button size="sm" variant="danger" onClick={this.handleOnClearFilterPressed} style={{ marginLeft: 10}}>Изчисти</Button>
                            </div>
                        </div>
                    </Form>
                </Card.Body>
            </Card>
        );
    }

    renderFilterValues = () => {
        const { filter } = this.state;

        console.log(filter);

        return (
            <div style={{ fontSize: '85%', marginBottom: 15}}>
                {filter.content
                 ? <div>
                     Съдържание: <span style={{ fontWeight: 'bold'}}>{filter.content}</span> 
                   </div>
                : null}
                 {filter.user
                 ? <div>
                     Потребител: <span style={{ fontWeight: 'bold'}}>{filter.user}</span> 
                   </div>
                : null}
                 {filter.statusId && filter.statusId !== 0
                 ? <div>
                    Статус: {this.getCommentStatus(filter.statusId)}
                   </div>
                : null}
                 {filter.ipAddress
                 ? <div>
                    IP Адрес: <span style={{ fontWeight: 'bold'}}>{filter.ipAddress}</span> 
                   </div>
                : null}
                 <Button 
                    variant="danger" 
                    onClick={this.handleOnClearFilterPressed}
                    size="sm"
                    style={{ marginTop: 5}}>
                        Изчисти
                </Button>
            </div>
        )
    }

    getCommentStatus = (commentStatusId: number) => {
        switch(commentStatusId) {
            case CommentStatus.Active :
                return (<span style={{ color: '#28B463'}}>Активен</span>);
            case CommentStatus.StoppedByAdmin :
                return (<span style={{ color: '#E74C3C'}}>Спрян / неактивен</span>);
            case CommentStatus.DeletedByUser :
                return (<span style={{ color: '#6E2C00'}}>Спрян / изтрит потребителски профил</span>);
        }
    }

    deleteComment = async (commentId: string) => {
        if(commentId) {
            try {
                this.setState({
                    isLoading: true,
                    errorMessage: '',
                });

                await this.commentsService.delete(commentId);

                this.setState({
                    isLoading: false
                }, () => this.getComments())
            } catch(error) {
                this.setState({
                    isLoading: false,
                    errorMessage: error.message
                });
            }
        }
    }

    render() {
        const { isLoading, errorMessage, comments, totalRecords, pageSize, 
                currentPage, filterIsVisible, isFiltering } = this.state;

        return (
            <>
            <div style={{ marginBottom: 50 }}>
                {isLoading ? <Spinner /> : null}

                {!isLoading 
                 ? <>
                    {this.renderBreadcrumb()}
                    <div style={{ 
                        display: 'flex', 
                        flexDirection: 'row', 
                        justifyContent: 'space-between', 
                        textAlign: 'center'}}>
                        <h5>Коментари ({totalRecords})</h5>
                        <FontAwesomeIcon 
                            icon={faSearch} 
                            style={{ color: "#FF7851", cursor: 'pointer' }} 
                            size='lg' 
                            onClick={this.toggleFilter}
                        />
                    </div>
                   </>
                 : null}

                {errorMessage ? 
                  <Alert variant={'danger'}>
                  {errorMessage}
                </Alert>
                : null}

                {!filterIsVisible && isFiltering
                 ? this.renderFilterValues()
                 : null}

                {filterIsVisible
                 ? this.renderFilter()
                 : null}

                {!isLoading && !errorMessage && comments && totalRecords === 0
                 ?  <Alert variant={'warning'}>
                        Няма намерени коментари
                    </Alert>
                : null}

                <CommentsGrid 
                    comments={comments}
                    history={this.props.history}
                    onDeleteComment={this.deleteComment}
                />

                {totalRecords > 15 && !isLoading
                    ? <Paginator 
                        totalRecords={totalRecords}
                        pageSize={pageSize}
                        currentPage={currentPage}
                        style={{marginTop: 30, justifyContent: 'center'}}
                        onClick={this.onChangePage} />
                : null}
               
            </div>
            
        </>
        )
    }
}

export default CommentsListComponent;