import React from 'react';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import AuthService from '../../services/authService';
import { withRouter } from "react-router-dom";
import NavDropdown from 'react-bootstrap/NavDropdown';
import Cookies from 'universal-cookie';

interface IProps {
    history: any;
    match: any;
    location: any;
}

interface IState {
}

class HeaderComponent extends React.Component<IProps, IState> {
    authService = new AuthService();
    cookies = new Cookies();
    // constructor(props: IProps) {
    //     super(props);
    // }

    logout = async (e) => {
        e.preventDefault();

        try {
            await this.authService.serverLogOut();
            this.cookies.remove('X-Login-Details');
            window.location.href = '/login';
        } catch {
            this.cookies.remove('X-Login-Details');
            window.location.href = '/login';
        }
    }

    render() {
        const isAuth = this.authService.isAuthenticated();

        return (
            <Navbar variant="dark" bg="primary" expand="lg" fixed="top" >
                <Container>
                <Navbar.Brand href="/">Story App Admin Panel</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto">
                        {isAuth
                         ? <>
                            <NavDropdown title="Истории" id="basic-nav-dropdown" >
                                <NavDropdown.Item href="/add-edit-story">Добавяне</NavDropdown.Item>
                                <NavDropdown.Item href="/stories">Списък</NavDropdown.Item>
                                <NavDropdown.Item href="/comments">Коментари</NavDropdown.Item>
                                <NavDropdown.Item href="/stories-views">Прегледи</NavDropdown.Item>
                                <NavDropdown.Item href="/categories">Категории</NavDropdown.Item>
                            </NavDropdown>
                            <NavDropdown title="Въпроси" id="basic-nav-dropdown" >
                                <NavDropdown.Item href="/polls">Списък</NavDropdown.Item>
                                <NavDropdown.Item href="/pollscomments">Коментари</NavDropdown.Item>
                                <NavDropdown.Item href="/polls-views">Прегледи</NavDropdown.Item>
                                <NavDropdown.Item href="/polls-votes">Гласувания</NavDropdown.Item>
                            </NavDropdown>
                            <NavDropdown title="Потребители" id="basic-nav-dropdown" >
                                <NavDropdown.Item href="/users">Списък</NavDropdown.Item>
                                <NavDropdown.Item href="/logins">Влизания</NavDropdown.Item>
                            </NavDropdown>
                            <Nav.Link href="/feedback">Feedback</Nav.Link>
                            <Nav.Link href="/system-options">Настройки</Nav.Link>
                         </>
                         : null}
                        
                    </Nav>
                    <Nav className="justify-content-end">
                        {isAuth 
                            ? <>
                                <Nav.Link disabled style={{ color: 'wheat'}}>Потребител: {this.authService.getLoginName()}</Nav.Link>
                                <Nav.Link href="#" onClick={this.logout}>Изход</Nav.Link>
                              </>
                            : null}
                       
                    </Nav>
                </Navbar.Collapse>
                </Container>
            </Navbar>
        )
    }
}

export default withRouter(HeaderComponent);