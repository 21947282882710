import React, { CSSProperties } from 'react';
import Alert from 'react-bootstrap/Alert';
import Spinner from '../../common/components/spinner/spinner';
import SystemOptionsService from '../../services/systemOptionsService';
import Card from 'react-bootstrap/esm/Card';
import Table from 'react-bootstrap/Table';
import { dateToLocal } from '../../common/functions/helperFunctions';
import { RestrictedAddressAddEditModel } from '../../models/options/restrictedAddressAddEditModel';
import { NotificationTaskModel } from '../../models/options/notificationTaskModel';

interface IProps {
    style: CSSProperties | undefined;
}

interface IState {
    notificationsTasks: Array<NotificationTaskModel> | null,
    isLoading: boolean;
    errorMessage: string;
}

class NotificationsTasksComponent extends React.Component<IProps, IState> {
    systemOptionsService = new SystemOptionsService();

    constructor(props: IProps) {
        super(props);

        let currentRestrictedAddress = new RestrictedAddressAddEditModel();
        currentRestrictedAddress.isActive = true;

        this.state = {
            notificationsTasks: null,
            isLoading: false,
            errorMessage: '',
        }
    }

    async componentDidMount() {
        await this.getAll();
    }

    getAll = async () => {
        try {
            this.setState({
                isLoading: true
            });

            let notificationsTasks: Array<NotificationTaskModel> = await this.systemOptionsService.getLastExecutedNotificationsTasks();

            if(!notificationsTasks) {
                notificationsTasks = new Array<NotificationTaskModel>();
            }

            this.setState({
                isLoading: false,
                notificationsTasks,
            })

        } catch (error) {
            this.setState({
                isLoading: false,
                errorMessage: error.message,
            });
        }
    }

    renderAsList = () => {
        const { notificationsTasks } = this.state;

        return (
            <div style={{ marginTop: 15}}>
            {notificationsTasks?.map((task, index) => {
                return(
                    <div key={index} style={{ display: 'flex', flexDirection: 'column', fontSize: '85%'}}>
                        <span>Изпълнен на: {dateToLocal(task.executedAt)}</span>
                        <span>Бр. изпратени имейли: {task.emailsSent}</span>
                        <span>Нови потребители: {task.newUsers}</span>
                        <span>Нови истории: {task.newStories}</span>
                        <span>Нови коментари: {task.newComments}</span>
                        <span>Нови feedbacks: {task.newFeedbacks}</span>
                        <span>Одобрени истории: {task.approvedStories}</span>
                        <span>Грешки: {task.error}</span>
                        {index < notificationsTasks.length - 1
                         ?  <hr
                                style={{
                                    color: 'red',
                                    backgroundColor: '#e9ecef',
                                    height: 0.5,
                                    width: '100%',
                                    marginTop: 8,
                                    marginBottom: 8,
                                }}
                            />
                        : null}
                    </div>
                )
            })}
        </div>
        );
    }

    renderAsTable = () => {
        const { notificationsTasks } = this.state;

        return (
            <Table striped bordered hover variant="light" responsive style={{ fontSize: '85%'}}>
            <thead>
            <tr>
                <th>Изпълнен на</th>
                <th style={{ textAlign: 'center'}}>Бр. изпратени имейли</th>
                <th style={{ textAlign: 'center'}}>Нови потребители</th>
                <th style={{ textAlign: 'center'}}>Нови истории</th>
                <th style={{ textAlign: 'center'}}>Нови коментари</th>
                <th style={{ textAlign: 'center'}}>Нови feedbacks</th>
                <th style={{ textAlign: 'center'}}>Одобрени истории</th>
                <th>Грешки</th>
            </tr>
            </thead>
            <tbody>
                {notificationsTasks?.map((task, index) => {
                    return (
                    <tr key={index}>
                        <td>{dateToLocal(task.executedAt)}</td>
                        <td style={{ textAlign: 'center'}}>{task.emailsSent}</td>
                        <td style={{ textAlign: 'center'}}>{task.newUsers}</td>
                        <td style={{ textAlign: 'center'}}>{task.newStories}</td>
                        <td style={{ textAlign: 'center'}}>{task.newComments}</td>
                        <td style={{ textAlign: 'center'}}>{task.newFeedbacks}</td>
                        <td style={{ textAlign: 'center'}}>{task.approvedStories}</td>
                        <td>{task.error}</td>
                    </tr>
                    )
                })}
            </tbody>
    </Table> 
        )
    }

    render() {
        const { isLoading, notificationsTasks, errorMessage } = this.state;

        return (
            <>
            <Card style={ this.props.style ? this.props.style : {}} hidden={notificationsTasks === null}>
                {isLoading ? <Spinner /> : null}
                <Card.Body>
                    <h6>Изпълнение на известия</h6>
                    {errorMessage
                    ?  <Alert style={{ fontSize: '85%'}} variant={'danger'}>
                            {errorMessage}
                        </Alert>
                    : null}
                    {notificationsTasks && notificationsTasks.length > 0
                     ? window.innerWidth > 960 ? this.renderAsTable() : this.renderAsList()
                     : null}
                </Card.Body>
             </Card>
            </>
         )
    }
}

export default NotificationsTasksComponent;