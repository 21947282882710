import axios from 'axios';
import * as HelperFunctions  from '../common/functions/helperFunctions';
import { CommentEditModel } from '../models/comment/commentEditModel';
import { CommentsListFilterModel } from '../models/comment/commentsListFilterModel';

class CommentsService {
    // authService = new AuthService();

    // constructor() {
    //     axios.defaults.baseURL = `${Constants.apiUrl}`;
    //     axios.defaults.headers = {
    //         "Content-Type": "application/json; charset=utf-8",
    //         //Authorization: `Bearer ${this.authService.getToken()}`,
    //         Pragma: 'no-cache'
    //     }
    // }

    getByStory(storyId: string, from: number, filter: CommentsListFilterModel) {
        return axios({
            url: `comments/list/byStory/${storyId}/${from}`,
            method: 'post', headers: {
              "Content-Type": "application/json; charset=utf-8",
              //Authorization: `Bearer ${this.authService.getToken()}`,
            },
            data: JSON.stringify(filter),
            withCredentials: true
          })
            .then(res => res.data.data)
            .catch(error => 
              Promise.reject(HelperFunctions.getErrorMessage(error))
          )
    }

    getAllByStory(storyId: string) {
      return axios({
          url: `comments/list/byStory/${storyId}`,
          method: 'get', headers: {
            "Content-Type": "application/json; charset=utf-8",
            //Authorization: `Bearer ${this.authService.getToken()}`,
          },
          withCredentials: true
        })
          .then(res => res.data.data)
          .catch(error => 
            Promise.reject(HelperFunctions.getErrorMessage(error))
        )
  }

    getAll(from: number, filter: CommentsListFilterModel) {
      return axios({
          url: `comments/list/${from}`,
          method: 'post', headers: {
            "Content-Type": "application/json; charset=utf-8",
            //Authorization: `Bearer ${this.authService.getToken()}`,
          },
          data: JSON.stringify(filter),
          withCredentials: true
        })
          .then(res => res.data.data)
          .catch(error => 
            Promise.reject(HelperFunctions.getErrorMessage(error))
        )
  }

  getComment(id: string) {
    return axios({
        url: `comments/${id}`,
        method: 'get', headers: {
          "Content-Type": "application/json; charset=utf-8",
          //Authorization: `Bearer ${this.authService.getToken()}`,
        },
        withCredentials: true
      })
        .then(res => res.data.data)
        .catch(error => 
          Promise.reject(HelperFunctions.getErrorMessage(error))
      )
  }

  editComment(comment: CommentEditModel) {
    return axios({
      url: `comments`,
      method: 'put', headers: {
        "Content-Type": "application/json; charset=utf-8",
        //Authorization: `Bearer ${this.authService.getToken()}`,
      },
      data: JSON.stringify(comment),
      withCredentials: true
    })
      .then(res => res.data.data)
      .catch(error => 
        Promise.reject(HelperFunctions.getErrorMessage(error))
    )
  }

  getByUser(userId: string) {
    return axios({
      url: `comments/list/byUser/${userId}`,
      method: 'get', headers: {
        "Content-Type": "application/json; charset=utf-8",
        //Authorization: `Bearer ${this.authService.getToken()}`,
      },
      withCredentials: true
    })
      .then(res => res.data.data)
      .catch(error => 
        Promise.reject(HelperFunctions.getErrorMessage(error))
    )
  }

  delete(commentId: string) {
    return axios({
      url: `comments/${commentId}`,
      method: 'delete', headers: {
        "Content-Type": "application/json; charset=utf-8",
        //Authorization: `Bearer ${this.authService.getToken()}`,
      },
      withCredentials: true
    })
      .then(res => res.data.data)
      .catch(error => 
        Promise.reject(HelperFunctions.getErrorMessage(error))
    )
  }
}

export default CommentsService;