import getApiError from './getApiError';
import { ApiErrorModel } from '../models/ApiErrorModel';
import moment from 'moment';

export function validateEmail(email) {
    // eslint-disable-next-line
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

export function getErrorMessage(error) {
  let apiError = new ApiErrorModel();
  if (error.response) {
    if (
      error.response.data &&
      error.response.data.Data &&
      error.response.data.Data.ErrorCode
    ) {
      return getApiError(`${error.response.data.Data.ErrorCode}`);
    }

    if (
      error.response.data &&
      error.response.data.Data &&
      error.response.data.Data.Message
    ) {
      apiError.message = `${error.response.data.Data.Message}`;
      return apiError;
    }

    if (error.response.data) {
      return getApiError(`${error.response.data}`);
    }

    apiError.message = `${error.response.status} ${error.response.statusText}`;
    return apiError;
  } else {
    return getApiError(`${error.message}`);
  }
}

export function formatDateAsString(date: Date) {
  return '' +
    (date.getDate() < 10 ? '0' + (date.getDate()).toString() : (date.getDate()).toString()) + '.' +
    (date.getMonth() < 9 ? '0' + (date.getMonth() + 1).toString() : (date.getMonth() + 1).toString()) + '.' +
    date.getFullYear().toString();
}

export function fomatDateTimeAsString(date: Date) {
  return (date.getDate() < 10 ? '0' + (date.getDate()).toString() : (date.getDate()).toString()) + "." +
    (date.getMonth() < 9 ? '0' + (date.getMonth() + 1).toString() : (date.getMonth() + 1).toString()) + '.' +
    date.getFullYear().toString() + ' ' +
    ("0" + date.getHours()).slice(-2) + ":" + ("0" + date.getMinutes()).slice(-2) + ":" + ("0" + date.getSeconds()).slice(-2);
}


export function dateToLocal(date: string) {
  let dateUtc = moment(date);
  let offset = moment().utcOffset();
  let local = moment.utc(dateUtc).utcOffset(offset).format('DD.MM.YYYY HH:mm');

  return local;
}