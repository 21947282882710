import apiErrors from '../../assets/apiErrors';
import { ApiErrorModel } from '../models/ApiErrorModel';

function getApiError(key) {
    if(typeof key === 'string') {
        let apiErrorModel = new ApiErrorModel();
        apiErrorModel.errorCode = key;
        apiErrorModel.message = apiErrors[key] ? apiErrors[key] : key;
        return apiErrorModel;
    } else {
        return key;
    }
}

export default getApiError;