import React from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import { InputValidationModel } from '../../common/models/inputValidationModel';
import Spinner from '../../common/components/spinner/spinner';
import AuthService from '../../services/authService';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { CategorySimpleModel } from '../../models/category/categorySimpleModel';
import CategoriesService from '../../services/categoriesService';
import Row from 'react-bootstrap/Row';
import { StoryStatus } from '../../common/enums';
import Col from 'react-bootstrap/Col';
import FormGroup from 'react-bootstrap/esm/FormGroup';
import { AddEditStoryModel } from '../../models/story/addEditStoryModel';
import StoriesService from '../../services/storiesService';
import { dateToLocal } from '../../common/functions/helperFunctions';
import { UserBasicModel } from '../../models/user/userBasicModel';
import UsersService from '../../services/usersService';
import Tabs from 'react-bootstrap/esm/Tabs';
import Tab from 'react-bootstrap/esm/Tab';
import { CommentModel } from '../../models/comment/commentModel';
import CommentsService from '../../services/commentsService';
import CommentsGrid from '../../common/components/comment/commentsGrid';
import { StoryViewModel } from '../../models/story/storyViewModel';
import StoriesViewsGrid from '../../common/components/story/storiesViewsGrid';
import SelectParentStoryModal from './selectParentStoryModal';

interface IProps {
    history: any;
    match: any;
}

interface IState {
    validations: Array<InputValidationModel>;
    touched: boolean;
    isLoading: boolean;
    errorMessage: string;
    successMessage: string;
    categories: Array<CategorySimpleModel>;
    users: Array<UserBasicModel>;
    storyId: string;
    story: AddEditStoryModel;
    selectedTab: string;
    comments: Array<CommentModel>,
    commentsErrorMessage: string,
    views: Array<StoryViewModel>,
    viewsErrorMessage: string,
    selectParentStoryModalVisible: boolean,
}

class AddEditStoryComponent extends React.Component<IProps, IState> {
    authService = new AuthService();
    categoriesService = new CategoriesService();
    storiesService = new StoriesService();
    usersService = new UsersService();
    commentsService = new CommentsService();

    constructor(props: IProps) {
        super(props);

        let story = new AddEditStoryModel();
        story.categoryId = 0;
        story.statusId = StoryStatus.New;
        story.views = 0;
        story.score = 0;
        story.votes = 0;
        story.rating = 0;
        story.userId = "0";
        story.isAnonymous = true;
        story.hideDate = true;

        let storyId = this.props.match.params.storyId;

        this.state = {
            validations: new Array<InputValidationModel>(),
            touched: false,
            isLoading: false,
            errorMessage: '',
            successMessage: '',
            users: new Array<UserBasicModel>(),
            categories: new Array<CategorySimpleModel>(),
            selectedTab: 'main',
            story,
            storyId,
            comments: new Array<CommentModel>(),
            commentsErrorMessage: '',
            views: new Array<StoryViewModel>(),
            viewsErrorMessage: '',
            selectParentStoryModalVisible: false,
        }
    }

    async componentDidMount() {
        const { storyId } = this.state;

        if(storyId) {
            await this.getStory()
        } else {
            await this.getAdditionalData();
        }
    }

    getStory = async () => {
        const { storyId } = this.state;

        if(storyId) {
            this.setState({
                isLoading: true
            });

            try {
                let story = await this.storiesService.getStory(storyId);
                await this.getAdditionalData();

                if(story) {
                    this.setState({
                        isLoading: false,
                        story
                    })
                } else {
                    this.setState({
                        isLoading: false,
                    })
                }
            } catch (error) {
                this.setState({
                    isLoading: false,
                    errorMessage: error.message
                });
            }   
        }
    }

    getCategories = async () => {
        const { categories } = this.state;

        if(categories.length === 0) {
            this.setState({
                isLoading: true,
            });
    
            try {
                let categoriesList: Array<CategorySimpleModel> = await this.categoriesService.getAllSimple();
    
                if(categoriesList && categoriesList.length > 0) {
                    this.setState({
                        isLoading: false,
                        categories: categoriesList
                    });
                } else {
                    this.setState({
                        isLoading: false
                    });
                }
            } catch (error) {
                this.setState({
                    isLoading: false,
                    errorMessage: error.message
                });
            }
        }
    }

    getAdditionalData = async () => {
        this.setState({
            isLoading: true
        });

        try {
            let categories = await this.categoriesService.getAllSimple();
            let users = await this.usersService.getAllActive();
            //let stories = await this.storiesService.list();

            this.setState({
                isLoading: false,
                users,
                categories,
                //stories,
            })
            
        } catch (error) {
            this.setState({
                isLoading: false,
                errorMessage: error.message
            });
        }
    }

    handleChange = (key: any, value: any) => {
        const story: AddEditStoryModel = { ...this.state.story };
        story[key] = value;

        if(key === 'isAnonymous') {
            story.isAnonymous = !this.state.story.isAnonymous;
        }

        if(key === 'hideDate') {
            story.hideDate = !this.state.story.hideDate;
        }

        if(key === 'pinned') {
            story.pinned = !this.state.story.pinned;
        } 

        if(key === 'userId') {
            if(value === '0') {
                story.isAnonymous = true
            }
        }

        if(key === 'score' || key === 'votes') {
            if(this.isNumeric(value)) {
                if(key === 'score' && story.votes > 0) {
                    story.rating = +value / story.votes
                }

                if(key === 'votes' && +value > 0) {
                    story.rating = story.score / +value;
                }
            }
        }
        
        this.setState({
            story
        }, () => this.state.touched ? this.validate() : null);
    }

    validate = (): boolean => {
        const { story } = this.state;

        this.setState({
            errorMessage: '',
            successMessage: '',
        });

        let validations = new Array<InputValidationModel>();

        if(!story.title)
            validations.push({ key: 'title', message: 'Въведете заглавие.'});

        if(story.title && story.title.length < 6)
            validations.push({ key: 'title', message: 'Заглавието е прекалено късо.'});

        if(story.title && story.title.length > 150)
            validations.push({ key: 'title', message: 'Заглавието е прекалено дълго.'});
        
        if(!story.content)
            validations.push({ key: 'content', message: 'Въведете съдържание.'});

        if(story.content && story.content.length < 150)
        validations.push({ key: 'content', message: 'Съдържанието е прекалено късо.'});

        if(!this.isNumeric(story.views))
            validations.push({ key: 'views', message: 'Невалидна стойност'});

        if(!this.isNumeric(story.votes))
            validations.push({ key: 'votes', message: 'Невалидна стойност'});

        if(!this.isNumeric(story.score))
            validations.push({ key: 'score', message: 'Невалидна стойност'});
        
        this.setState({
            validations,
            touched: true
        });

        return validations.length === 0;
     }

     handleKeyPress = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            this.handleSaveBtnPressed();
        }
     }

    handleSaveBtnPressed = async () => {
        if(!this.validate()) {
            return;
        }

        try {
            this.setState({
                isLoading: true
            });

            const { story } = this.state;

            await this.storiesService.addEdit(story);
            
            if(story.id) {
                await this.storiesService.getStory(story.id);

                this.setState({
                    isLoading: false,
                    touched: false,
                    successMessage: 'Данните бяха записани успешно.'
                }, () => this.getStory());
            } else {
                this.setState({
                    isLoading: false
                });

                this.props.history.push('/stories');
            }

        } catch (error) {
            this.setState({
                isLoading: false,
                errorMessage: error.message
            });
        }
    }

    isNumeric = (value: any) => {
        return /^\d+$/.test(value)
    }

    renderBreadcrumb = () => {
        const { storyId } = this.state;

        return (
            <Breadcrumb style={{ fontSize: '85%'}}>
                    <Breadcrumb.Item href="#">Начало</Breadcrumb.Item>
                    <Breadcrumb.Item href="/stories">
                        Истории
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>
                        {storyId ? 'Редактиране' : 'Добавяне'}
                    </Breadcrumb.Item>
                </Breadcrumb>
        );
    }

    removeParentStory = () => {
        const story: AddEditStoryModel = { ...this.state.story };

        story.parentTitle = '';
        story.parentId = '';

        this.setState({ story });
    }

    renderStoryAddEdit = () => {
        const { validations, touched, errorMessage, categories, story, storyId, users, successMessage } = this.state;
        const titleValidationErrors = validations.find(v => v.key === 'title');
        const contentValidationErrors = validations.find(v => v.key === 'content');
        const viewsValidationErrors = validations.find(v => v.key === 'views');
        const votesValidationErrors = validations.find(v => v.key === 'votes');
        const scoreValidationErrors = validations.find(v => v.key === 'score');

        return (
                <div style={{ marginTop: 15 }}>
                    {errorMessage ? 
                            <Alert variant={'danger'}>
                            {errorMessage}
                            </Alert>
                        : null}

                        {successMessage ? 
                            <Alert variant={'success'}>
                            {successMessage}
                            </Alert>
                        : null}

                        <Form>
                        {storyId 
                            ? <div style={{ display: 'flex', flexDirection: 'column', fontSize: '85%', marginBottom: 15}}>
                                    <span style={{ marginBottom: 5 }}> Публикувана: {dateToLocal(story.addedOn)}</span>
                                    <span style={{ marginBottom: 5 }}>Последна промяна: {dateToLocal(story.updatedOn)}</span>
                                    <span style={{ marginBottom: 5 }}>IP адрес: {story.ipAddress ? story.ipAddress : 'неизвестен'}</span>
                            </div>
                        : null}
                        <Form.Group controlId="titleGroup">
                                <Form.Label style={{ fontSize: '85%'}}>Заглавие</Form.Label>
                                <Form.Control 
                                    type="text" 
                                    placeholder="Въведете заглавие" 
                                    size="sm"
                                    isValid={touched && !titleValidationErrors} 
                                    isInvalid={titleValidationErrors !== undefined}
                                    onChange={(e) => this.handleChange('title', e.target['value'])}
                                    value={story && story.title ? story.title : ''}
                                    onKeyPress={this.handleKeyPress}
                                />
                                <Form.Control.Feedback type="invalid">
                                {touched && titleValidationErrors !== undefined ? titleValidationErrors.message : null }
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group controlId="contentGroup">
                                <Form.Label style={{ fontSize: '85%'}}>Съдържание</Form.Label>
                                <Form.Control 
                                    as="textarea" 
                                    size="sm"
                                    rows={7}
                                    isInvalid={contentValidationErrors !== undefined}
                                    isValid={touched && !contentValidationErrors}
                                    value={story.content}
                                    placeholder="Въведете съдържание"
                                    onChange={(e) => this.handleChange('content', e.target['value'])} />
                                <Form.Control.Feedback type="invalid">
                                    {touched && contentValidationErrors !== undefined ? contentValidationErrors.message : null }
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group controlId="parentStoryGroup">
                                    <Form.Label style={{ fontSize: '85%'}}>Главна история</Form.Label>
                                    {story && story.parentId
                                     ? <div className="d-flex flex-row align-items-center">
                                        <a className='mr-2' target='_blank' rel="noopener noreferrer" href={`/add-edit-story/${story.parentId}`}>{story.parentTitle}</a>
                                        <Button 
                                            variant="outline-secondary" 
                                            size="sm" 
                                            onClick={this.removeParentStory}
                                            className="mr-2">
                                                Премахни
                                        </Button>
                                        <Button 
                                            variant="outline-secondary"
                                            onClick={() => this.setState({ selectParentStoryModalVisible: true })}
                                            size="sm">
                                                Избери
                                        </Button>
                                      </div>
                                     : <div>
                                          <Button 
                                            variant="outline-secondary"
                                            onClick={() => this.setState({ selectParentStoryModalVisible: true })}
                                            size="sm">
                                                Избери
                                        </Button>
                                      </div>}
                            </Form.Group>
                            <Form.Group controlId="usersGroup">
                                    <Form.Label style={{ fontSize: '85%'}}>Потребител</Form.Label>
                                    <Form.Control 
                                        size="sm" 
                                        as="select"
                                        value={story.userId}
                                        onChange={(e) => this.handleChange('userId', e.target['value'])}>
                                        <option value={0}>Анонимен</option>
                                        {users.map((user, index) => {
                                            return (
                                                <option value={user.id} key={index}>{user.userName}</option>
                                            );
                                        })}
                                    </Form.Control>
                            </Form.Group>
                            <Form.Group controlId="isAnonymousGroup">
                                <Form.Check 
                                    type={'checkbox'}
                                    checked={story.isAnonymous}
                                    label={'Анонимно публикуване'}
                                    style={{ fontSize: '85%'}}
                                    onChange={(e) => this.handleChange('isAnonymous', e.target['value'])}
                                />
                            </Form.Group>
                            <Form.Group controlId="hideDateGroup">
                                <Form.Check 
                                    type={'checkbox'}
                                    checked={story.hideDate}
                                    label={'Скриване на датата на публикуване'}
                                    style={{ fontSize: '85%'}}
                                    onChange={(e) => this.handleChange('hideDate', e.target['value'])}
                                />
                            </Form.Group>
                            <Form.Group controlId="pinnedGroup">
                                <Form.Check 
                                    type={'checkbox'}
                                    checked={story.pinned}
                                    label={'Закачена'}
                                    style={{ fontSize: '85%'}}
                                    onChange={(e) => this.handleChange('pinned', e.target['value'])}
                                />
                            </Form.Group>
                            <Form.Group controlId="categoryGroup">
                                    <Form.Label style={{ fontSize: '85%'}}>Категория</Form.Label>
                                    <Form.Control 
                                        size="sm" 
                                        as="select"
                                        value={story && story.categoryId ? story.categoryId : ''}
                                        onChange={(e) => this.handleChange('categoryId', +e.target['value'])}>
                                        <option value={0}>Без категория</option>
                                        {categories.map((category, index) => {
                                            return (
                                                <option value={category.id} key={index}>{category.name}</option>
                                            );
                                        })}
                                    </Form.Control>
                            </Form.Group>
                            <Form.Group controlId="statusGroup">
                                    <Form.Label style={{ fontSize: '85%'}}>Статус</Form.Label>
                                    <Form.Control size="sm" as="select"
                                        value={story.statusId}
                                        onChange={(e) => this.handleChange('statusId', +e.target['value'])}
                                    >
                                        <option value={StoryStatus.New}>Нова / чака одобрение</option>
                                        <option value={StoryStatus.Active}>Активна</option>
                                        <option value={StoryStatus.StoppedByAdmin}>Спряна / неактивна</option>
                                        <option value={StoryStatus.DeletedByUser}>Спряна / изтрит потребителски профил</option>
                                    </Form.Control>
                            </Form.Group>
                            <Row>
                                <Col>
                                    <Form.Group controlId="viewsGroup">
                                        <Form.Label style={{ fontSize: '85%'}}>Прегледи</Form.Label>
                                        <Form.Control 
                                            size="sm" 
                                            type="text"
                                            isInvalid={viewsValidationErrors !== undefined}
                                            isValid={touched && !viewsValidationErrors}
                                            value={story.views}
                                            onChange={(e) => this.handleChange('views', this.isNumeric(e.target['value']) ? +e.target['value'] : e.target['value'] )}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {touched && viewsValidationErrors !== undefined ? viewsValidationErrors.message : null }
                                        </Form.Control.Feedback>
                                </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group controlId="votesGroup">
                                        <Form.Label style={{ fontSize: '85%'}}>Гласувания</Form.Label>
                                        <Form.Control 
                                            size="sm" 
                                            type="text"
                                            value={story.votes}
                                            isInvalid={votesValidationErrors !== undefined}
                                            isValid={touched && !votesValidationErrors}
                                            onChange={(e) => this.handleChange('votes', this.isNumeric(e.target['value']) ? +e.target['value'] : e.target['value'] )}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {touched && votesValidationErrors !== undefined ? votesValidationErrors.message : null }
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group controlId="scoreGroup">
                                            <Form.Label style={{ fontSize: '85%'}}>Точки</Form.Label>
                                            <Form.Control 
                                                size="sm" 
                                                type="text"
                                                isInvalid={scoreValidationErrors !== undefined}
                                                isValid={touched && !scoreValidationErrors}
                                                value={story.score}
                                                onChange={(e) => this.handleChange('score', this.isNumeric(e.target['value']) ? +e.target['value'] : e.target['value'] )}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {touched && scoreValidationErrors !== undefined ? scoreValidationErrors.message : null }
                                            </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <div style={{ fontSize: '85%'}}> 
                                            Рейтинг: {story.rating.toFixed(2)}
                                        </div>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Form.Group controlId="notesGroup">
                                <Form.Label style={{ fontSize: '85%'}}>Админ бележки</Form.Label>
                                <Form.Control 
                                    as="textarea" 
                                    size="sm"
                                    rows={5}
                                    value={story && story.adminNotes ? story.adminNotes : ''}
                                    onChange={(e) => this.handleChange('adminNotes', e.target['value'])} />
                            </Form.Group>
                            <FormGroup>
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
                                    <Button variant="primary" onClick={this.handleSaveBtnPressed}>Запис</Button>
                                </div>
                            </FormGroup>
                        </Form>
                </div>);
    }

    changeTab = (key: any) => {
        const { comments, views } = this.state;

        if(key === 'comments' && comments.length === 0) {
            this.setState({
                selectedTab: key
            }, async () => await this.getComments());
        } else if(key === 'usersViews' && views.length === 0) {
            this.setState({
                selectedTab: key
            }, async () => await this.getViews());
        } else {
            this.setState({
                selectedTab: key
            });
        }
    }

    getComments = async () => {
        const { storyId } = this.state;

        try {
            this.setState({
                isLoading: true,
                commentsErrorMessage: '',
            });

            let comments = await this.commentsService.getAllByStory(storyId);

            this.setState({
                isLoading: false,
                comments
            })
        } catch (error) {
            this.setState({
                isLoading: false,
                commentsErrorMessage: error.message,
            })
        }
    }

    getViews = async () => {
        const { storyId } = this.state;

        try {
            this.setState({
                isLoading: true,
                viewsErrorMessage: '',
            });

            let views = await this.storiesService.getStoryViews(storyId);

            this.setState({
                isLoading: false,
                views
            })
        } catch (error) {
            this.setState({
                isLoading: false,
                viewsErrorMessage: error.message,
            })
        }
    }

    onParentStoryModalClosed = () => {
        this.setState({
            selectParentStoryModalVisible: false
        });
    }

    onParentstorySelected = (selectedStory) => {
        if(selectedStory) {
            const story: AddEditStoryModel = { ...this.state.story };
            story.parentId = selectedStory.id;
            story.parentTitle = selectedStory.title;

            this.setState({
                story,
                selectParentStoryModalVisible: false
            });
        } else {
            this.setState({
                selectParentStoryModalVisible: false
            });
        }
    }

    render() {
        const { isLoading, story, storyId, successMessage, selectedTab, comments, views, 
            commentsErrorMessage, viewsErrorMessage, selectParentStoryModalVisible } = this.state;

        if(successMessage) {
            setTimeout(() => {
                this.setState({ successMessage: ''});
            }, 5000)
        }

        return (
            <div style={{ marginBottom: 80 }}>
                {isLoading ? <Spinner /> : null}

                <h5>
                    {!storyId ? 'Добавяне на история' : null} 
                    {storyId && story && story.title ? <span style={{ color: '#FF7851'}}> {story.title}</span> : null}
                </h5>
                

                <Tabs
                    id="storyTabs"
                    activeKey={selectedTab}
                    onSelect={(k) => this.changeTab(k)}
                    style={{ marginTop: 15 }}
                    >
                    <Tab eventKey="main" title="История">
                        {!isLoading
                         ? this.renderStoryAddEdit()
                         : null}
                    </Tab>
                    {storyId 
                    ?  <Tab eventKey="comments" 
                            title={`Коментари (${!isLoading && story ? story.comments : 0})`} 
                            disabled={!isLoading && story?.comments === 0}>
                            <div style={{ marginTop: 10}}>
                                {commentsErrorMessage ? 
                                    <Alert variant={'danger'} style={{ fontSize: '85%'}}>
                                        {commentsErrorMessage}
                                    </Alert>
                                : null}
                                <CommentsGrid 
                                    comments={comments}
                                    history={this.props.history}
                                    excludeColumns={['StoryTitle', 'Delete']}
                                />
                            </div>
                        </Tab>
                    : null}
                    {storyId
                     ?  <Tab eventKey="usersViews" 
                            title={`Прегледи (${!isLoading && story ? story.usersViews : 0})`}
                            disabled={!isLoading && story?.usersViews === 0}>
                            <div style={{ marginTop: 10}}>
                                    {viewsErrorMessage ? 
                                        <Alert variant={'danger'} style={{ fontSize: '85%'}}>
                                            {viewsErrorMessage}
                                        </Alert>
                                    : null}
                                <StoriesViewsGrid 
                                    views={views}
                                    excludeColumns={['Story']}
                                />
                            </div>
                        </Tab>
                     : null}
                </Tabs>
                <SelectParentStoryModal 
                    isVisible={selectParentStoryModalVisible} 
                    close={this.onParentStoryModalClosed}
                    onStorySelected={this.onParentstorySelected} />
            </div>);
        }
}

// class ListItem {
//     lebel: string;
//     value: string;
// }

export default AddEditStoryComponent;