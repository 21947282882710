export class AddEditStoryModel {
    public id: string;
    public parentId: string;
    public parentTitle: string;
    public userId: string;
    public userName: string;
    public ipAddress: string;
    public addedOn: string;
    public updatedOn: string;
    public title: string;
    public content: string;
    public comments: number;
    public isAnonymous: boolean;
    public categoryId: number;
    public statusId: number;
    public views: number;
    public votes: number;
    public score: number;
    public rating: number;
    public adminNotes: number;
    public usersViews: number;
    public hideDate: boolean;
    public pinned: boolean;
}