export enum NotificationType {
    StoryApproved = 1,
    CommentInUserStory = 2,
    CommnetInCommentedStory = 3,
}

export enum StoryStatus {
    New = 1,
    Active = 2,
    StoppedByAdmin = 3,
    DeletedByUser = 4,
}

export enum CommentStatus {
    Active = 1,
    StoppedByAdmin = 2,
    DeletedByUser = 3,
}

export enum UserRole {
    Administrator = 1,
    User = 2,
}

export enum SystemOptions {
    DisableUserRegistrations = 1,
    DisableAnonymousStoryPosts = 2,
    DisableStoryPosts = 3,
    DisableAnonymousCommentsPost = 4,
    DisableCommentsPost = 5,
    DisableAnonymousPollPosts = 6,
    DisablePollPosts = 7,
}

export enum CacheTypes {
    RestrictedAddresses = 1,
    Votes = 2,
    StoryViews = 3,
}

export enum PollStatus {
    New = 1,
    Active = 2,
    Stopped = 3,
}