const apiErrors = {
    '1000':'Въведете потребителско име.',
    '1001':'Въведете парола.',
    '1002':'Паролите не съвпадат.',
    '1003':'Въвели сте невалидно потребителско име.',
    '1005':'Избраното потребителско име е заето.',
    '1006':'Паролата трябва да съдържа минимум 6 символа.',
    '1007':'Паролата не трябва да съдържа повече от 30 символа.',
    '1008':'Въведеният имейл адрес е невалиден.',
    '1009':'Съществува потребител с този имейл адрес.',
    '1010':'Възникна проблем със завършването на регистрацията. Моля, опитайте отново.',
    '1011':'Отказан достъп. Потребителят е деактивиран.',
    '1012':'Потребителят е деактивиран.',
    '1013':'Невалиден потребител',
    '1014':'Въведете имейл адрс.',
    '1015':'Невалиден имейл адрес.',
    '1016':'Невалиден потребител',
    '1017':'Съществува потребител с този имейл адрес.',
    '1018':'Възникна проблем. Моля, опитайте отново.',
    '1019':'Въведете текуща парола.',
    '1020':'Въведете нова парола.',
    '1021':'Потвърдете новата парола.',
    '1022':'Паролата трябва да съдържа минимум 6 символа.',
    '1023':'Паролата не трябва да съдържа повече от 30 символа.',
    '1024':'Паролите не съвпадат.',
    '1025':'Невалиден потребител.',
    '1026':'Въвели сте грешна текуща парола.',
    '1027':'Възникна проблем с промяната на Вашата парола. Моля, опитайте отново.',
    '1028':'Невалиден потребител.',
    '1029':'Възникна грешка. Моля, опитайте отново.',
    '1030':'Невалиден код за потвърждение.',
    '1031': 'Невалиден потребител',
    '1032':'Невалиден код за потвърждение.',
    '1033':'Възникна грешки при потвърждаване на имейл адреса. Моля, опитайте отново.',
    '1034':'Невалиден потребител',
    '1035':'Възникна грешка при зареждане на абонаментите за известия. Моля, опитайте отново.',
    '1036':'Невалиден потребител',
    '1037':'Възникна грешка при промяна на абонаментите за известия. Моля, опитайте отново',
    '1038':'Моля, въведете имейл адрес.',
    '1039':'Въведенеия имейл адрес е невалиден.',
    '1040':'Няма регистриран потребител с този имейл адрес.',
    '1041':'Възникна проблем при генерирането на заявката за нова парола. Моля, опитайте отново.',
    '1042':'Операцията не може да бъде приключена - липсващ код от имейла за забравена парола.',
    '1043':'Операцията не може да бъде приключена - невалиден код от имейла за забравена парола.',
    '1044':'Възникна проблем. Моля, опитайте отново.',
    '1045':'Операцията не може да бъде приключена - невалидни данни от имейла за забравена парола.',
    '1046':'Паролата трябва да съдържа минимум 6 символа.',
    '1047':'Паролата не трябва да съдържа повече от 30 символа.',
    '1048':'Паролите не съвпадат.',
    '1049': 'Възникна проблем със зареждането на активните потребители. Моля, опитайте отново.',
    '1050': 'Възникна грешка при зареждането на потребителите. Моля, опитайте отново.',
    '1051':'Потребителят не е намерен.',
    '1052':'Възникна грешка при зареждане на потребителя. Моля, опитайте отново',
    '1053':'Потребителят не е намерен.',
    '1054':'Въведете потребителско име',
    '1055':'Въведеното потребителско име е невалидно - трябва да съдържа минимум 4 и максимум 30 символа.',
    '1056': 'Въведеният имейл адрес е невалиден.',
    '1057':'Съществува потребител с този имейл адрес.',
    '1058':'Съществува потребител с това потребителско име.',
    '1059':'Възникна грешка при записа на потребителските данни. Моля, опитайте отново.',
    '1060':'Въведете парола',
    '1061':'Потвърдете въведената парола.',
    '1062':'Паролата трябва да съдържа минимум 6 символа.',
    '1063':'Паролата не трябва да съдържа повече от 30 символа.',
    '1064':'Въведените пароли не съвпадат.',
    '1065':'Възникна грешка при промяна на паролата - потребителят не е намерен.',
    '1066':'Възникна грешка при промяна на паролата на потребителя. Моля, опитайте отново.',
    '1067':'Промяна на абонамнет за нотификации - потребителят не е намерен.',
    '1068':'Промяна на абонамнет за нотификации - възникна грешка моля, опитайте отново.',
    '1070':'Изтриване на потребител - потребителят не е намерен.',
    '1071':'Възникна проблем при изтриването на потребителят. Моля, опитайте отново.',
    '1072':'Не може да изтривате администраторски акаунт!',

    '2000':'Потвърдете, че не сте робот.',
    '2001':'Антибот потвърждението е невалидно.',

    '3000':'Въведете заглавие.',
    '3001':'Въведете съдържание.',
    '3002':'Възникна проблем с добавянето на историята. Моля, опитайте отново.',
    '3005':'Отказан достъп. Потребителят е деактивиран.',
    '3006':'Заглавието е прекалено кратко.',
    '3007':'Заглавието е прекалено дълго.',
    '3008':'Съдържанието е прекалено кратко.',
    '3009':'Възникна проблем със зареждането на историите. Моля, опитайте отново.',
    '3010':'Историята не е намерена.',
    '3011':'Възникна проблем със зареждането на историята. Моля, опитайте отново.',
    '3012':'Невалидна история',
    '3013':'Невалиден глас',
    '3014':'Възникна грешка. Моля, опитайте отново.',
    '3015':'Вече сте гласували за тази история.',
    '3016':'Възникна проблем със зареждането на Вашите истории. Моля, опитайте отново.',
    '3017':'Възникна проблем със зареждането на историите. Моля, опитайте отново.',
    '3018':'Възникна проблем със зареждането на историите. Моля, опитайте отново.',
    '3019':'Историята не е намерена.',
    '3020':'Възникна проблем със зареждането на историята. Моля, опитайте отново.',

    '3030':'Зареждане на потребителски истории - потребителят не е намерен.',
    '3031':'Възникна проблем със зареждането на потребителските истории. Моля, опитайте отново.',

    '3032':'Зареждане на прегледани истории от потребител - потребителят не е намерен.',
    '3033':'Възникна проблем със зареждането на прегледаните истории на потребителя. Моля, опитайте отново.',

    '3034':'Зареждане на потребителски прегледи на история - историята не е намерена.',
    '3035':'Възникна грешка при зареждането на потребителските прегледи. Моля, опитайте отново.',

    '3036':'Възникна грешка при зареждането на прегледите. Моля, опитайте отново.',

    '3039':'Изтриване на история - историята не е намерена.',
    '3040':'Възникна проблем с изтриването на историята. Моля, опитайте отново.',

    '4000':'Възникна проблем със зареждането на активните категории. Моля, опитайте отново.',
    '4001':'Възникна проблем със зареждането на категориите. Моля, опитайте отново.',
    '4002':'Възникна проблем със зареждането на категорията - категорията не е намерена.',
    '4003':'Възникна проблем със зареждането на категорията. Моля, опитайте отново.',
    '4004':'Възникна проблем при редактиране - категорията не е намерена.',
    '4005':'Въведете име на категорията.',
    '4006':'Възникна проблем при записа на категорията. Моля, опитайте отново.',

    '5000':'Въведете коментар.',
    '5001':'Невалидна история.',
    '5002':'Възникна проблем с добавянето на Вашия коментар. Моля, опитайте отново.',
    '5003':'Невалидна история.',
    '5004':'Възникна проблем със зареждането на коментарите. Моля, опитайте отново.',
    '5005':'Възникна проблем със зареждането на коментарите. Моля, опитайте отново.',
    '5006':'Невалидна история.',
    '5007':'Възникна проблем със зареждането на коментарите. Моля, опитайте отново.',
    '5008':'Коментарът не е намерен.',
    '5009':'Възникна проблем със зареждането на коментара. Моля, опитайте отново.',
    '5010':'Невалиден коментар.',
    '5011':'Коментарът не е намерен.',
    '5012':'Въведете съдържание на коментара',
    '5013':'Въведете причина за деактивиране',
    '5014':'Възникна проблем с редакцията на коментара. Моля, опитайте отново.',
    '5019':'Изтриване на коментар - коментарът не е намерен.',
    '5020':'Изтриване на коментар - коментарът не е последен.',
    '5021':'Възникна проблем с изтриването на коментара. Моля, опитайте отново.',

    '6000':'Възникна проблем със зареждането на данните. Моля, опитайте отново.',

    '7000':'Възникна грешка при зареждане на системнте настройки. Моля, опитайте отново.',
    '7001':'Възникна грешка при записването на системните настройки. Моля, опитайте отново.',

    '10000': 'Възникна грешка при опит за изчистване на кеш. Моля, опитайте отново.',

    '12002': 'Възникна грешка при зареждането на feedback записите.',
    '12003': 'Feedback записа не е намерен.',
    '12004': 'Възникна грешка при изтриването на feedback записа.',

    '13029': 'Възникна грешка при зареждане на данните. Моля, опитайте отново.',
    '13030': 'Въпросът не е намерен.',
    '13031': 'Грешка при зареждана на въпрос. Моля, опитайте отново.',
    '13032': 'Въпросът не е намерен.',
    '13033': 'Въведете въпрос.',
    '13034': 'Въведете поне два отговора.',
    '13035': 'Въведете минимум два отговора.',
    '13036': 'Възникна грешка при запис на данните. Моля, опитайте отново.',
    '13037': 'Опцията не е намерена.',
    '13038': 'Възникна грешка на сървъра при зареждането на данните. Моля, опитайте отново.',
    '13039': 'Въпросът не е намерен.',
    '13040': 'Възникна грешка на сървъра при зареждането на данните. Моля, опитайте отново.',

    '13063': 'Възникна грешка при изтриване на въпроса. Моля, опитайте отново.',

    'Invalid User Name or Password':'Невалидно потребителско име и/или парола.',
    'Invalid User Name':'Невалидно потребителско име.',
    'User is deactivated':'Отказан достъп. Потребителят е деактивиран.',
    'Login problem':'Възникна проблем. Моля, опитайте отново.',
    'Network Error': 'Няма връзка със сървъра. Моля, проверете Вашата интернет свързаност и опитайте отново.'
}

export default apiErrors;