import React from 'react';
import Alert from 'react-bootstrap/Alert';
import Spinner from '../../common/components/spinner/spinner';
import { SystemOptionModel } from '../../models/options/systemOptionModel';
import SystemOptionsService from '../../services/systemOptionsService';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Card from 'react-bootstrap/esm/Card';
import { SystemOptions } from '../../common/enums';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import RestrcitedAddressesComponent from './restrictedAddressesComponent';
import CacheResetComponent from './cacheResetComponent';
import NotificationsTasksComponent from './notificationsTasksComponent';

interface IProps {
    history: any;
    match: any;
    location: any;
}

interface IState {
    systemOptions: Array<SystemOptionModel> | null,
    isLoading: boolean;
    errorMessage: string;
    successMassage: string;
}

class SystemOptionsComponent extends React.Component<IProps, IState> {
    systemOptionsService = new SystemOptionsService();

    constructor(props: IProps) {
        super(props);

        this.state = {
            systemOptions: null,
            isLoading: false,
            errorMessage: '',
            successMassage: '',
        }
    }

    async componentDidMount() {
        await this.getAll();
    }

    getAll = async () => {
        try {
            this.setState({
                isLoading: true
            });

            let systemOptions: Array<SystemOptionModel> = await this.systemOptionsService.getAllSystemOptions();

            this.setState({
                isLoading: false,
                systemOptions,
            })

        } catch (error) {
            this.setState({
                isLoading: false,
                errorMessage: error.message,
            });
        }
    }

    getOptionLabel = (id: number): string => {
        switch(id) {
            case SystemOptions.DisableAnonymousCommentsPost:
                return 'Забрани анонимното публикуване на коментари (в истории и въпроси)';
            case SystemOptions.DisableAnonymousStoryPosts :
                return 'Забрани анонимното публикуване на истории';
            case SystemOptions.DisableCommentsPost:
                return 'Забрани публикуването на коментари (в истории и въпроси)';
            case SystemOptions.DisableStoryPosts:
                return 'Забрани публикуването на истории';
            case SystemOptions.DisableUserRegistrations:
                return 'Забрани регистрацията на потребители';
            case SystemOptions.DisableAnonymousPollPosts:
                return 'Забрани анонимното публикуване на въпроси';
            case SystemOptions.DisablePollPosts:
                return 'Забрани публикуването на въпроси';
            default:
                return '';
        }
    }

    handleSystemOptionChange = (id: number) => {
        let { systemOptions } = this.state;

        let option = systemOptions?.find(s => s.id === id);

        if(option) {
            option.isActive = !option.isActive;

            this.setState({
                systemOptions
            });
        }
    }

    handleSaveSystemOptionsPressed = async () => {
        const { systemOptions } = this.state;

        try {
            this.setState({
                isLoading: true
            });

            await this.systemOptionsService.updateAllSystemOptions(systemOptions!);

            this.setState({
                isLoading: false,
                successMassage: 'Данните бяха записани успешно.'
            })
        } catch (error) {
            this.setState({
                isLoading: false,
                errorMessage: error.message,
            });
        }
    }

    render() {
        const { isLoading, systemOptions, errorMessage, successMassage } = this.state;

        if(successMassage) {
            setTimeout(() => {
                this.setState({ 
                    successMassage: '',
                });
            }, 5000)
        }

        return (
            <div style={{ marginBottom: 50}}>
                {isLoading ? <Spinner /> : null}
                <Breadcrumb style={{ fontSize: '85%'}}>
                    <Breadcrumb.Item href="/">Начало</Breadcrumb.Item>
                    <Breadcrumb.Item active>
                        Настройки
                    </Breadcrumb.Item>
                </Breadcrumb>

                {systemOptions && systemOptions.length > 0
                ? <Card>
                    <Card.Body>
                        <h6>Ограничаване на функционалности</h6>
                        {errorMessage
                        ?  <Alert style={{ fontSize: '85%'}} variant={'danger'}>
                                {errorMessage}
                            </Alert>
                        : null}
                        {successMassage
                        ?  <Alert style={{ fontSize: '85%'}} variant={'success'}>
                                {successMassage}
                            </Alert>
                        : null}
                        <Form style={{ marginTop: 10}}>
                            {systemOptions.map((option, index) => {
                                return (
                                    <Form.Check 
                                        key={index}
                                        type={'checkbox'}
                                        checked={option.isActive}
                                        label={this.getOptionLabel(option.id)}
                                        style={{ fontSize: '85%', marginBottom: 5}}
                                        onChange={(e) => this.handleSystemOptionChange(option.id)}
                                    />
                                )
                            })}
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginTop: 5}}> 
                                <Button size="sm" variant="primary" onClick={this.handleSaveSystemOptionsPressed}>Запис</Button>
                            </div>
                         </Form>
                    </Card.Body>
                </Card>
                : null}

                <NotificationsTasksComponent style={{ marginTop: 10}} />

                <RestrcitedAddressesComponent style={{ marginTop: 10}} />

                <CacheResetComponent style={{ marginTop: 10 }} />

             </div>
            )
    }
}

export default SystemOptionsComponent;