import React from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import { InputValidationModel } from '../../common/models/inputValidationModel';
import Spinner from '../../common/components/spinner/spinner';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import CategoriesService from '../../services/categoriesService';
import FormGroup from 'react-bootstrap/esm/FormGroup';
import { dateToLocal } from '../../common/functions/helperFunctions';
import { CategoryModel } from '../../models/category/categoryModel';
import { CategoryAddEditModel } from '../../models/category/categoryAddEditModel';

interface IProps {
    history: any;
    match: any;
}

interface IState {
    validations: Array<InputValidationModel>;
    touched: boolean;
    isLoading: boolean;
    isSaving: boolean;
    errorMessage: string;
    successMessage: string;
    categoryId: string;
    category: CategoryModel | null;
    categoryAddEditModel: CategoryAddEditModel;
}

class AddEditCategoryComponent extends React.Component<IProps, IState> {
    categoriesService = new CategoriesService();

    constructor(props: IProps) {
        super(props);

        let categoryId = this.props.match.params.categoryId;

        this.state = {
            validations: new Array<InputValidationModel>(),
            touched: false,
            isLoading: false,
            isSaving: false,
            errorMessage: '',
            successMessage: '',
            categoryId,
            category: null,
            categoryAddEditModel: new CategoryAddEditModel(),
        }
    }

    async componentDidMount() {
        await this.getCategory();
    }

    getCategory = async () => {
        const { categoryId } = this.state;

        if(categoryId) {
            this.setState({
                isLoading: true
            });

            try {
                let category: CategoryModel = await this.categoriesService.get(categoryId);

                if(category) {
                    let categoryAddEditModel = { ...this.state.categoryAddEditModel };
                    categoryAddEditModel.id = category.id;
                    categoryAddEditModel.isActive = category.isActive;
                    categoryAddEditModel.name = category.name;
                    categoryAddEditModel.authUsersOnly = category.authUsersOnly;

                    this.setState({
                        isLoading: false,
                        category,
                        categoryAddEditModel,
                    });
                } else {
                    this.setState({
                        isLoading: false
                    });
                }

            } catch (error) {
                this.setState({
                    isLoading: false,
                    errorMessage: error.message
                });
            }   
        }
    }

    handleChange = (key: any, value: any) => {
        const categoryAddEditModel: CategoryAddEditModel = { ...this.state.categoryAddEditModel };
        categoryAddEditModel[key] = value;

        if(key === 'isActive') {
            categoryAddEditModel.isActive = !this.state.categoryAddEditModel?.isActive;
        } else if(key === 'authUsersOnly') {
            categoryAddEditModel.authUsersOnly = !this.state.categoryAddEditModel?.authUsersOnly;
        } else {
            categoryAddEditModel[key] = value;
        }

        this.setState({
            categoryAddEditModel
        }, () => this.state.touched ? this.validate() : null);
    }

    validate = (): boolean => {
        const { categoryAddEditModel } = this.state;

        this.setState({
            errorMessage: '',
            successMessage: '',
        });

        let validations = new Array<InputValidationModel>();

        if(!categoryAddEditModel.name)
            validations.push({ key: 'name', message: 'Въведете име на категорията..'});
        
        this.setState({
            validations,
            touched: true
        });

        return validations.length === 0;
     }

     handleKeyPress = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            this.handleSaveBtnPressed();
        }
     }

    handleSaveBtnPressed = async () => {
        if(!this.validate()) {
            return;
        }

        try {
            this.setState({
                isSaving: true
            });

            const { categoryAddEditModel, categoryId } = this.state;

            if(!categoryId) {
                await this.categoriesService.create(categoryAddEditModel);

                this.setState({
                    isSaving: false
                });

                this.props.history.push('/categories');
                
            } else {
                await this.categoriesService.update(categoryAddEditModel);

                this.setState({
                    isSaving: false,
                    touched: false,
                    successMessage: 'Данните бяха записани успешно.'
                }, () => this.getCategory());
            }
        } catch (error) {
            this.setState({
                isSaving: false,
                errorMessage: error.message
            });
        }
    }

    renderBreadcrumb = () => {
        const { categoryId } = this.state;

        return (
            <Breadcrumb style={{ fontSize: '85%'}}>
                    <Breadcrumb.Item href="/">Начало</Breadcrumb.Item>
                    <Breadcrumb.Item href="/categories">
                        Категории
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>
                        {categoryId ? 'Редактиране' : 'Добавяне'}
                    </Breadcrumb.Item>
                </Breadcrumb>
        );
    }

    renderCategoryAddEdit = () => {
        const { validations, touched, errorMessage, category, categoryAddEditModel, successMessage, categoryId } = this.state;
        const nameValidationErrors = validations.find(v => v.key === 'name');

        return (
                <div style={{ marginTop: 15 }}>
                    {errorMessage ? 
                            <Alert variant={'danger'}>
                            {errorMessage}
                            </Alert>
                        : null}

                        {successMessage ? 
                            <Alert variant={'success'}>
                            {successMessage}
                            </Alert>
                        : null}

                        <Form>
                        {categoryId && category 
                            ? <div style={{ display: 'flex', flexDirection: 'column', fontSize: '85%', marginBottom: 15}}>
                                    <span style={{ marginBottom: 5 }}> Добавена: {dateToLocal(category.createdOn)}</span>
                                    <span style={{ marginBottom: 5 }}>Последна промяна: {dateToLocal(category.updatedOn)}</span>
                                    <span style={{ marginBottom: 5 }}>Брой истории: {category.storiesCount}</span>
                            </div>
                        : null}
                        <Form.Group controlId="nameGroup">
                                <Form.Label style={{ fontSize: '85%'}}>Име</Form.Label>
                                <Form.Control 
                                    type="text" 
                                    placeholder="Въведете име на категорията" 
                                    size="sm"
                                    isValid={touched && !nameValidationErrors} 
                                    isInvalid={nameValidationErrors !== undefined}
                                    onChange={(e) => this.handleChange('name', e.target['value'])}
                                    value={categoryAddEditModel.name ? categoryAddEditModel.name : ''}
                                    onKeyPress={this.handleKeyPress}
                                />
                                <Form.Control.Feedback type="invalid">
                                {touched && nameValidationErrors !== undefined ? nameValidationErrors.message : null }
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group controlId="isActiveGroup">
                                <Form.Check 
                                    type={'checkbox'}
                                    checked={categoryAddEditModel.isActive}
                                    label={'Активна'}
                                    style={{ fontSize: '85%'}}
                                    onChange={(e) => this.handleChange('isActive', e.target['value'])}
                                />
                            </Form.Group>
                            <Form.Group controlId="authUsersOnly">
                                <Form.Check 
                                    type={'checkbox'}
                                    checked={categoryAddEditModel.authUsersOnly}
                                    label={'Достъп само за регистрирани потребители'}
                                    style={{ fontSize: '85%'}}
                                    onChange={(e) => this.handleChange('authUsersOnly', e.target['value'])}
                                />
                            </Form.Group>
                            <FormGroup>
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
                                    <Button variant="primary" onClick={this.handleSaveBtnPressed}>Запис</Button>
                                </div>
                            </FormGroup>
                        </Form>
                </div>);
    }

    render() {
        const { isLoading, isSaving, categoryId, category, successMessage } = this.state;

        if(successMessage) {
            setTimeout(() => {
                this.setState({ successMessage: ''});
            }, 5000)
        }

        return (
            <div style={{ marginBottom: 80 }}>
                {isLoading || isSaving ? <Spinner /> : null}

                {this.renderBreadcrumb()}

                <h5>
                    {!categoryId ? 'Добавяне на категория' : null} 
                    {categoryId && category && category.name ? <span style={{ color: '#FF7851'}}> {category.name}</span> : null}
                </h5>
                
                {!isLoading
                    ? this.renderCategoryAddEdit()
                    : null}
              
            </div>);
        }
}

export default AddEditCategoryComponent;