import React from 'react';
import './App.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col';
import HeaderComponent from './components/header/headerComponent';
//import SidebarComponent from './components/sidebar/sidebarComponent';
import { BrowserRouter } from 'react-router-dom';
import Content from './components/content/content';

function App() {
  return (
    <BrowserRouter>
      <Container>
        <HeaderComponent />
        <Row style={{ marginTop: 70 }}>
          <Col sm={12}>
            <Content />
          </Col>
          {/* <Col sm={2}>
            <SidebarComponent />
          </Col> */}
        </Row>
      </Container>
    </BrowserRouter>
    
  );
}

export default App;
