import React from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import Spinner from '../../common/components/spinner/spinner';
import Card from 'react-bootstrap/Card';
import Paginator from '../../common/components/paginator/paginator';
import { faSearch  } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { PollViewModel } from '../../models/polls/pollViewModel';
import { PollViewsListFilterModel } from '../../models/polls/pollViewsListFilterModel';
import PollsService from '../../services/pollsService';
import { PollViewsListModel } from '../../models/polls/pollViewsListModel';
import PollsViewsGrid from '../../common/components/polls/pollsViewsGrid';

interface IProps {
    history: any;
    match: any;
}

interface IState {
    isLoading: boolean;
    from: number;
    views: Array<PollViewModel> | null;
    totalRecords: number;
    errorMessage: string;
    currentPage: number;
    pageSize: number;
    filterIsVisible: boolean;
    isFiltering: boolean;
    filter: PollViewsListFilterModel;

}

class PollsViewsListComponent extends React.Component<IProps, IState> {
    pollsService = new PollsService();

    constructor(props: IProps) {
        super(props);

        let currentPage = +this.props.match.params.page;

        if(!currentPage) {
            currentPage = 1
        }

        let pageSize = 15;
        let from = (currentPage - 1) * pageSize;

        let filter = new PollViewsListFilterModel();
        filter.userName = '';
        filter.ipAddress = '';
        filter.pollQuestion = '';

        this.state = {
            isLoading: false,
            errorMessage: '',
            views: null,
            from,
            totalRecords: 0,
            currentPage,
            pageSize,
            filterIsVisible: false,
            isFiltering: false,
            filter,
        }
    }

    componentDidMount() {
        this.getViews();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let nextPage = this.props.match.params.page;
        let prevPage = prevProps.match.params.page;

        if(prevPage !== nextPage) {
            if(!nextPage) {
                nextPage = 1;
            }

            let from = (nextPage - 1) * this.state.pageSize;

            this.setState({
                from,
                currentPage: +nextPage
            }, () => this.getViews());
        }
    }

    getViews = async () => {
        this.setState({
            isLoading: true,
            views: new Array<PollViewModel>()
        });

        const { from, filter } = this.state;

        try {
            let pollsViewsModel: PollViewsListModel = await this.pollsService.getAllPollsViews(from, filter);

            let totalRecords = 0;
            let views = new Array<PollViewModel>();

            if(pollsViewsModel) {
                totalRecords = pollsViewsModel.count;
                views = pollsViewsModel.views;
            }

            this.setState({
                totalRecords,
                views,
                isLoading: false
            });

        } catch (error) {
            this.setState({
                isLoading: false,
                errorMessage: error.message
            });
        }
    }

    renderFilter = () => {
        const { filter } = this.state;

        return (
            <Card style={{marginBottom: 10, marginTop: 10}}>
                <Card.Body>
                    <h6>Филтър</h6>
                    <Form>
                        <Form.Group as={Row} controlId="userNameSearch">
                            <Form.Label column sm="2" style={{ fontSize: '85%'}}>Въпрос:</Form.Label>
                            <Col sm="10">
                                <Form.Control 
                                    type="text" 
                                    size="sm" 
                                    onChange={(e) => this.handleChange('pollQuestion', e.target['value'])}
                                    value={filter.pollQuestion}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="userNameSearch">
                            <Form.Label column sm="2" style={{ fontSize: '85%'}}>Потребителско име</Form.Label>
                            <Col sm="10">
                                <Form.Control 
                                    type="text" 
                                    size="sm" 
                                    onChange={(e) => this.handleChange('userName', e.target['value'])}
                                    value={filter.userName}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="ipAddressSearch">
                            <Form.Label column sm="2" style={{ fontSize: '85%'}}>IP Адрес</Form.Label>
                            <Col sm="10">
                                <Form.Control 
                                    type="text" 
                                    size="sm" 
                                    onChange={(e) => this.handleChange('ipAddress', e.target['value'])}
                                    value={filter.ipAddress}
                                />
                            </Col>
                        </Form.Group>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
                            <div style={{ display: 'flex', flexDirection: 'row'}}>
                                <Button size="sm" variant="primary" onClick={this.handleOnFilterPressed}>Търси</Button>
                                <Button size="sm" variant="danger" onClick={this.handleOnClearFilterPressed} style={{ marginLeft: 10}}>Изчисти</Button>
                            </div>
                        </div>
                    </Form>
                </Card.Body>
            </Card>
        );
    }

    renderFilterValues = () => {
        const { filter } = this.state;

        return (
            <div style={{ fontSize: '85%', marginBottom: 15}}>
                {filter.pollQuestion
                 ? <div>
                     Въпрос: <span style={{ fontWeight: 'bold'}}>{filter.pollQuestion}</span> 
                   </div>
                : null}
                {filter.userName
                 ? <div>
                     Потребителско име: <span style={{ fontWeight: 'bold'}}>{filter.userName}</span> 
                   </div>
                : null}
                 {filter.ipAddress
                 ? <div>
                     IP Адрес: <span style={{ fontWeight: 'bold'}}>{filter.ipAddress}</span> 
                   </div>
                : null}
                 <Button 
                    variant="danger" 
                    onClick={this.handleOnClearFilterPressed}
                    size="sm"
                    style={{ marginTop: 5}}>
                        Изчисти
                </Button>
            </div>
        )
    }

    toggleFilter = () => {
        let filterIsVisible = this.state.filterIsVisible;
        this.setState({
            filterIsVisible: !filterIsVisible
        });
    }

    onChangePage = (page) => {
        this.props.history.push(`/polls-views/${page}`);
    }

    handleChange = (key: any, value: any) => {
        const filter: PollViewsListFilterModel = { ...this.state.filter };

        filter[key] = value;

        this.setState({
            filter
        });
    }

    handleOnFilterPressed = () => {
        this.props.history.replace('/polls-views');

        const { filter } = this.state;

        let isFiltering = false;

        if(filter) {
            if(filter.userName) { isFiltering = true }
            if(filter.ipAddress) { isFiltering = true }
            if(filter.pollQuestion) { isFiltering = true }
        }

        this.setState({
            from: 0,
            currentPage: 1,
            views: null,
            totalRecords: 0,
            isFiltering,
            filterIsVisible: false,
        }, () => this.getViews());
    }

    handleOnClearFilterPressed = () => {
        this.props.history.replace('/polls-views');

        let filter = new PollViewsListFilterModel();
        filter.userName = '';
        filter.ipAddress = '';
        filter.pollQuestion = '';

        this.setState({
            from: 0,
            currentPage: 1,
            views: null,
            totalRecords: 0,
            filter,
            isFiltering: false,
        }, () => this.getViews());
    }

    render() {
        const { isLoading, errorMessage, views, totalRecords, pageSize, currentPage,
            filterIsVisible, isFiltering } = this.state;

        return (
            <>
            <div style={{ marginBottom: 50 }}>
                {isLoading ? <Spinner /> : null}
                <Breadcrumb style={{ fontSize: '85%'}}>
                    <Breadcrumb.Item href="/">Начало</Breadcrumb.Item>
                    <Breadcrumb.Item href="/polls">Въпроси</Breadcrumb.Item>
                    <Breadcrumb.Item active>
                        Прегледи
                    </Breadcrumb.Item>
                </Breadcrumb>
                <div style={{ 
                    display: 'flex', 
                    flexDirection: 'row', 
                    justifyContent: 'space-between', 
                    textAlign: 'center'}}>
                    <h5>Прегледи на въпроси ({totalRecords})</h5>
                    <FontAwesomeIcon 
                        icon={faSearch} 
                        style={{ color: "#FF7851", cursor: 'pointer' }} 
                        size='lg' 
                        onClick={this.toggleFilter}
                    />
                </div>
                {errorMessage ? 
                  <Alert variant={'danger'}>
                  {errorMessage}
                </Alert>
                : null}

                {!filterIsVisible && isFiltering
                 ? this.renderFilterValues()
                 : null}
               
                {filterIsVisible
                 ? this.renderFilter()
                 : null}

                <PollsViewsGrid 
                    views={views} excludeColumns={[]}               
                />

                {totalRecords > 15 && !isLoading
                ? <Paginator 
                    totalRecords={totalRecords}
                    pageSize={pageSize}
                    currentPage={currentPage}
                    style={{marginTop: 10, justifyContent: 'center'}}
                    onClick={this.onChangePage} />
              : null}
            </div>
        </>
        )
    }
}

export default PollsViewsListComponent;