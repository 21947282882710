import React,{useState, useEffect} from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import StoriesService from '../../services/storiesService';
import { StorySimpleModel } from '../../models/story/storySimpleModel';
import Alert from 'react-bootstrap/Alert';
import ListGroup from 'react-bootstrap/ListGroup';
import Spinner from 'react-bootstrap/Spinner';

const SelectParentStoryModal = ({isVisible, close, onStorySelected}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [stories, setStories] = useState(new Array<StorySimpleModel>());
    const [errorMessage, setErrorMessage] = useState('');
    const [selectedStory, setSelectedStory] = useState(null);

    useEffect(() => {
        const loadStories = async () => {
            setIsLoading(true);
            setErrorMessage('');
            setSelectedStory(null);
            setStories(new Array<StorySimpleModel>());

            try {
                const storiesService = new StoriesService();
                let stories = await storiesService.list();

                if(stories) {
                    setStories(stories);
                }
                setIsLoading(false);
                
            } catch (error) {
                setIsLoading(false);
                setErrorMessage(error.message);
            }
        }

        if(isVisible) {
            loadStories();
        }

    }, [isVisible])

    const handleClose = () => {
        close();
    }

    const onSelectStoryFromList = (story) => {
        setSelectedStory(story);
    }

    const onSelectButtonClicked = () => {
        onStorySelected(selectedStory);
        setSelectedStory(null);
    }

    return(
         <Modal 
            show={isVisible} 
            onHide={handleClose} 
            scrollable
            size='lg'>
            <Modal.Header closeButton>
            <Modal.Title>Изберете итория</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {isLoading 
                    ? <div className='d-flex flex-row align-items-center'>
                        <Spinner animation="grow" variant="primary"/>
                        <span className='ml-2'>Зареждане...</span>
                      </div>
                    : null}
                {errorMessage ? 
                    <Alert variant={'danger'}>
                    {errorMessage}
                    </Alert>
                : null}
                {!isLoading && stories && stories.length > 0
                 ? <ListGroup>
                    {stories.map((story, index) => {
                        return <ListGroup.Item onClick={() => onSelectStoryFromList(story)} key={index} action={true}>{story.title}</ListGroup.Item>
                    })}
                 </ListGroup>
                 : null}
            </Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
                Отказ
            </Button>
            <Button variant="primary" onClick={onSelectButtonClicked} disabled={selectedStory === null}>
                Избери
            </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default SelectParentStoryModal;